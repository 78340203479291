import React, { useState } from 'react'
import { Button, Header, Icon, Modal, TextArea, Form, Popup } from "semantic-ui-react";
import { addFieldToProject, saveProject } from '../../functions/project-card-functions';
import StageGateChecklist from "../StageGateChecklist";
import StepModal from './StepModal';

const TTOProcessModal = (props) => {
    const [checkedState, setCheckedState] = useState()
    const [openRevertModal, setOpenRevertModal] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [describeDecisionForNext, setDescribeDecisionForNext] = useState("");
    const [describeDecisionForRevert, setDescribeDecisionForRevert] = useState("")
    const userInfo = props.userInfo;

    let showStageGateDecisionDescriptionFormAndChecklist = true;
    let showConfirmButton = true;
    let buttonLock = true;
    let lockForDecision = true;
    let buttonName = "Cancel"
    const gate = props.project.projectGate

    if (describeDecisionForNext !== "") {
        buttonLock = false;
    }

    if (describeDecisionForRevert !== "") {
        lockForDecision = false;
    }

    let gateSubHeadline = "Check list for Gate ";
    let enableRevertButton = true;
    if (gate === "1") {
        enableRevertButton = false;
        gateSubHeadline = gateSubHeadline + "A";
    } else if (gate === "2") {
        gateSubHeadline = gateSubHeadline + "B";
    } else if (gate === "3") {
        gateSubHeadline = gateSubHeadline + "C";
    } else if (gate === "4") {
        gateSubHeadline = gateSubHeadline + "D";
        //  showConfirmButton = false;
    } else if (gate === "5") {
        buttonName = "Close"
        gateSubHeadline = "The project is in Maintenance stage";
        showStageGateDecisionDescriptionFormAndChecklist = false;
        showConfirmButton = false;
    }

    let checkList = [];

    if (gate === "1") {
        checkList = require("../../data/MigrationToNewTTOProcess/CheckListTTOGate1.json");
    } else if (gate === "2") {
        checkList = require("../../data/MigrationToNewTTOProcess/CheckListTTOGate2.json");
    } else if (gate === "3") {
        checkList = require("../../data/MigrationToNewTTOProcess/CheckListTTOGate3.json");
    } else if (gate === "4") {
        checkList = require("../../data/MigrationToNewTTOProcess/CheckListTTOGate4.json");
    }

    const plainTextOfCheckedState = (checkedState) => {
        if (checkedState === undefined || checkedState === '') {
            checkedState = []
        }
        let plainTextChecked = []
        const indicesOfSelectedValues = checkedState.flatMap((bool, index) => bool ? index : [])
        for (let i = 0; i < indicesOfSelectedValues.length; i++) {
            plainTextChecked.push(checkList[indicesOfSelectedValues[i]]["name"])
        }
        return plainTextChecked
    }

    const nextGate = (g) => {
        switch (g) {
            case "0":
                return "1";
            case "1":
                return "2";
            case "2":
                return "3";
            case "3":
                return "4";
            case "4":
                return "5";
            default:
                return "1";
        }
    };

    const prevGate = (g) => {
        switch (g) {
            case "1":
                return "0";
            case "2":
                return "1";
            case "3":
                return "2";
            case "4":
                return "3";
            case "5":
                return "4";
            default:
                return "1";
        }
    };

    const setModalClosed = () => {
        props.dispatch({ type: 'setModal', payload: '' })
    }
    const handleCancelButton = () => {
        setModalClosed();
    };

    const submitConfirmForNextStage = (decide) => {
        if (decide) {
            const updatedGate = nextGate(gate)
            const historyObject = {
                type: "gate",
                userId: userInfo.userId,
                userName: userInfo.userDetails,
                gate: gate,
                decision: describeDecisionForNext,
                decisionDate: new Date().toISOString(),
                timestamp: new Date().toISOString(),
                checklist: plainTextOfCheckedState(checkedState)
            };

            const visCheckListItemObj = {
                gate: gate,
                checkedItems: plainTextOfCheckedState(checkedState),
                userId: userInfo.userId,
                decisionDate: new Date().toISOString(),
                decision: describeDecisionForNext,
            }

            const stages = props.project.projectStages || [];
            stages.push(visCheckListItemObj)
            const updatedProject = addFieldToProject(
                props.project, "projectStages", stages);
            updatedProject.projectGate = updatedGate
            updatedProject.history.push(historyObject)
            saveProject(updatedProject)
                .then(r => console.log("Updated project ", updatedProject, "stages", stages));
            setModalClosed();
            setDescribeDecisionForNext('')
            setOpenConfirmModal(false)
            props.dispatch({ type: 'updateProject', payload: updatedProject })
        }
        setModalClosed();
        setOpenConfirmModal(false)
    }

    const submitRevertForPreviousStage = (decide) => {
        if (decide) {
            const updatedGate = prevGate(gate)
            const historyObject = {
                type: "gate",
                userId: userInfo.userId,
                userName: userInfo.userDetails,
                gate: gate,
                decision: "(Reverted) " + describeDecisionForRevert,
                decisionDate: new Date().toISOString(),
                timestamp: new Date().toISOString()
            };
            const stages = props.project.projectStages || [];

            const removedStage = stages.pop()
            console.log("Removed from project stages:", removedStage)
            const updatedProject = addFieldToProject(
                props.project, "projectStages", stages);
            updatedProject.projectGate = updatedGate
            updatedProject.history.push(historyObject)
            saveProject(updatedProject)
                .then(r => console.log("Updated project ", updatedProject, "stages", stages));
            setModalClosed();
            setOpenRevertModal(false)
            setDescribeDecisionForRevert('')
            props.dispatch({ type: 'updateProject', payload: updatedProject })
        }
        setModalClosed();
        setOpenRevertModal(false)
        setDescribeDecisionForRevert('')
    }

    return (
        <Modal closeOnDimmerClick={false} closeIcon open={props.isOpen} onClose={setModalClosed}>
            <Header icon="handshake" content="Decision to move to next stage" />
            <Modal.Header>{props.project.id} - {props.project.projectName}</Modal.Header>

            <Modal.Content>
                <h3>{gateSubHeadline}</h3>
                <StepModal gate={gate} />
                {showStageGateDecisionDescriptionFormAndChecklist && (
                    <>
                        <StageGateChecklist
                            updateSelectedCriteria={setCheckedState}
                            gate={gate}
                        />
                        <Form>
                            <TextArea
                                placeholder="Tell us more about your decision"
                                name={"decision"}
                                //value={describeDecisionForNext}
                                onChange={(e) => setDescribeDecisionForNext(e.target.value)}
                            />
                        </Form>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>

                <Modal
                    onClose={() => setOpenRevertModal(false)}
                    onOpen={() => setOpenRevertModal(true)}
                    open={openRevertModal}
                    closeOnDimmerClick={false}
                    trigger={
                        <Popup trigger={<Button onClick={() => setOpenRevertModal(true)} disabled={!enableRevertButton} floated='left'>Revert</Button>}>
                            Confirm moving the project back to previous stage
                        </Popup>
                    }>
                    <Modal.Header>Are you sure you will set the project back to stage {gate - 1}? This will change the history of the project.</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <TextArea
                                placeholder="Tell us more about your decision"
                                name={"decision"}
                                // value={describeDecisionForNext}
                                onChange={(e) => setDescribeDecisionForRevert(e.target.value)}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={() => setOpenRevertModal(false)}>
                            <Icon name="remove" />
                            No
                        </Button>
                        <Button disabled={lockForDecision}
                            onClick={() => submitRevertForPreviousStage(true)}>
                            <Icon name="checkmark" />
                            Yes
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Popup
                    trigger={<Button onClick={handleCancelButton}>
                        <Icon name="remove" /> {buttonName}
                    </Button>} >
                    No change to the project
                </Popup>

                {showConfirmButton && (
                    <Modal
                        onClose={() => setOpenConfirmModal(false)}
                        onOpen={() => setOpenConfirmModal(true)}
                        open={openConfirmModal}
                        closeOnDimmerClick={false}
                        trigger={
                            <Popup
                                trigger={<Button disabled={buttonLock} onClick={() => setOpenConfirmModal(true)}>
                                    <Icon name="checkmark" /> Confirm
                                </Button>} >
                                Confirm move to next stage
                            </Popup>
                        }>
                        <Modal.Header>Please confirm that the project is ready for the next stage</Modal.Header>
                        <Modal.Actions>
                            <Button
                                onClick={() => setOpenConfirmModal(false)}>
                                <Icon name="remove" />
                                Cancel
                            </Button>
                            <Button
                                onClick={() => submitConfirmForNextStage(true)}>
                                <Icon name="checkmark" />
                                Confirm
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}

            </Modal.Actions>
        </Modal>
    );
}

export default TTOProcessModal