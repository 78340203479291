import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'

const ArchiveStatusIcon = (props) => {

  const info = props.props;
  let message = "";

  if (info === "" || info === undefined) {
    message = "There is no archive information for this project!";
  } else {
    message = info;
  }

  if (info === "Archived") {
    return (
      <Popup
        trigger={<Icon circular name="archive" />}>
        {message}
      </Popup>
    )
  }

  if (info === "On-hold") {
    return (
      <Popup
        trigger={<Icon circular name="pause" />}>
        {message}
      </Popup>
    )
  }

  return (<></>)

};

export default ArchiveStatusIcon
