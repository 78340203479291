import React, { useState } from "react";
import { Form, TextArea, Dropdown, Grid } from "semantic-ui-react";
import OwnerList from "../elements/OwnerList";

const NewProject = (props) => {
  const exitRouteOptions = require("../../data/ExitRouteOptions.json");
  const [project, setProject] = useState(props.project);

  let errorMessageProjectName = "";
  let errorMessageStartDate = "";
  let errorMessageOwningUnit1 = "";
  let errorMessageNonConfInfo = "";
  let errorMessageLeadInventor = "";

  if (project.projectName === "") {
    errorMessageProjectName = "Project name must be filled out";
  }
  if (project.startDate === "") {
    errorMessageStartDate = "Start date must be filled out";
  }
  if (project.owningUnit1 === "") {
    errorMessageOwningUnit1 = "Owning unit must be filled out";
  }
  if (project.nonConfidentialInformation === "") {
    errorMessageNonConfInfo = "Non-confidential information must be filled out";
  }
  if (project.leadInventor === "") {
    errorMessageLeadInventor = "Lead inventor must be filled out";
  }

  const handleChange = (e, { name, value }) => {
    console.log(`Handle change: ${name}, ${value}`);
    const updatedProject = { ...project, [name]: value };
    setProject(updatedProject);
    props.handleChange(updatedProject);
  };

  const ideaOptions = [{ value: "YES" }, { value: "NO" }];

  const countOptions = ideaOptions.map((option) => {
    return { key: option.value, value: option.value, text: option.value };
  });

  let pType = "";
  const ttoProject = "TTO";
  const administrative = "Administrative";
  const innovation = "Innovation";
  const company = "Company";
  const consulting = "Consulting";
  const supportingActivities = "Supporting";

  let isRequired = false;

  const displayFor = (showWhen) => {
    return showWhen.includes(project.projectType);
  };

  if (displayFor([ttoProject])) {
    isRequired = true;
  }

  if (project.projectType === ttoProject) {
    pType = "TTO";
  } else {
    pType = project.projectType;
  }

  return (
    <div>
      <h2>New {pType}</h2>
      <Form>
        <Form.Input
          placeholder="Name of the project"
          label="Project Name"
          name="projectName"
          value={project.projectName}
          onChange={handleChange}
          required
        />
        <p style={{ color: "red" }}>{errorMessageProjectName}</p>
        {displayFor([administrative]) && (
          <Form.Input
            placeholder="VIS-Process Area"
            label="VIS-Process Area"
            name="processArea"
            value={project.processArea}
            onChange={handleChange}
          />
        )}

        <br />
        <Grid>
          <div>
            <Form.Input
              placeholder="Actual start date of the project"
              label="Start Date"
              name="startDate"
              value={project.startDate}
              type="Date"
              onChange={handleChange}
              required
            />
            <p style={{ color: "red" }}>{errorMessageStartDate}</p>
          </div>
          {/*<div>
            <Form.Input
              placeholder="Estimated or actual end date of the project"
              label="End Date"
              name="endDate"
              value={project.endDate}
              type="Date"
              onChange={handleChange}
            />
        </div>*/}
          {displayFor([administrative, ttoProject]) && (
            <Form.Field
              label="Exit Route"
              placeholder="Exit Route"
              name="exitRoute"
              value={project.exitRoute}
              selection
              control={Dropdown}
              options={exitRouteOptions}
              onChange={handleChange}
            />
          )}
        </Grid>
        <br />
        <br />

        {displayFor([innovation]) && (
          <Form.Input
            placeholder="Count As Idea (Yes/No)"
            label="Count As Idea"
            name="countAsIdea"
            value={project.countAsIdea}
            fluid
            selection
            control={Dropdown}
            options={countOptions}
            onChange={handleChange}
          />
        )}

        {displayFor([
          administrative,
          ttoProject,
          company,
          innovation,
          supportingActivities,
        ]) && (
          <Form.Field>
            <Form.Input
              label="Owning Unit 1"
              placeholder="Main owner"
              name="owningUnit1"
              control={OwnerList}
              value={project.owningUnit1}
              onChange={handleChange}
              required
            />
            <p style={{ color: "red" }}>{errorMessageOwningUnit1}</p>
          </Form.Field>
        )}

        {displayFor([ttoProject, supportingActivities]) && (
          <Form.Field>
            <Form.Input
              label="Sub owner"
              placeholder="Sub owner"
              control={OwnerList}
              name="subOwningUnit"
              value={project.subOwningUnit}
              onChange={handleChange}
            />
          </Form.Field>
        )}

        {displayFor([
          administrative,
          ttoProject,
          company,
          innovation,
          supportingActivities,
        ]) && (
          <Form.Field>
            <Form.Input
              label="Owning Unit 2 - in other legal entity"
              placeholder="Main owner"
              control={OwnerList}
              name="owningUnit2"
              value={project.owningUnit2}
              onChange={handleChange}
            />
          </Form.Field>
        )}

        {displayFor([ttoProject]) && (
          <Form.Input
            placeholder="Percentage ownership - Owning Unit 1"
            label="Owner percentage - Unit 1"
            name="ownerPercentage1"
            value={project.ownerPercentage1}
            onChange={handleChange}
          />
        )}

        {displayFor([ttoProject]) && (
          <Form.Input
            placeholder="Percentage ownership - Owning Unit 2"
            label="Owner percentage - Unit 2"
            name="ownerPercentage2"
            value={project.ownerPercentage2}
            onChange={handleChange}
          />
        )}

        {displayFor([ttoProject]) && (
          <>
            <Form.Input
              placeholder="Name of the lead inventor for the project"
              label="Lead Inventor"
              name="leadInventor"
              value={project.leadInventor}
              onChange={handleChange}
              required={isRequired}
            />
            <p style={{ color: "red" }}>{errorMessageLeadInventor}</p>
          </>
        )}

        <Form.Field
          label="Non-confidential Information"
          placeholder="Non-confidential Information"
          name="nonConfidentialInformation"
          control={TextArea}
          value={project.nonConfidentialInformation}
          onChange={handleChange}
          required
        />
        <p style={{ color: "red" }}>{errorMessageNonConfInfo}</p>

        {displayFor([consulting]) && (
          <Form.Input
            placeholder="Client"
            label="Client"
            name="client"
            value={project.client}
            onChange={handleChange}
          />
        )}

        {displayFor([consulting]) && (
          <Form.Input
            placeholder="Contact Person"
            label="Contact Person"
            name="contactPerson"
            value={project.contactPerson}
            onChange={handleChange}
          />
        )}

        {displayFor([administrative, ttoProject]) && (
          <Form.Field
            label="Project Keywords"
            placeholder="Keywords"
            name="projectKeywords"
            value={project.projectKeywords}
            fluid
            multiple
            selection
            control={Dropdown}
            options={props.projectKeywordList}
            onChange={handleChange}
          />
        )}

        {displayFor([]) && (
          <Form.Input
            placeholder="Financed by 1"
            label="Financed by 1"
            name="financedBy1"
            value={project.financedBy1}
            onChange={handleChange}
          />
        )}

        {displayFor([]) && (
          <Form.Input
            placeholder="Financed by 2"
            label="Financed by 2"
            name="financedBy2"
            value={project.financedBy2}
            onChange={handleChange}
          />
        )}

        {/*{displayFor([ttoProject]) && (
          <Form.Input
            placeholder="Expected Deal Type"
            label="Expected Deal Type"
            name="expectedDealType"
            value={project.expectedDealType}
            onChange={handleChange}
          />
        )}

        <Form.Input
          placeholder="Related Gateway Project(s)"
          label="Related Gateway Projects"
          name="relatedGatewayProjects"
          value={project.relatedGatewayProjects}
          onChange={handleChange}
        />

        <Form.Input
          placeholder="Sustainable Development"
          label="Sustainable Development"
          name="sustainableDevelopment"
          value={project.sustainableDevelopment}
          onChange={handleChange}
        />*/}
      </Form>
    </div>
  );
};

export default NewProject;
