import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { saveProject } from "../functions/project-card-functions";
import { useAppState } from "../state/useAppState";
import Application from "./Application.jsx";
import { cleanApplicationForSave } from "./application-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

const ApplicationModal = (props) => {
  const { state, dispatcher } = useAppState();

  const userInfo = props.userInfo;

  const newLine = () => {
    const line = {
      totalBudget: 0,
      specificationList: [],
    };
    const withOneExtraLine = state.applicationsForEdit;
    withOneExtraLine.push(line);
    const lastIndex = withOneExtraLine.length - 1;
    dispatcher({ type: "setApplicationsForEdit", payload: withOneExtraLine });
    dispatcher({ type: "setApplicationsEditIndex", payload: lastIndex });
  };

  const historyObject = {
    type: "changes",
    userId: userInfo.userId,
    userName: userInfo.userDetails,
    change: "Application updated",
  };

  const setModalClosed = () => {
    dispatcher({ type: "setModal", payload: "" });
  };

  const handleCancelButton = () => {
    setModalClosed();
    dispatcher({ type: "setApplicationsEditIndex", payload: null });
    console.log(state.projectForEdit.applications);
    {
      /*if(!state.projectForEdit.applications || state.projectForEdit.applications.length === 0) {
      state.applicationsForEdit = []
    }*/
    }
  };

  const saveApplication = async (status, applications) => {
    const project = state.projectForEdit;
    if (status) {
      historyObject.change = "Application updated. Status: " + status;
    }
    if (applications) {
      project.applications = applications
        .filter((item) => item != null)
        .map((application) => cleanApplicationForSave(application));
    } else {
      project.applications = state.applicationsForEdit
        .filter((item) => item != null)
        .map((application) => cleanApplicationForSave(application));
    }
    project.history.push(historyObject);
    saveProject(project);
    props.dispatch({ type: "updateProject", payload: project });
    console.log("===>> APPLICATION SAVED ", project);
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={state.modal === "applicationModal"}
      onClose={setModalClosed}
    >
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faHandHoldingDollar} />
        </Icon>
        Applications for funding
      </Header>
      <Modal.Header>
        {state.projectForEdit.id} - {state.projectForEdit.projectName}
      </Modal.Header>
      <Modal.Content>
        {state.applicationsForEdit.length !== 0 &&
          state.applicationsForEdit.some(
            (application) => application !== null
          ) && (
            <p>
              <em>* are mandatory fields</em>
            </p>
          )}
        {state.applicationsForEdit.map((applicationLine, i) => (
          <Application
            application={applicationLine}
            index={i}
            key={i}
            saveApplication={saveApplication}
          />
        ))}
        <Button onClick={() => newLine()}>
          <Icon>
            <FontAwesomeIcon icon={faHandHoldingDollar} />
          </Icon>
          New application
        </Button>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button onClick={() => handleSubmitButton()}>
          <Icon name="save" />
          Save changes
        </Button> */}
        <Button onClick={() => handleCancelButton()}>
          <Icon name="cancel" />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ApplicationModal;
