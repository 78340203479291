import React from "react";

import {
  createSpecificationItem,
  addOrUpdateSpecificationItem,
  calculateTotalAmount,
} from "./application-functions";
import SpecificationCell from "./SpecificationCell";
import { Grid } from "semantic-ui-react";
import { formatNumber } from "../functions/numbers";

const SpecificationLine = (props) => {
  const { specificationType, partner } = props;
  let specifications = props.specifications;
  if (
    !specifications ||
    specifications.length === 0 ||
    !Array.isArray(specifications)
  ) {
    specifications = [];
  }
  const specifcationsOfType = specifications.filter((specification) => {
    if (specificationType === "PARTNER") {
      return (
        specification.partner === partner &&
        specification.type === specificationType
      );
    } else {
      return specification.type === specificationType;
    }
  });

  const total = calculateTotalAmount(specifcationsOfType);

  return (
    <>
      <Grid.Column width={2}>{formatNumber(total)}</Grid.Column>
      {props.yearList.map((year, index) => {
        let specification = specifcationsOfType.find(
          (specification) => specification.year === year
        );
        if (!specification) {
          specification = createSpecificationItem(
            specificationType,
            partner,
            year,
            0
          );
        }
        const handleChangeNumber = (e, t) => {
          console.log(e.target.value);
          specification.amount = e.target.value.replace(/\D/g, "");
          const updatedList = addOrUpdateSpecificationItem(
            props.specifications,
            specification
          );

          props.onChange(e, {
            name: "specificationList",
            value: updatedList,
          });
        };
        return (
          <SpecificationCell
            key={index}
            value={specification.amount}
            specification={specification}
            isInEdit={props.isInEdit}
            handleChangeNumber={handleChangeNumber}
          />
        );
      })}
      <Grid.Column width={1}></Grid.Column>
    </>
  );
};

export default SpecificationLine;
