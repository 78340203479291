import { Parser } from "html-to-react";

const getArrayItems = (array) => {
    const htmlParser = new Parser();

    if (array === null || array === undefined) {
        array = []
    } else {
        let tmp = ''
        array.forEach((e) => {
            tmp += "- " + e + "</br>"
        });
        return htmlParser.parse(tmp)
    }
    return array
}

const getArrayItemsAsString = (array) => {
    let arrayAsString = ''
    if (array === null || array === undefined) {
        return null
    } else {
        let count = 0
        array.forEach(element => {
            count++
            arrayAsString += element + (count < array.length ? ", " : "")
        });
        return arrayAsString
    }
}

export { getArrayItems, getArrayItemsAsString }