
const numberFormatCheck = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
}
const formatNumber = (x) => {
    if (!x) return ""

    const formatedNum = x.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ")

    if (x < 0) return "-" + formatedNum
    return formatedNum;
}

export { numberFormatCheck, formatNumber }