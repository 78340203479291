import React, { useState } from "react";
import {
  Button,
  Form,
  Divider,
  Grid,
  Dropdown,
  Icon,
  TextArea,
} from "semantic-ui-react";
import { useAppState } from "../state/useAppState";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import ConfirmDiscardModal from "../components/modals/ConfirmDiscardModal";
import ApplicationSpecification from "./ApplicationSpecification";
import { formatNumber } from "../functions/numbers";
import { calculateTotalAmount, getYearFromDate } from "./application-functions";

const Application = (props) => {
  const { state, dispatcher } = useAppState();
  const [inputMissing, setInputMissing] = useState(false);

  const isInEdit = state.applicationsEditIndex === props.index;
  const application = isInEdit
    ? state.applicationsForEdit[state.applicationsEditIndex]
    : props.application;

  if (!application) {
    console.log("Application is null. Index: " + props.index);
    return null;
  }
  console.log("Application: " + JSON.stringify(application));

  const modalContentDelete =
    "The application will be permanently deleted. Are you sure that this line should be deleted?";
  const modalContentDiscard =
    "The changes to the application will be permanently lost. Are you sure that the changes should be discarded?";

  const handleChange = (e, { name, value }) => {
    console.log(`Handle change: ${name}=${value} on line ${props.index + 1}`);
    if (name === "periodFrom") {
      console.log("Period from changed: " + value);
      application.specificationList = application.specificationList.filter(
        (spec) => {
          const specYearAsInt = parseInt(getYearFromDate(spec.year));
          const periodFromYearAsInt = parseInt(getYearFromDate(value));
          return specYearAsInt >= periodFromYearAsInt;
        }
      );
    }
    updateFieldApplication(name, value);
  };

  const updateFieldApplication = (name, value) => {
    const updatedApplication = state.applicationsForEdit.map(
      (application, i) => {
        if (i === props.index) {
          let updatedLine = {
            ...application,
            [name]: value,
          };
          return updatedLine;
        } else {
          return application;
        }
      }
    );
    dispatcher({ type: "setApplicationsForEdit", payload: updatedApplication });
  };

  const updateLineStatus = (value) => {
    const updatedApplication = state.applicationsForEdit.map(
      (application, i) => {
        if (i === props.index) {
          return { ...application, applicationStatus: value };
        } else {
          return application;
        }
      }
    );
    props.saveApplication(value, updatedApplication);
    dispatcher({ type: "setApplicationsForEdit", payload: updatedApplication });
  };

  const deleteApplication = () => {
    const updatedApplication = state.applicationsForEdit.filter(
      (application, i) => i !== props.index
    );
    dispatcher({ type: "setApplicationsForEdit", payload: updatedApplication });
    props.saveApplication("Deleted application line", updatedApplication);
    dispatcher({ type: "setApplicationsEditIndex", payload: null });
  };

  const discardApplicationChanges = () => {
    // TODO Need to check if changes are made. If not, ConfirmDiscardModal does not need to appear.
    // TODO Need to set state to previously saved.
    console.log("DISCARDING");

    // const updatedApplication = state.applicationsForEdit.map(
    //   (application, i) => {
    //     console.log("inupdateApp")
    //     console.log(application)
    //     console.log(i)
    //     console.log(props.index)
    //     if (i === props.index) {
    //       console.log("found it")
    //       if(state.projectForEdit.applications && state.projectForEdit.applications.length > props.index) {
    //         if(state.projectForEdit.applications[props.index]) {
    //           console.log("in if")
    //           let updatedLine = {
    //             ...state.projectForEdit.applications[props.index],
    //           };
    //           return updatedLine;
    //         }
    //         else {
    //           return undefined;
    //         }
    //       }
    //     } else {
    //       return application;
    //     }
    //   }
    // ).filter((item) => item !== undefined);;
    // console.log("updap")
    // console.log(updatedApplication)
    // dispatcher({ type: "setApplicationsForEdit", payload: updatedApplication });

    dispatcher({ type: "cancelApplicationForEdit", payload: null });
  };

  const applicationTypes = [
    {
      key: "NFR Verification",
      text: "NFR Verification",
      value: "NFR Verification",
    },
    {
      key: "NFR Qualification",
      text: "NFR Qualification",
      value: "NFR Qualification",
    },
    {
      key: "NFR Other",
      text: "NFR Other",
      value: "NFR Other",
    },
    {
      key: "Other",
      text: "Other",
      value: "Other",
    },
  ];

  const projectManagementOrganizations = [
    {
      key: "VIS",
      text: "VIS",
      value: "VIS",
    },
    {
      key: "Universitetet i Bergen",
      text: "Universitetet i Bergen",
      value: "Universitetet i Bergen",
    },
    {
      key: "Helse Bergen",
      text: "Helse Bergen",
      value: "Helse Bergen",
    },
    {
      key: "Høgskulen på Vestlandet",
      text: "Høgskulen på Vestlandet",
      value: "Høgskulen på Vestlandet",
    },
    {
      key: "Other",
      text: "Other",
      value: "Other",
    },
  ];

  const applicationStatuses = [
    {
      key: "In progress",
      text: "In progress",
      value: "In progress",
    },
    {
      key: "Sent",
      text: "Sent",
      value: "Sent",
    },
    {
      key: "Approved",
      text: "Approved",
      value: "Approved",
    },
    {
      key: "Rejected",
      text: "Rejected",
      value: "Rejected",
    },
  ];

  const redNegNum = (number) => {
    if (number < 0) return { color: "red" };
    return { color: "black" };
  };

  const formIsValid = () => {
    //TODO Add call, project management and status
    if (
      application.call === "" ||
      application.call === undefined ||
      application.projectManagementLead === "" ||
      application.projectManagementLead === undefined ||
      application.applicationStatus === "" ||
      application.applicationStatus === undefined ||
      (application.applicationStatus === "Sent" &&
        (application.sentDate === "" || application.sentDate === undefined)) ||
      (application.applicationStatus === "Approved" &&
        (application.approvalDate === "" ||
          application.approvalDate === undefined)) ||
      (application.applicationStatus === "Rejected" &&
        (application.rejectionDate === "" ||
          application.rejectionDate === undefined)) ||
      application.periodFrom === "" ||
      application.periodFrom === undefined ||
      application.periodTo === "" ||
      application.periodTo === undefined
    ) {
      setInputMissing(true);
      return false;
    } else setInputMissing(false);
    return true;
  };

  // useEffect(() => {
  //   const cleanList = application.specificationList.filter((spec) => {
  //     const specYearAsInt = parseInt(getYearFromDate(spec.year));
  //     const periodFromYearAsInt = parseInt(
  //       getYearFromDate(application.periodFrom)
  //     );
  //     return specYearAsInt >= periodFromYearAsInt;
  //   });

  //   dispatcher({ type: "setApplicationsForEditSpecList", payload: cleanList });
  // }, [application.periodFrom, application.specificationList, dispatcher]);

  return (
    application && (
      <Form>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={5}>
              <p>Call (Application Type)*</p>
            </Grid.Column>
            <Grid.Column>
              {!isInEdit && <p>{application.call}</p>}
              {isInEdit && (
                <>
                  <Dropdown
                    name="call"
                    placeholder="Choose call/application type"
                    fluid
                    selection
                    options={applicationTypes}
                    style={{ margin: "0px 0px 3.73px", width: "220px" }}
                    onChange={handleChange}
                    defaultValue={application.call}
                  />
                  {inputMissing &&
                    (application.call === "" ||
                      application.call === undefined) && (
                      <p style={{ color: "red" }}>This field is mandatory.</p>
                    )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <p>Project management organization (lead)*</p>
            </Grid.Column>
            <Grid.Column>
              {!isInEdit && <p>{application.projectManagementLead}</p>}
              {isInEdit && (
                <>
                  <Dropdown
                    name="projectManagementLead"
                    placeholder="Choose lead organization for project management"
                    fluid
                    selection
                    options={projectManagementOrganizations}
                    style={{ margin: "0px 0px 3.73px", width: "400px" }}
                    onChange={handleChange}
                    defaultValue={application.projectManagementLead}
                  />
                  {inputMissing &&
                    (application.projectManagementLead === "" ||
                      application.projectManagementLead === undefined) && (
                      <p style={{ color: "red" }}>This field is mandatory.</p>
                    )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <p>Status*</p>
            </Grid.Column>
            <Grid.Column>
              {!isInEdit && <p>{application.applicationStatus}</p>}{" "}
              {/*{state.projectForEdit.applications[props.index].applicationStatus} */}
              {isInEdit && (
                <>
                  <Dropdown
                    name="applicationStatus"
                    placeholder="Choose status of application"
                    fluid
                    selection
                    options={applicationStatuses}
                    style={{ margin: "0px 0px 3.73px", width: "400px" }}
                    onChange={handleChange}
                    defaultValue={application.applicationStatus}
                  />
                  {inputMissing &&
                    (application.applicationStatus === "" ||
                      application.applicationStatus === undefined) && (
                      <p style={{ color: "red" }}>This field is mandatory.</p>
                    )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          {application.applicationStatus === "Sent" && (
            <Grid.Row>
              <Grid.Column width={5}>
                <p>Sent date*</p>
              </Grid.Column>
              <Grid.Column>
                {!isInEdit && <p>{application.sentDate}</p>}
                {isInEdit && (
                  <>
                    <Form.Input
                      name="sentDate"
                      type="date"
                      size="mini"
                      style={{ margin: "0px 0px 3.73px", width: "200px" }}
                      onChange={handleChange}
                      defaultValue={application.sentDate}
                    />
                    {inputMissing &&
                      (application.sentDate === "" ||
                        application.sentDate === undefined) && (
                        <p style={{ color: "red" }}>This field is mandatory.</p>
                      )}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          {application.applicationStatus === "Approved" && (
            <Grid.Row>
              <Grid.Column width={5}>
                <p>Approval date*</p>
              </Grid.Column>
              <Grid.Column>
                {!isInEdit && <p>{application.approvalDate}</p>}
                {isInEdit && (
                  <>
                    <Form.Input
                      name="approvalDate"
                      type="date"
                      size="mini"
                      style={{ margin: "0px 0px 3.73px", width: "200px" }}
                      onChange={handleChange}
                      defaultValue={application.approvalDate}
                    />
                    {inputMissing &&
                      (application.approvalDate === "" ||
                        application.approvalDate === undefined) && (
                        <p style={{ color: "red" }}>This field is mandatory.</p>
                      )}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          {application.applicationStatus === "Rejected" && (
            <Grid.Row>
              <Grid.Column width={5}>
                <p>Rejection date*</p>
              </Grid.Column>
              <Grid.Column>
                {!isInEdit && <p>{application.rejectionDate}</p>}
                {isInEdit && (
                  <>
                    <Form.Input
                      name="rejectionDate"
                      type="date"
                      size="mini"
                      style={{ margin: "0px 0px 3.73px", width: "200px" }}
                      onChange={handleChange}
                      defaultValue={application.rejectionDate}
                    />
                    {inputMissing &&
                      (application.rejectionDate === "" ||
                        application.rejectionDate === undefined) && (
                        <p style={{ color: "red" }}>This field is mandatory.</p>
                      )}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={5}>
              <p>Periode from*</p>
              <p>Periode to*</p>
              <br />
            </Grid.Column>
            <Grid.Column withd={8}>
              {!isInEdit && <p>{application.periodFrom}</p>}
              {isInEdit && (
                <>
                  <Form.Input
                    name="periodFrom"
                    type="date"
                    size="mini"
                    style={{ margin: "0px 0px 3.73px", width: "200px" }}
                    onChange={handleChange}
                    defaultValue={application.periodFrom}
                  />
                  {inputMissing &&
                    (application.periodFrom === "" ||
                      application.periodFrom === undefined) && (
                      <p style={{ color: "red" }}>This field is mandatory.</p>
                    )}
                </>
              )}

              {!isInEdit && <p>{application.periodTo}</p>}
              {isInEdit && (
                <>
                  <Form.Input
                    name="periodTo"
                    type="date"
                    size="mini"
                    style={{ margin: "0px 0px 3.73px", width: "200px" }}
                    onChange={handleChange}
                    defaultValue={application.periodTo}
                  />
                  {inputMissing &&
                    (application.periodTo === "" ||
                      application.periodTo === undefined) && (
                      <p style={{ color: "red" }}>This field is mandatory.</p>
                    )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <p>
                <strong>Total project budget in NOK</strong>
              </p>
            </Grid.Column>
            <Grid.Column width={7}>
              <p style={redNegNum(application.totalBudget)}>
                {formatNumber(
                  calculateTotalAmount(application.specificationList)
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <ApplicationSpecification
            periodFrom={application.periodFrom}
            onChange={handleChange}
            isInEdit={isInEdit}
            specifications={application.specificationList}
          />
          <Grid.Row>
            <Grid.Column width={5}>
              <p>Notes</p>
            </Grid.Column>
            <Grid.Column width={8}>
              {!isInEdit && (
                <p>{state.projectForEdit.applications[props.index]?.notes}</p>
              )}{" "}
              {isInEdit && (
                <Form.Field
                  control={TextArea}
                  name="notes"
                  size="mini"
                  style={{ margin: "0px 0px 3.73px", width: "400px" }}
                  onChange={handleChange}
                  defaultValue={application.notes}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {!isInEdit && !state.applicationsEditIndex && (
                <Button
                  onClick={() => {
                    dispatcher({
                      type: "setApplicationsEditIndex",
                      payload: props.index,
                    });
                  }}
                >
                  <Icon name="lock" /> Edit
                </Button>
              )}
              {isInEdit && (
                <>
                  <Button
                    onClick={() => {
                      if (formIsValid()) {
                        props.saveApplication(application.applicationStatus);
                        dispatcher({
                          type: "setApplicationsEditIndex",
                          payload: null,
                        });
                      }
                    }}
                  >
                    <Icon name="unlock" /> Save
                  </Button>
                  <ConfirmDiscardModal
                    discardFunction={discardApplicationChanges}
                    modalContent={modalContentDiscard}
                    modalHeader="Discard Changes"
                  />
                </>
              )}
            </Grid.Column>
            {!isInEdit && (
              <Grid.Column width={8} textAlign="right">
                <ConfirmDeleteModal
                  deleteFunction={deleteApplication}
                  modalContent={modalContentDelete}
                  modalHeader="Delete Application Line"
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
        <br />
        <Divider />
        <br />
      </Form>
    )
  );
};

export default Application;
