
import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'

const InfoButton = (infoData) => {

  const info = infoData.infoData.nonConfidentialInformation;
  let message = "";

  if (info === "" || info === undefined) {
    message = "There is no non-confidential information for this project!";
  } else {
    message = info;
  }

  return (
    <Popup
      trigger={<Icon circular name='info' size="small" />} wide="very">
      {message}
    </Popup>
  )
};

export default InfoButton
