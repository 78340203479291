import React, {useState, useEffect} from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Modal,
  Select,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import {
  addFieldToProject,
  saveProject,
} from "../../functions/project-card-functions";
import OwnerLabel from "../elements/OwnerLabel";
import OwnerList from "../elements/OwnerList";
import { projectManagerDropDownOptions } from "../../functions/project-manager-functions";

const ProjectDetailModalBox = (props) => {
  const exitRouteOptions = require("../../data/ExitRouteOptions.json");
  const project = props.project;

  const [isCheckboxSpinOffChecked, setIsCheckboxSpinOffChecked] = useState(false);
  const [isCheckboxLicenseChecked, setIsCheckboxLicenseChecked] = useState(false);

  const handleChange = (e, { name, value }) => {
    console.log(`Handle change: ${name}, ${value}`);
    props.dispatch({
      type: "setProjectForEdit",
      payload: addFieldToProject(project, name, value),
    });
  };

  useEffect(() => {
    if (props.isOpen) {
      setSpinOffCheckbox();
      setLicenseCheckbox();
    }
   }, [props.isOpen]);

  const ttoProject = "TTO";
  const administrative = "Administrative";
  const innovation = "Innovation";
  const company = "Company";
  const consulting = "Consulting";
  const supportingActivities = "Supporting";

  const displayFor = (showWhen) => {
    return showWhen.includes(project.projectType);
  };

  const setModalClosed = () => {
    props.dispatch({ type: "setModal", payload: "" });
  };

  const setSpinOffCheckbox = () => {
    if (project.spinOffDate || project.spinOffURL) {
      setIsCheckboxSpinOffChecked(true);
    } else {
      setIsCheckboxSpinOffChecked(false);
    }
  };

  const setLicenseCheckbox = () => {
    if (project.licenseDate || project.licenseURL) {
      setIsCheckboxLicenseChecked(true);
    } else {
      setIsCheckboxLicenseChecked(false);
    }
  };

  const handleCancelButton = () => {
    setModalClosed();
  };

  const handleSubmitButton = async () => {
    saveProject(project);
    props.dispatch({ type: "updateProject", payload: project });
    setModalClosed();
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={props.isOpen}
      onClose={setModalClosed}
    >
      <Header icon="edit" content="Project Details" />
      <Modal.Header>
        {" "}
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              {project.id} - {project.projectName}
            </Grid.Column>
            <Grid.Column
              width={4}
              floated="right"
              textAlign="right"
              verticalAlign="top"
            >
              {/* <ProjectSiteButton
              project={project}
              dispatch={props.dispatch}
            ></ProjectSiteButton> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            placeholder="Name of the project"
            label="Project Name"
            name="projectName"
            value={project.projectName}
            onChange={handleChange}
          />
          <Form.Field
            label="Project Manager"
            name="ownerId"
            value={project.ownerId}
            control={Select}
            options={projectManagerDropDownOptions(props.projectManagers)}
            onChange={handleChange}
          />
          {displayFor([administrative]) && (
            <Form.Input
              placeholder="VIS Process Area"
              label="VIS Process Area"
              name="processArea"
              value={project.processArea}
              onChange={handleChange}
            />
          )}
          <Grid verticalAlign="top">
            <Grid.Row>
              <Grid.Column width={3}>
                <Form.Input
                  placeholder="Actual start date of the project"
                  label="Start Date"
                  name="startDate"
                  value={project.startDate && project.startDate.slice(0, 10)}
                  type="Date"
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
            
            {/*
          <Form.Input
            placeholder="Estimated or actual end date of the project"
            label="End Date"
            name="endDate"
            value={project.endDate && project.endDate.slice(0, 10)}
            type="Date"
            onChange={handleChange}
          />
          */}
          {displayFor([ttoProject]) && (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <h3>Exit</h3>
              <Form.Field
                label="Exit Route"
                placeholder="Exit Route"
                name="exitRoute"
                value={project.exitRoute}
                selection
                control={Dropdown}
                options={exitRouteOptions}
                onChange={handleChange}
              />
            </div>
          )}
          </Grid>
          <br />
          <Grid>
            <Grid.Row>
              <Grid.Column width={1}>
                <Checkbox 
                  checked={isCheckboxSpinOffChecked} 
                  onChange={() => setIsCheckboxSpinOffChecked(!isCheckboxSpinOffChecked)}
                  disabled={project.spinOffDate || project.spinOffURL}  
                /> 
              </Grid.Column>
              <Grid.Column width={15}>
                <Form.Input
                  label="Spin-off company established"
                  name="spinOffDate"
                  value={project.spinOffDate && project.spinOffDate.slice(0, 10)}
                  type="Date"
                  width={3}
                  onChange={handleChange}
                  disabled={!isCheckboxSpinOffChecked}
                />
                <Form.Input
                  placeholder={"Spin-off company URL"}
                  icon="linkify"
                  iconPosition="left"
                  size="large"
                  name="spinOffURL"
                  value={project.spinOffURL}
                  onChange={handleChange}
                  disabled={!isCheckboxSpinOffChecked}
                />  
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          
          <br />
          <Grid> 
            <Grid.Row>
              <Grid.Column width={1}>
                <Checkbox 
                  checked={isCheckboxLicenseChecked} 
                  onChange={() => setIsCheckboxLicenseChecked(!isCheckboxLicenseChecked)}
                  disabled={project.licenseDate || project.licenseURL}
                />   
              </Grid.Column>
              <Grid.Column width={15}>
                <Form.Input
                  label="License agreement date"
                  name="licenseDate"
                  value={project.licenseDate && project.licenseDate.slice(0, 10)}
                  type="Date"
                  width={3}
                  onChange={handleChange}
                  disabled={!isCheckboxLicenseChecked}
                />
                <Form.Input
                  placeholder={"License URL"}
                  icon="linkify"
                  iconPosition="left"
                  size="large"
                  name="licenseURL"
                  value={project.licenseURL}
                  onChange={handleChange}
                  disabled={!isCheckboxLicenseChecked}
                />  
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <Form.Input
            placeholder={"Project site URL for " + project.id}
            icon="linkify"
            iconPosition="left"
            size="large"
            label="Project Site URL"
            name="projectSite"
            value={project.projectSite}
            onChange={handleChange}
          />
          {/* {(!project.projectSite || project.projectSite === "") && <Button onClick={() => handleSiteButton()}>
            <Icon name="folder open" />
            Create Project Site
          </Button>} */}
          <h3>DOFI</h3>
          <Form.Input
            label="Registered date"
            name="dofiDate"
            value={project.dofiDate && project.dofiDate.slice(0, 10)}
            type="Date"
            width={3}
            onChange={handleChange}
          />
          <Form.Input
            placeholder={"DOFI document URL for " + project.id}
            icon="linkify"
            iconPosition="left"
            size="large"
            label="DOFI document URL"
            name="dofiURL"
            value={project.dofiURL}
            onChange={handleChange}
          />  
          <br />
          {displayFor([
            administrative,
            ttoProject,
            company,
            innovation,
            supportingActivities,
          ]) && (
            <>
              <Form.Field label="Owning Unit 1 - Main owner"></Form.Field>
              <Form.Field>
                <OwnerLabel owner={project.owningUnit1} />
                <Form.Input
                  placeholder="Main owner"
                  name="owningUnit1"
                  control={OwnerList}
                  value={project.owningUnit1}
                  onChange={handleChange}
                />
              </Form.Field>
            </>
          )}

          {displayFor([ttoProject, innovation]) && (
            <Form.Input
              placeholder="Percentage ownership - Owning Unit 1"
              label="Owner percentage"
              name="ownerPercentage1"
              value={project.ownerPercentage1}
              onChange={handleChange}
            />
          )}

          {displayFor([ttoProject, innovation, supportingActivities]) && (
            <>
              <Form.Field label="Owning Unit 1 - Sub owner"></Form.Field>
              <Form.Field>
                <OwnerLabel owner={project.subOwningUnit} />
                <Form.Input
                  placeholder="Sub owner"
                  name="subOwningUnit"
                  control={OwnerList}
                  value={project.subOwningUnit}
                  onChange={handleChange}
                />
              </Form.Field>
            </>
          )}

          {displayFor([
            administrative,
            ttoProject,
            company,
            innovation,
            supportingActivities,
          ]) && (
            <>
              <Form.Field label="Owning Unit 2 - in other legal entity"></Form.Field>
              <Form.Field>
                <OwnerLabel owner={project.owningUnit2} />
                <Form.Input
                  placeholder="Main owner"
                  name="owningUnit2"
                  control={OwnerList}
                  value={project.owningUnit2}
                  onChange={handleChange}
                />
              </Form.Field>
            </>
          )}

          {displayFor([ttoProject]) && (
            <Form.Input
              placeholder="Percentage ownership - Owning Unit 2"
              label="Owner percentage - Unit 2"
              name="ownerPercentage2"
              value={project.ownerPercentage2}
              onChange={handleChange}
            />
          )}

          {displayFor([innovation, ttoProject]) && (
            <Form.Input
              placeholder="Name of the lead inventor for the project"
              label="Lead Inventor"
              name="leadInventor"
              value={project.leadInventor}
              onChange={handleChange}
            />
          )}

          <Form.Field
            label="Non-confidential Information"
            name="nonConfidentialInformation"
            control={TextArea}
            value={project.nonConfidentialInformation}
            onChange={handleChange}
          />
          {/**
          <Form.Field
            label="VIS-Team"
            placeholder="Select responsible team in VIS."
            name="team"
            value={project.team}
            fluid
            selection
            control={Dropdown}
            options={teamOptions}
            onChange={handleChange}
          />
          */}
          {displayFor([consulting]) && (
            <Form.Input
              placeholder="Client"
              label="Client"
              name="client"
              value={project.client}
              onChange={handleChange}
            />
          )}

          {displayFor([consulting]) && (
            <Form.Input
              placeholder="Contact Person"
              label="Contact Person"
              name="contactPerson"
              value={project.contactPerson}
              onChange={handleChange}
            />
          )}

          {displayFor([administrative, ttoProject]) && (
            <Form.Field
              label="Project Keywords"
              placeholder="Keywords"
              name="projectKeywords"
              value={project.projectKeywords}
              fluid
              multiple
              selection
              control={Dropdown}
              options={props.projectKeywordList}
              onChange={handleChange}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleSubmitButton()}>
          <Icon name="check" />
          Save
        </Button>
        <Button onClick={() => handleCancelButton()}>
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectDetailModalBox;
