import React from 'react'
import {Icon, Button, Grid, Segment, Placeholder} from 'semantic-ui-react'

const CardPlaceholder = (props) => {

    return (
      <>
          <Grid.Column>
              <Segment raised>
                  <Placeholder fluid>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>
          <Grid.Column>
              <Segment raised>
                  <Placeholder>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>

          <Grid.Column>
              <Segment raised>
                  <Placeholder fluid>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>
          <Grid.Column>
              <Segment raised>
                  <Placeholder fluid>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>
          <Grid.Column>
              <Segment raised>
                  <Placeholder fluid>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>
          <Grid.Column>
              <Segment raised>
                  <Placeholder fluid>
                      <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                          <Placeholder.Line length='long' />
                      </Placeholder.Paragraph>
                  </Placeholder>
              </Segment>
          </Grid.Column>
      </>    );
}

export default CardPlaceholder