import { Popup, Icon } from 'semantic-ui-react'
import { reformatDate } from "../functions/reformat-date";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faC } from '@fortawesome/free-solid-svg-icons'
import { openAbsoluteUrl } from '../functions/project-card-functions'

const SpinOffButton = (props) => {
  const spinOffDate = props.project.spinOffDate && props.project.spinOffDate.slice(0, 10);
  const spinOffURL = props.project.spinOffURL;
  let message = "";
  let backgroundColor = null

  if (spinOffDate === "" || spinOffDate === undefined || spinOffDate == null) {
    backgroundColor = null;
    message = "No spin-off company established.";
  } else {
    backgroundColor = '#bce416';
    message = "Spin-off company establishment date: " + reformatDate(spinOffDate);
  }

  const iconStyle = {
    backgroundColor: backgroundColor,
    cursor: spinOffURL === "" || spinOffURL === undefined || spinOffURL == null ? "default" : "pointer",
  }

  return (
    <Popup
        trigger= {<Icon circular size="small" style={iconStyle} onClick={() => openAbsoluteUrl(spinOffURL)}>
                      <FontAwesomeIcon icon={faC} />
                  </Icon>}
    >
        {message}
    </Popup>
  )
};

export default SpinOffButton
