import { lookUpProjectManagerName } from "../functions/project-manager-functions";

const getProjectTeam = (teamMembersArray, projectManagers) => {
    let teamMembers = '';
    let count = 0
    for (const member in teamMembersArray) {
        count++;
        teamMembers += lookUpProjectManagerName(teamMembersArray[member], projectManagers) + (count < teamMembersArray.length ? ", " : "")
    }
    return teamMembers
}

export { getProjectTeam }