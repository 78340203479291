import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import ProjectCardSingle from "./ProjectCardSingle.js";
import { Container, Divider, Grid } from "semantic-ui-react";
import { projectListReducer } from "../functions/project-card-functions";
import ProgressModalBox from "./modals/ProgressModalBox.js";
import ProjectDetailModalBox from "./modals/ProjectDetailModalBox";
import NewProjectModal from "./new/NewProjectModal";
import ArchiveModalBox from "./modals/ArchiveModalBox.js";
import HistoryModal from "./modals/HistoryModal.js";
import BudgetModal from "../budget/BudgetModal.jsx";
import ApplicationModal from "../applications/ApplicationModal.jsx";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import { callApiWithToken, postApiWithToken } from "../functions/fetch";
import { InteractionRequiredAuthError } from "@azure/msal-node";
import TeamMemberAndProjectManagerModalBox from "./modals/TeamMemberAndProjectManagerModalBox";
import CardPlaceholder from "./CardPlaceholder";
import "./ProjectCardTableView.css";
import TTOProcessModal from "./modals/TTOProcessModal.js";

const RouteSingleProject = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);
  const userInfo = {
    userId: account.localAccountId,
    userDetail: account.username,
    userName: account.name,
  };
  const [showList, setShowList] = useState(false);
  const [state, dispatch] = useReducer(projectListReducer, {
    loading: true,
    reload: false,
    listDataLoaded: props.list,
    listOfValuesDataLoaded: props.listOfValues,
    currentViewList: [],
    modal: "",
    userInfo: userInfo,
    userMessage: "",
    projectKeywordList: [],
    projectManagers: [],
    projectForEdit: {},
    filter: {
      runningFilter: { running: true, onHold: false, archived: false },
    },
  });

  const reloadProjects = async () => {
    dispatch({ type: "projectIdFilter" });
    const { projectList } = await (
      await fetch(
        `/api/ProjectData?function=allProjects&userId=${account.localAccountId}`
      )
    ).json();
    dispatch({ type: "newListFromServer", payload: projectList });
  };

  async function getlistOfValues(listName) {
    const { listOfValues } = await (
      await fetch(
        `/api/ProjectData?function=getListOfValues&listName=${listName}&userId=${account.localAccountId}`
      )
    ).json();
    const action = "listOfValues-" + listName;
    dispatch({ type: action, payload: listOfValues });
  }

  async function getProjectManagers() {
    const projectManagers = await (
      await fetch(
        `/api/ProjectData?function=getProjectManagers&userId=${account.localAccountId}`
      )
    ).json();
    const action = "setProjectManagerList";
    dispatch({ type: action, payload: projectManagers });
  }

  const createProjectSite = async (project) => {
    const groupName = "VIS-Project-Site-" + project.id;
    const projectManager =
      "https://graph.microsoft.com/v1.0/users/" + project.ownerId;
    const body = {
      description: project.projectName,
      displayName: groupName,
      groupTypes: ["Unified"],
      mailEnabled: false,
      mailNickname: groupName,
      securityEnabled: true,
      "owners@odata.bind": [projectManager],
      "members@odata.bind": [projectManager],
    };

    instance
      .acquireTokenSilent({
        scopes: protectedResources.graphCreateGroup.scopes,
        account: account,
      })
      .then((response) => {
        postApiWithToken(
          response.accessToken,
          protectedResources.graphCreateGroup.endpoint,
          body
        ).then((response) => console.log(response));
      })
      .catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === "none") {
            instance
              .acquireTokenPopup({
                scopes: protectedResources.graphCreateGroup.scopes,
              })
              .then((response) => {
                postApiWithToken(
                  response.accessToken,
                  protectedResources.graphCreateGroup.endpoint,
                  body
                ).then((response) => console.log(response));
              })
              .catch((error) => console.log(error));
          }
        }
      });
  };

  useEffect(() => {
    if (account && inProgress === "none" && !graphData) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.graphGroup.scopes,
          account: account,
        })
        .then((response) => {
          callApiWithToken(
            response.accessToken,
            protectedResources.graphGroup.endpoint
          ).then((response) => setGraphData(response));
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.graphGroup.scopes,
                })
                .then((response) => {
                  callApiWithToken(
                    response.accessToken,
                    protectedResources.graphGroup.endpoint
                  ).then((response) => setGraphData(response));
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [account, inProgress, instance]);

  useEffect(() => {
    if (state.reload) {
      (async function () {
        dispatch({ type: "doReload", payload: {} });
      })();
    }
  }, [state.reload]);
  let projectId = useParams().projectId;
  useEffect(() => {
    if (
      graphData &&
      graphData.value &&
      graphData.value[0].displayName === "GatewayPortfolioManagers"
    ) {
      getlistOfValues("projectKeywords");
      getProjectManagers();
      (async function () {
        const { projectList } = await (
          await fetch(
            `/api/ProjectData?function=getProject&userId=${account.localAccountId}&projectId=${projectId}`
          )
        ).json();
        if (projectList.length === 0) {
          const userMessage = "User does not have access to any projects";
          dispatch({ type: "emptyListFromServer", payload: userMessage });
        } else {
          dispatch({ type: "newListFromServer", payload: projectList });
        }
      })();
    }
  }, [graphData, projectId]);

  let projectFound = [];
  const projectNotFound = {
    id: "PROJECT NOT FOUND! PLEASE CHECK YOUR URL!",
    projectName: "PROJECT NOT FOUND! PLEASE CHECK YOUR URL!",
    projectGate: null,
    projectTeam: [],
    projectDescription: "PROJECT NOT FOUND! PLEASE CHECK YOUR URL!",
    archiveStatus: "notFound",
    ownerId: null,
    owningUnit1: null,
    owningUnit2: null,
    team: null,
    history: [],
  };
  if (state.listDataLoaded.length > 0) {
    projectFound =
      state.listDataLoaded.find((el) => el.id === projectId) || projectNotFound;
    return (
      <Container>
        <div>
          <h3>
            User {account && account.name}{" "}
            <p style={{ color: "red" }}>{state.userMessage}</p>
          </h3>
        </div>
        <NewProjectModal
          projectKeywordList={state.projectKeywordList}
          projectManagers={state.projectManagers}
          userInfo={state.userInfo}
          isOpen={state.modal === "newProject"}
          dispatch={dispatch}
          reload={reloadProjects}
        />
        <ProgressModalBox
          project={state.projectForEdit}
          projectKeywordList={state.projectKeywordList}
          userInfo={state.userInfo}
          isOpen={state.modal === "progressModal"}
          dispatch={dispatch}
        />
        <ArchiveModalBox
          project={state.projectForEdit}
          userInfo={state.userInfo}
          isOpen={state.modal === "archiveModal"}
          dispatch={dispatch}
        />
        <ProjectDetailModalBox
          project={state.projectForEdit}
          projectManagers={state.projectManagers}
          projectKeywordList={state.projectKeywordList}
          createProjectSite={createProjectSite}
          isOpen={state.modal === "projectDetails"}
          dispatch={dispatch}
        />
        {/*<StageGateDecision project={state.projectForEdit} userInfo={state.userInfo} isOpen={state.modal === 'stageGateDecisionModal'} dispatch={dispatch} />*/}
        <TTOProcessModal
          project={state.projectForEdit}
          projectKeywordList={state.projectKeywordList}
          userInfo={state.userInfo}
          isOpen={state.modal === "stageGateDecisionModal"}
          dispatch={dispatch}
        />
        <HistoryModal
          project={state.projectForEdit}
          userInfo={state.userInfo}
          projectManagers={state.projectManagers}
          isOpen={state.modal === "historyModal"}
          dispatch={dispatch}
        />
        <TeamMemberAndProjectManagerModalBox
          project={state.projectForEdit}
          userInfo={state.userInfo}
          projectManagers={state.projectManagers}
          projectKeywordList={state.projectKeywordList}
          isOpen={state.modal === "teamMemberAndProjectManagerModal"}
          dispatch={dispatch}
        />
        <BudgetModal
          project={state.projectForEdit}
          userInfo={state.userInfo}
          isOpen={state.modal === "budgetModal"}
          dispatch={dispatch}
        />
        <ApplicationModal
          project={state.projectForEdit}
          userInfo={state.userInfo}
          isOpen={state.modal === "applicationModal"}
          dispatch={dispatch}
        />

        <Divider hidden></Divider>
        {!showList && (
          <Grid stackable columns="1">
            {state.loading && <CardPlaceholder />}
            <Grid.Column>
              <ProjectCardSingle
                project={projectFound}
                projectDispatch={dispatch}
                projectManagers={state.projectManagers}
              />
            </Grid.Column>
          </Grid>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <div>
          <h3>
            User {account && account.name}{" "}
            <p style={{ color: "red" }}>{state.userMessage}</p>
          </h3>
        </div>
        <Grid stackable columns="1">
          {state.loading && <CardPlaceholder />}
          <Grid.Column>
            <h1>PROJECT NOT FOUND!</h1>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
};

export default RouteSingleProject;
