import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

const NewProjectButton = (props) => {

  const openModal = () => {
    props.dispatch({type: 'setModal', payload:'newProject'})
  }
  return (
    <Button onClick={openModal}>
      <Button.Content visible><Icon name="arrow right"/>New Project</Button.Content>
    </Button>      
  );
}

export default NewProjectButton