import React from "react";
import { Checkbox, Grid } from "semantic-ui-react";

const RunningFilter = (props) => {
  //const [showRunning, updateRunning] = useState(true)
  //const [showArchive, updateArchive] = useState(false)
  const showRunning = props.filterState.runningFilter.running;
  const showArchive = props.filterState.runningFilter.archived;
  const showOnHold = props.filterState.runningFilter.onHold;
  //const [showOnHold, updateOnHold] = useState(false)

  const onRunningChange = (currentNode, change) => {
    props.filterDispatch({
      type: "runningFilter",
      payload: {
        running: change.checked,
        onHold: showOnHold,
        archived: showArchive,
      },
    });
  };
  const onArchiveChange = (currentNode, change) => {
    props.filterDispatch({
      type: "runningFilter",
      payload: {
        running: showRunning,
        onHold: showOnHold,
        archived: change.checked,
      },
    });
  };
  const onOnHoldChange = (currentNode, change) => {
    props.filterDispatch({
      type: "runningFilter",
      payload: {
        running: showRunning,
        onHold: change.checked,
        archived: showArchive,
      },
    });
  };

  return (
    <Grid>
      <Grid.Row double={true.toString()} columns={4}>
        <Grid.Column>
          <Checkbox
            label="Running"
            toggle
            checked={showRunning}
            onChange={onRunningChange}
          />
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            label="Archived"
            toggle
            checked={showArchive}
            onChange={onArchiveChange}
          />
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            label="On-hold"
            toggle
            checked={showOnHold}
            onChange={onOnHoldChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RunningFilter;
