import { sortedBudgetLines } from "../budget/budget-functions";

const appReducer = (state, action) => {
  switch (action.type) {
    case "setProjectForEdit":
      const project = action.payload;
      console.log(`Project for edit: ${project.id}`);
      return {
        ...state,
        projectForEdit: project,
        budgetForEdit: project.budget ? sortedBudgetLines(project.budget) : [],
        applicationsForEdit: project.applications ? project.applications : [],
      };
    case "cancelApplicationForEdit":
      console.log(`Cancel application for edit`);
      return {
        ...state,
        applicationsForEdit: state.projectForEdit.applications
          ? state.projectForEdit.applications
          : [],
        applicationsEditIndex: null,
      };
    case "setBudgetForEdit":
      return {
        ...state,
        budgetForEdit: action.payload,
      };
    case "setBudgetEditIndex":
      return {
        ...state,
        budgetEditIndex: action.payload,
      };
    case "setApplicationsForEdit":
      return {
        ...state,
        applicationsForEdit: action.payload,
      };
    case "setApplicationsEditIndex":
      return {
        ...state,
        applicationsEditIndex: action.payload,
      };
    case "setModal":
      console.log(`Modal for display: "${action.payload}"`);
      return {
        ...state,
        modal: action.payload,
      };
    default:
      return state;
  }
};
export { appReducer };
