import React, { useState, useEffect } from "react";
import { Button, Label, Popup } from "semantic-ui-react";

const nextColor = (statusColor) => {
  switch (statusColor) {
    case "green":
      return "yellow";
    case "yellow":
      return "red";
    case "red":
      return "default";
    default:
      return "green";
  }
};

const RAGButton = (props) => {
  const initialColor = props.color === "Green" ? "green" : props.color;
  const initialChange = props.readonly;
  const [status, setStatus] = useState(initialColor);
  const readonlyStatus = initialChange;

  //Reset for filter - button status
  useEffect(() => {
    if (props.resetFilters) setStatus(initialColor);
  }, [props.resetFilters]);

  const onColorChange = (click) => {
    if (!readonlyStatus) {
      const theColor = nextColor(click);
      setStatus(theColor);
      if (props.name) {
        const event = { name: props.name, value: theColor };
        props.onChange(click, event);
      }
    }
  };

  const divStyle = {
    padding: "5px 1px 0px 1px",
    minWidth: "35%",
    float: "left",
  };

  if (readonlyStatus) {
    return (
      <Popup
        trigger={
          <Label
            style={{
              float: "left",
              width: "124px",
              verticalAlign: "baseline",
              backgroundColor: "#fff0",
            }}
          >
            <Label
              style={{
                float: "left",
                verticalAlign: "baseline",
                color: "rgb(0 0 0)",
              }}
              circular
              color={initialColor}
              name="circle"
            />
            <div style={divStyle}> {props.ragTopic}</div>
          </Label>
        }
      >
        Traffic lights for status
      </Popup>
    );
  }

  return (
    <Popup
      trigger={
        <Button
          color={status}
          size="mini"
          onClick={() => onColorChange(status)}
          style={{ width: "80px" }}
          compact
        >
          {props.ragTopic}
        </Button>
      }
      wide="very"
    >
      Traffic Lights
    </Popup>
  );
};

export default RAGButton;
