// function that returns new ApplicationSpecificationItem on the basis of the type of the item
// {
//  "type": "VISHours",
//  "partner": null,
//  "year": "2022",
//  "amount": "50000"
//}
const createSpecificationItem = (
  type: string,
  partner: any,
  year: string,
  amount: string
) => {
  return {
    type: type,
    partner: partner,
    year: year,
    amount: amount,
  };
};

const getYearFromDate = (date: any): string => {
  if (!date) {
    return new Date().getFullYear().toString();
  }
  return new Date(date).getFullYear().toString();
};

// Function returs a list of years based on start year passed as string parameter and the number of years passed as number parameter
const getLifeTimeYears = (startYear: any, numberOfYears: any) => {
  const startYearNumber = parseInt(startYear);
  let years = [];
  for (let i = 0; i < numberOfYears; i++) {
    years.push("" + (startYearNumber + i));
  }
  return years;
};

// function that add specification item to the list of specification items or updates the existing item in the list
const addOrUpdateSpecificationItem = (
  specificationItems: any,
  specificationItem: any
) => {
  const index = specificationItems.findIndex(
    (item: any) =>
      item.type === specificationItem.type &&
      item.partner === specificationItem.partner &&
      item.year === specificationItem.year
  );
  if (index === -1) {
    specificationItems.push(specificationItem);
  } else {
    specificationItems[index] = specificationItem;
  }
  return specificationItems;
};

const isListWithReduce = (obj: any) => {
  return Array.isArray(obj) && typeof obj.reduce === "function";
};

const calculateTotalAmount = (specificationItems: any, year?: any) => {
  if (!specificationItems || !isListWithReduce(specificationItems)) {
    return -1;
  }

  return specificationItems.reduce((totalAmount: any, item: any) => {
    const amount = parseInt(item.amount);

    if ((year === item.year || year === undefined) && !isNaN(amount)) {
      return totalAmount + amount;
    } else {
      return totalAmount;
    }
  }, 0);
};

const cleanApplicationForSave = (application: any) => {
  if (!application) {
    return null;
  }
  const specifications = application.specificationList.filter(
    (item: any) => item.amount > 0
  );
  const totalAmount = calculateTotalAmount(specifications);
  const applicationCopy = {
    ...application,
    specificationList: specifications,
    totalBudget: totalAmount,
  };
  return applicationCopy;
};

const doesPeriodOverlapWithCurrentYear = (application: any): boolean => {
  const currentYear = new Date().getFullYear();

  const periodFromYear = new Date(application.periodFrom).getFullYear();
  const periodToYear = new Date(application.periodTo).getFullYear();

  return periodFromYear === currentYear || periodToYear === currentYear;
};

const isTodayInPeriod = (application: any): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison

  const periodFrom = new Date(application.periodFrom);
  const periodTo = new Date(application.periodTo);
  periodTo.setHours(23, 59, 59, 999); // Set the time to 23:59:59 for accurate comparison

  return today >= periodFrom && today <= periodTo;
};

const isWithinLastThreeMonths = (date: string): boolean => {
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  threeMonthsAgo.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison

  const dateToCheck = new Date(date);
  if (dateToCheck.toDateString() !== new Date().toDateString()) {
    // If the date is not today, set the time to 23:59:59 for accurate comparison
    dateToCheck.setHours(23, 59, 59, 999);
  }

  return dateToCheck >= threeMonthsAgo && dateToCheck <= new Date();
};

const statusApplications = (applications: any) => {
  // Undefined if no applications
  if (!applications || !applications.length) {
    return undefined;
  }

  // Remove all null entries
  applications = applications.filter(
    (application: any) => application !== null
  ); // TODO Imporve the first part

  if (!applications.length) {
    return undefined;
  }

  // Grey if no applications this year
  const applicationsThisYear = applications.filter((application: any) =>
    doesPeriodOverlapWithCurrentYear(application)
  );
  if (!applicationsThisYear.length) {
    return undefined;
  }

  // Check if today is within any application period
  const applicationsWithinPeriodToday = applications.filter(
    (application: any) => isTodayInPeriod(application)
  );
  if (applicationsWithinPeriodToday.length) {
    // Green if any application within application period today is approved
    const applicationsInPeriodTodayApproved =
      applicationsWithinPeriodToday.filter(
        (application: any) => application.applicationStatus === "Approved"
      );
    if (applicationsInPeriodTodayApproved.length) {
      return "green";
    }

    // Yellow if any applications within application period today is in progress or sent
    const applicationsInPeriodTodayStarted =
      applicationsWithinPeriodToday.filter(
        (application: any) =>
          application.applicationStatus === "In progress" ||
          application.applicationStatus === "Sent"
      );
    if (applicationsInPeriodTodayStarted.length) {
      return "yellow";
    }
  }

  // Red if any application (this year) were rejected in the last three months
  const applicationsRejectedRecently = applicationsThisYear.filter(
    (application: any) =>
      application.applicationStatus === "Rejected" &&
      isWithinLastThreeMonths(application.rejectionDate)
  );
  if (applicationsRejectedRecently.length > 0) {
    return "red";
  }

  return undefined;
};

export {
  createSpecificationItem,
  getLifeTimeYears,
  addOrUpdateSpecificationItem,
  calculateTotalAmount,
  getYearFromDate,
  cleanApplicationForSave,
  isTodayInPeriod,
  statusApplications,
};
