import React, { useRef, useMemo } from 'react';
import {
  Button,
  Header,
  Dropdown,
  Icon,
  Modal,
  Form,
  Divider,
} from "semantic-ui-react";
import {
  saveProject,
  addFieldToProject,
} from "../../functions/project-card-functions";
import RAGButton from "../RAGButton";

import JoditEditor from "jodit-react";

const ProgressModalBox = (props) => {
  const project = props.project;
  const userInfo = props.userInfo;

  const editor = useRef(null);

  const config = useMemo(() => (
    {
      readonly: false,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      statusbar: false,
      minHeight: "50",
      buttons: [
        'bold', 'ul', 'ol', 'fontsize',
        'paragraph', 'indent', 'outdent', 'undo', 'redo'
      ],
      removeButtons: ['break']
    }), [])

  const handleChange = (e, { name, value }) => {
    console.log(`Handle change: ${name}, ${value}`);
    props.dispatch({ type: 'setProjectForEdit', payload: addFieldToProject(project, name, value) });
  };

  const handleProgressValue = (value) => {
    handleChange({}, { name: "progressDescription", value: value })
  }

  const handleNextStepsValue = (value) => {
    handleChange({}, { name: "nextSteps", value: value })
  }

  const handleRiskEvaluationValue = (value) => {
    handleChange({}, { name: "riskEvaluation", value: value })
  }

  const historyObject =
  {
    type: "status",
    userId: userInfo.userId,
    userName: userInfo.userDetails,
    statusProgress: project.statusProgress,
    statusCost: project.statusCost,
    statusRisk: project.statusRisk,
    progressDescription: project.progressDescription,
    nextSteps: project.nextSteps,
    riskEvaluation: project.riskEvaluation
  }
  const setModalClosed = () => {
    props.dispatch({ type: 'setModal', payload: '' })
  }

  const handleCancelButton = () => {
    setModalClosed();
  };

  const handleSubmitButton = async () => {
    project.history.push(historyObject);
    saveProject(project);
    props.dispatch({ type: 'updateProject', payload: project })
    setModalClosed();
    console.log("===>>PROJECT", project)
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={props.isOpen}
      onClose={setModalClosed}
    >
      <Header icon="cogs" content="Evaluation of current project status" />
      <Modal.Header>{props.project.id} - {project.projectName}</Modal.Header>
      <Modal.Content>
        <Form>
          <RAGButton name="statusProgress" color={project.statusProgress} onChange={handleChange} ragTopic="Progress" />
          <RAGButton name="statusCost" color={project.statusCost} onChange={handleChange} ragTopic="Cost" />
          <RAGButton name="statusRisk" color={project.statusRisk} onChange={handleChange} ragTopic="Risk" />

          <Divider />

          <Form.Field
            placeholder="Keywords"
            name="projectKeywords"
            value={project.projectKeywords}
            fluid
            multiple
            selection
            control={Dropdown}
            options={props.projectKeywordList}
            onChange={handleChange}
          />

          <Divider />
          <div>
            <p><strong>Progress Description</strong></p>
            <div>
              <JoditEditor
                ref={editor}
                value={project.progressDescription}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={handleProgressValue} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}
              />
            </div>
          </div>
          <Divider />
          <div></div>
          <div>
            <p><strong>Next Steps</strong></p>
            <div>
              <JoditEditor
                ref={editor}
                value={project.nextSteps}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={handleNextStepsValue} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}
              />
            </div>
          </div>
          <Divider />
          <div></div>
          <div>
            <p><strong>Risk Evaluation</strong></p>
            <div>
              <JoditEditor
                ref={editor}
                value={project.riskEvaluation}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={handleRiskEvaluationValue} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}
              />
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleSubmitButton()}>
          <Icon name="check" />
          Confirm
        </Button>
        <Button onClick={() => handleCancelButton()}>
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProgressModalBox;