import React from 'react';
import RAGButton from "./RAGButton";
import ArchiveStatusIcon from './ArchiveStatusIcon'
import { lookUpProjectManagerName } from "../functions/project-manager-functions";
import { Table, Popup, Button, Icon } from 'semantic-ui-react'
import { reformatDate } from '../functions/reformat-date'
import { getLastUpdatedDate } from '../functions/getLastUpdated';
import { getProjectTeam } from '../functions/getProjectTeam';
import { Parser } from "html-to-react";
import { statusBudget } from '../budget/budget-functions';
import { statusApplications } from '../applications/application-functions';

const ProjectCardTableView = (props) => {
  const showProjectAsCard = (change) => {
    props.handleCardView()
    props.filterDispatch({ type: 'projectIdFilter', payload: change })
  }

  const openInNewTab = url => {
    window.open("/" + url);
  }

  const colors = (archiveStatus) => {
    if (archiveStatus === 'Running') return '#f0f8ff'
    if (archiveStatus === 'On-hold') return '#ffffe0'
    if (archiveStatus === 'Archived') return '#F6F6F6'
  }

  const htmlParser = new Parser();

  return (
    <Table.Body>
      {props.currentList.map((item) => (
        <Table.Row key={item.id}  >
          {props.showId && <Table.Cell>
            <Button style={{ 'backgroundColor': colors(item.archiveStatus) }} size='mini' onClick={() => openInNewTab(item.id)}><Icon name="id card" />
              {item.id}
              <ArchiveStatusIcon props={item.archiveStatus} />
            </Button>
          </Table.Cell>}
          {props.showStartDate && <Table.Cell>{reformatDate(item.startDate)}</Table.Cell>}
          {props.showName && <Table.Cell>{item.projectName}</Table.Cell>}
          {props.showPhase && <Table.Cell>{item.projectGate}</Table.Cell>}
          {props.showProgress && <Table.Cell>{htmlParser.parse(item.progressDescription)}</Table.Cell>}
          {props.showNextSteps && <Table.Cell>{htmlParser.parse(item.nextSteps)}</Table.Cell>}
          {props.showRiskEvaluation && <Table.Cell>{htmlParser.parse(item.riskEvaluation)}</Table.Cell>}
          {props.showTrafficLights &&
            <Table.Cell>
              <RAGButton
                color={item.statusProgress} readonly={true}
                ragTopic='Progress'
              />
              <RAGButton
                color={item.statusCost} readonly={true}
                ragTopic='Cost'
                marginTop="2px"
              />
              <RAGButton
                color={item.statusRisk} readonly={true}
                ragTopic='Risk'
                marginTop="2px"
              />
              <RAGButton
                color={statusBudget(item.budget)} 
                readonly={true}
                ragTopic='Budget'
                marginTop="2px"
              />
              <RAGButton
                color={statusApplications(item.applications)} 
                readonly={true}
                ragTopic='Applications'
                marginTop="2px"
              />
            </Table.Cell>}
          {props.showProjectManager && <Table.Cell>{lookUpProjectManagerName(item.ownerId, props.projectManagers)}</Table.Cell>}
          {props.showLeadInventor && <Table.Cell>{item.leadInventor}</Table.Cell>}
          {props.showOwner1 && <Table.Cell>{item.owningUnit1}</Table.Cell>}
          {props.showOwner2 && <Table.Cell>{item.owningUnit2}</Table.Cell>}
          {props.showLinks && <Table.Cell>
            <Popup
              trigger={
                <Button icon size="mini" onClick={() => window.open(item.projectSite, "_blank")}
                  compact><Icon name='folder open' />
                </Button>
              } wide="very">
              {item.projectSite}
            </Popup>
          </Table.Cell>}
          {props.showTeamMembers && <Table.Cell>{getProjectTeam(item.projectTeam, props.projectManagers)}</Table.Cell>}
          {props.showExitRoute && <Table.Cell>{item.exitRoute}</Table.Cell>}
          {props.showLastUpdated && <Table.Cell>{reformatDate(getLastUpdatedDate(item.history))}</Table.Cell>}
          {props.showInformation && <Table.Cell>{item.nonConfidentialInformation}</Table.Cell>}
          {props.showDofiDate&& <Table.Cell>{reformatDate(item.dofiDate && item.dofiDate.slice(0, 10))}</Table.Cell>}
          {props.showDofiURL && <Table.Cell>{item.dofiURL}</Table.Cell>}
          {props.showSpinOffDate&& <Table.Cell>{reformatDate(item.spinOffDate && item.spinOffDate.slice(0, 10))}</Table.Cell>}
          {props.showLicenseDate&& <Table.Cell>{reformatDate(item.licenseDate && item.licenseDate.slice(0, 10))}</Table.Cell>}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default ProjectCardTableView

