import React, { useState } from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'

const ProjectSiteButton = (props) => {
  const project = props.project
  const site = project.projectSite
  const siteText = project.projectSite ? 'Project Site - ' + project.projectSite : 'Click to create project site'
  const [lagerSite, setLagerSite] = useState(0)

  const saveWithNewProjectSite = async (project) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(project),
    };
    console.log("Site to be created for: ", project.id);
    const response = await fetch(
      `/api/ProjectData?function=newProjectSite&projectId=${project.id}`,
      requestOptions
    );
    await response.json();
    return project;
  }

  const openSite = (url) => {
    if (!url) {
      const siteURL = "https://bergento.sharepoint.com/sites/VISProjectSites/" + project.id
      const fieldName = "projectSite"
      const projectWithUrl = { ...project, [fieldName]: siteURL }
      saveWithNewProjectSite(projectWithUrl);
      props.dispatch({ type: 'updateProject', payload: projectWithUrl });
      setLagerSite(true)
      setLagerSite(1)
      return
    }
    console.log(url)
    window.open(url)
  }

  if (lagerSite === 1) {
    setTimeout(function () {
      setLagerSite(0)
    }, 8000);
  
    return (
      <div class="ui tiny active inline loader"></div>
    );
  }

  const iconName = project.projectSite ? 'folder open' : 'plus circle' 

  return (
    <Popup
      trigger={<Button icon size="mini" onClick={() => openSite(site)} compact><Icon name={iconName} /></Button>
      } wide="very">
      {siteText}
    </Popup>
  );
}

export default ProjectSiteButton