// import './App.css';
import React from "react";
import "semantic-ui-css/semantic.min.css";
import {Image} from "semantic-ui-react";
import visLogo from "./data/VIS_logo_2024_small.jpg"
import ProjectCardList from "./components/ProjectCardList.js";
import { Container, Grid, Header } from "semantic-ui-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ExportProject from "./components/ExportProject";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { PageLayout } from "./ui.jsx"
import RouteSingleProject from "./components/RouteSingleProject.js";
import { AppStateProvider } from "./state/AppStateProvider.jsx";

// temp helper var for project description, cause it's the same sample for all
const tmpDesc = "Lorem ipsum.";

function App({ msalInstance }) {

  const emptyList = []

  const testList = [
    {
      id: "80394", // display
      version: 12, // not displayed
      projectName: "DARCBLUETest201901", // display and changeable
      projectGate: "1", // display and separate component
      projectType: "TTO", // display
      projectTeam: ["member1", "member2", "member3"],
      projectOnlineGUID: "eacc714b-bb49-e611-80eb-00155dd4800b", // non display
      startDate: "2019-01-30T00:00:00Z", // Display and change
      ownerId: "512a8d41-8921-e611-80e1-00155dd04106", // non display ==> separate User Story to research User based on Azure AD
      projectSite:
        "https://bergento.sharepoint.com/sites/pwa/DARCBLUETest201901", //display and change
      signedDofiSaved: "2021-01-12T23:00:00Z", // non display
      ownerIdUnit4: "4", // non display
      nonConfidentialInformation: " ​skal ha verdi ", // display and change
      archiveStatus: "Running", // display and change (list)  ==> (new User Story to manage archiving of projects)
      leadInventor: "Peter Tompson", // display and change
      keywords: "Team Marin", // display and change (nested! list)
      expectedDealType: "Company", // display and change (list)
      processArea: "TTO Process", // display and change (list)
      team: "Kommunikasjon", // display and change (list)
      owningUnit1: "VIS", // display and change (list) ==> (new User Story for rendering list tree in separate component)
      owningUnit2: "Darcblue",
      fields: "Aquaculture", // display and change (nested list | multi choice, Base is CategoryList)
      statusCost: "green", // display and change (RAGBUtton is there)
      statusProgress: "red", // display and change (RAGBUtton is there)
      statusRisk: "yellow", // display and change (RAGBUtton is there)
      statusBudget: "red",
      nextSteps: "this is nextStep",
      progressDescription: "this is progressDecription",
      riskEvaluation: "High risk of electricity",
      history: [
        {
          type: "status",
          timestamp: "2021-12-01T00:00:00Z",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",
        },
        {
          type: "status",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",
        },
        {
          type: "status",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          statusProgress: "green",
          statusCost: "green",
          statusRisk: "yellow",
          progressDescription: "PD tree",
          nextSteps: "NS tree",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 1,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Idea identified as DOFI ready",
            "Researcher(s) is well informed about the process and expectations are clarified",
            "Idea registered as phase 1 in the VIS project center",
            "Prepared Basic recommendation",
            "Presented to the Head of TTO or deputy",
            "Agreement  to proceed to phase 2"
          ],
          decision: "go next gate",
          description: "Gate hist One",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 2,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Idea registered as phase 2 in the VIS project center",
            "Patentability assessment executed if relevant",
            "Preliminary IP strategy ready",
            "Preliminary market analysis ready if relevant"
          ],
          decision: "go next gate",
          description: "Gate hist two",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 3,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Rights have been transferred",
            "Innovation Agreement signed",
            "Exit Agreement signed",
            "Exit strategy concluded",
            "Idea/Project is ready for realisation (license, start-up, other)",
            "Exit strategy presented to the Project board"
          ],
          decision: "go next gate",
          description: "Gate hist tree",
        },

        {
          type: "archive",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          archiveStatus: "Running",
          archiveComment: "Let it go",
        },
        {
          type: "archive",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          archiveStatus: "On-hold",
          archiveComment: "On-hold activated",
        },
        {
          type: "archive",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          archiveStatus: "Running",
          archiveComment: "Let it go again",
        },

        {
          type: "changes",
          timestamp: "2021-12-01T00:00:00Z",
          userId: "16140125-17df-eb11-aaa4-00155d91290c",
          change: "Project Manager changed"
        }

      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 1,
          consultancyExpenses: 2,
          patentCosts: 3,
          travelAndMeetingExpenses: 4,
          otherExpenses: 5,
          remainingAmountForVISHours: 149985,
          comments: "This is a comment."
        },
        {
          budgetYear: 2023,
          sourceOfFinancing: "Owner financed",
          periodFrom: "01.01.2023",
          periodTo: "31.12.2023",
          totalFinancedAmount: 160000,
          asvExpenses: 10,
          consultancyExpenses: 20,
          patentCosts: 30,
          travelAndMeetingExpenses: 40,
          otherExpenses: 50,
          remainingAmountForVISHours: 159850,
          comments: "And ...this is a comment for the second budget line."
        }
      ]
    },
    {
      id: "80366", // display
      version: 12, // not displayed
      projectName: "Stage Gate Filter Test", // display and changeable
      projectGate: "2", // display and separate component
      projectType: "TTO", // display
      projectTeam: ["member1", "member2", "member3"],
      projectOnlineGUID: "eacc714b-bb49-e611-80eb-00155dd4800b", // non display
      startDate: "2019-01-30T00:00:00Z", // Display and change
      ownerId: "512a8d41-8921-e611-80e1-00155dd04106", // non display ==> separate User Story to research User based on Azure AD
      projectSite:
        "https://bergento.sharepoint.com/sites/pwa/DARCBLUETest201901", //display and change
      signedDofiSaved: "2021-01-12T23:00:00Z", // non display
      ownerIdUnit4: "4", // non display
      nonConfidentialInformation: " ​skal ha verdi ", // display and change
      archiveStatus: "Running", // display and change (list)  ==> (new User Story to manage archiving of projects)
      leadInventor: "Peter Tompson", // display and change
      keywords: "Team Marin", // display and change (nested! list)
      expectedDealType: "Company", // display and change (list)
      processArea: "TTO Process", // display and change (list)
      team: "Kommunikasjon", // display and change (list)
      owningUnit1: "VIS", // display and change (list) ==> (new User Story for rendering list tree in separate component)
      owningUnit2: "Darcblue",
      fields: "Aquaculture", // display and change (nested list | multi choice, Base is CategoryList)
      statusCost: "green", // display and change (RAGBUtton is there)
      statusProgress: "red", // display and change (RAGBUtton is there)
      statusRisk: "yellow", // display and change (RAGBUtton is there)
      statusBudget: "red",
      nextSteps: "this is nextStep",
      progressDescription: "this is progressDecription",
      history: [
        {
          type: "status",
          timestamp: "2021-12-01",
          user: [{
            "userId": "055b578d6833b8a6b15f1bd57f4e413a",
            "userRoles": ["anonymous", "authenticated", "pm"],
            "identityProvider": "aad",
            "userDetails": "darcblue@bergento.com"
          }],
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",
        },
        {
          type: "status",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",
        },
        {
          type: "status",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          statusProgress: "green",
          statusCost: "green",
          statusRisk: "yellow",
          progressDescription: "PD tree",
          nextSteps: "NS tree",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 1,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Idea identified as DOFI ready",
            "Researcher(s) is well informed about the process and expectations are clarified",
            "Idea registered as phase 1 in the VIS project center",
            "Prepared Basic recommendation",
            "Presented to the Head of TTO or deputy",
            "Agreement  to proceed to phase 2"
          ],
          decision: "go next gate",
          description: "Gate hist One",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 2,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Idea registered as phase 2 in the VIS project center",
            "Patentability assessment executed if relevant",
            "Preliminary IP strategy ready",
            "Preliminary market analysis ready if relevant"
          ],
          decision: "go next gate",
          description: "Gate hist two",
        },

        {
          type: "gate",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          gate: 3,
          decisionDate: "2021-12-01T00:00:00Z",
          checklist: [
            "Rights have been transferred",
            "Innovation Agreement signed",
            "Exit Agreement signed",
            "Exit strategy concluded",
            "Idea/Project is ready for realisation (license, start-up, other)",
            "Exit strategy presented to the Project board"
          ],
          decision: "go next gate",
          description: "Gate hist tree",
        },

        {
          type: "archive",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          archiveStatus: "Running",
          archiveComment: "Let it go",
        },
        {
          type: "archive",
          userId: "9f723339-da03-e911-afb4-00155d788512",
          timestamp: "2021-12-01T00:00:00Z",
          archiveStatus: "On-hold",
          archiveComment: "On-hold activated",
        },
        {
          type: "archive",
          timestamp: "2022-01-15",
          archiveStatus: "Running",
          archiveComment: "Let it go again",
        }

      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        },
        {
          budgetYear: 2023,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "80904", // display
      version: 12, // not displayed
      projectName: "Stage Gate filter test data 2", // display and changeable
      projectGate: "6", // display and separate component
      projectType: "TTO", // display
      projectTeam: ["member1", "member2", "member3"],
      projectOnlineGUID: "eacc714b-bb49-e611-80eb-00155dd4800b", // non display
      startDate: "2019-01-30T00:00:00Z", // Display and change
      ownerId: "512a8d41-8921-e611-80e1-00155dd04106", // non display ==> separate User Story to research User based on Azure AD
      projectSite:
        "https://bergento.sharepoint.com/sites/pwa/DARCBLUETest201901", //display and change
      signedDofiSaved: "2021-01-12T23:00:00Z", // non display
      ownerIdUnit4: "4", // non display
      nonConfidentialInformation: " ​skal ha verdi ", // display and change
      archiveStatus: "Running", // display and change (list)  ==> (new User Story to manage archiving of projects)
      leadInventor: "Peter Tompson", // display and change
      keywords: "Team Marin", // display and change (nested! list)
      expectedDealType: "Company", // display and change (list)
      processArea: "TTO Process", // display and change (list)
      team: "Kommunikasjon", // display and change (list)
      owningUnit1: "VIS", // display and change (list) ==> (new User Story for rendering list tree in separate component)
      owningUnit2: "Darcblue",
      fields: "Aquaculture", // display and change (nested list | multi choice, Base is CategoryList)
      statusCost: "green", // display and change (RAGBUtton is there)
      statusProgress: "red", // display and change (RAGBUtton is there)
      statusRisk: "yellow", // display and change (RAGBUtton is there)
      statusBudget: "red",
      nextSteps: "this is nextStep",
      progressDescription: "this is progressDecription",
      history: [
        {
          type: "status",
          timeStamp: "2021-12-01",
          user: [{
            "userId": "055b578d6833b8a6b15f1bd57f4e413a",
            "userRoles": ["anonymous", "authenticated", "pm"],
            "identityProvider": "aad",
            "userDetails": "darcblue@bergento.com"
          }],
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",
        },
        {
          type: "status",
          timestamp: "2021-12-02",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",
        },
        {
          type: "status",
          timestamp: "2021-12-03",
          statusProgress: "green",
          statusCost: "green",
          statusRisk: "yellow",
          progressDescription: "PD tree",
          nextSteps: "NS tree",
        },

        {
          type: "gate",
          user: '{ "userId": "055b578d6833b8a6b15f1bd57f4e413a", "userRoles": ["anonymous", "authenticated", "pm"], "identityProvider": "aad", "userDetails": "darcblue@bergento.com" }',
          timestamp: "2021-10-03",
          gate: 1,
          decisionDate: "2021-10-02",
          checklist: [{ name: "ch1" }, { name: "ch2" }, { name: "ch3" }],
          decision: "go next gate",
          description: "Gate hist One",
        },

        {
          type: "gate",
          timestamp: "2021-10-04",
          gate: 2,
          decisionDate: "2021-10-03",
          checklist: [{ name: "ch1" }, { name: "ch2" }, { name: "ch3" }],
          decision: "go next gate",
          description: "Gate hist two",
        },

        {
          type: "gate",
          timestamp: "2021-10-15",
          gate: 3,
          decisionDate: "2021-10-12",
          checklist: [{ name: "ch1" }, { name: "ch2" }, { name: "ch3" }],
          decision: "go next gate",
          description: "Gate hist tree",
        },

        {
          type: "archive",
          user: [{
            "userId": "055b578d6833b8a6b15f1bd57f4e413a",
            "userRoles": ["anonymous", "authenticated", "pm"],
            "identityProvider": "aad",
            "userDetails": "darcblue@bergento.com"
          }],
          timestamp: "2021-10-15",
          archiveStatus: "Running",
          archiveComment: "Let it go",
        },
        {
          type: "archive",
          timestamp: "2021-12-15",
          archiveStatus: "On-hold",
          archiveComment: "On-hold activated",
        },
        {
          type: "archive",
          timestamp: "2022-1-15",
          archiveStatus: "Running",
          archiveComment: "Let it go again",
        },


      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33445",
      projectName: "Cloud project",
      projectGate: "1",
      projectDescription: tmpDesc,
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1:
        "Universitetet i Bergen:Det medisinske fakultet:Klinisk institutt 1",
      owningUnit2:
        "Universitetet i Bergen:Det medisinske fakultet:Klinisk institutt 1",
      archiveStatus: "Running",
      team: "Kommunikasjon",
      projectTeam: ["eb9c83f4-cc9b-e911-b078-00155d78450d", "9f723339-da03-e911-afb4-00155d788512", "70295695-ae63-e611-80c6-00155dd45711"],
      history: [
        {
          type: "status",
          timestamp: "2021-12-01",
          userName: "darcblue@bergento.com",
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "3",
          decisionDate: "2021-12-1",
          checklist: [
            "Rights have been transferred",
            "Innovation Agreement signed",
            "Exit Agreement signed",
            "Exit strategy concluded",
            "Idea/Project is ready for realisation (license, start-up, other)",
            "Exit strategy presented to the Project board"
          ],
          decision: "Agreed on next step",
          description: "This is test description"
        },
        {
          type: "status",
          timestamp: "2021-12-02",
          userName: "halvor.aas@bergento.com",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "2",
          decisionDate: "2021-11-1",
          checklist: [
            "Idea registered as phase 2 in the VIS project center",
            "Patentability assessment executed if relevant",
            "Preliminary IP strategy ready",
            "Preliminary market analysis ready if relevant"
          ],
          decision: "Agreed on next step two",
          description: "This is test description of two"
        },
      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33646",
      projectName: "DevOps Software As a Service",
      projectGate: "5",
      projectDescription: tmpDesc,
      archiveStatus: "Archived",
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1: "Haukeland Universitetssykehus",
      owningUnit2:
        "Universitetet i Bergen:Det medisinske fakultet:Klinisk institutt 1",
      team: "Kommunikasjon",
      history: [
        {
          type: "status",
          timestamp: "2021-12-01",
          userName: "darcblue@bergento.com",
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "3",
          decisionDate: "2021-12-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step",
          description: "This is test description"
        },
        {
          type: "status",
          timestamp: "2021-12-02",
          userName: "halvor.aas@bergento.com",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "2",
          decisionDate: "2021-11-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step two",
          description: "This is test description of two"
        },
      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33446",
      projectName: "Green IT",
      projectGate: "2",
      projectTeam: ["member12", "member22", "member32"],
      projectDescription: tmpDesc,
      archiveStatus: "Archived",
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1: "Harstad",
      team: "Kommunikasjon",
      history: [
        {
          type: "status",
          timestamp: "2021-12-01",
          userName: "darcblue@bergento.com",
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "3",
          decisionDate: "2021-12-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step",
          description: "This is test description"
        },
        {
          type: "status",
          timestamp: "2021-12-02",
          userName: "halvor.aas@bergento.com",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "2",
          decisionDate: "2021-11-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step two",
          description: "This is test description of two"
        },
      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33447",
      projectName: "VIS Project Center",
      projectGate: "2",
      projectTeam: ["member13", "member23", "member33"],
      projectDescription: tmpDesc,
      archiveStatus: "Archived",
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1: "Kristiansand",
      team: "Kommunikasjon",
      history: [
        {
          type: "status",
          timestamp: "2021-12-01",
          userName: "darcblue@bergento.com",
          statusProgress: "green",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD one",
          nextSteps: "NS one",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "3",
          decisionDate: "2021-12-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step",
          description: "This is test description"
        },
        {
          type: "status",
          timestamp: "2021-12-02",
          userName: "halvor.aas@bergento.com",
          statusProgress: "red",
          statusCost: "red",
          statusRisk: "yellow",
          progressDescription: "PD two",
          nextSteps: "NS two",

          archiveStatus: "Running",
          archiveComment: "Archived successfully",

          gate: "2",
          decisionDate: "2021-11-1",
          checklist: "ch1 OK, ch2 OK, ch3 OK",
          decision: "Agreed on next step two",
          description: "This is test description of two"
        },
      ],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33448",
      projectName: "Data Analytics in the Cloud",
      projectGate: "2",
      projectTeam: ["member14", "member24", "member34"],
      projectDescription: tmpDesc,
      archiveStatus: "Archived",
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1: "Trondheim",
      //owningUnit2: "Helse Bergen:Økonomi- og finansavdelinga:Innkjøpsseksjonen",
      team: "Kommunikasjon",
      history: [],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
    {
      id: "33449",
      projectName: "Customer Segmentation",
      projectGate: "2",
      projectTeam: ["member15", "member25", "member35"],
      projectDescription: tmpDesc,
      archiveStatus: "Archived",
      ownerId: "0817c752-cf61-45c5-8d15-1d5f358db127",
      owningUnit1: "Lillehammer",
      owningUnit2: "VIS",
      team: "Kommunikasjon",
      history: [],
      budget: [
        {
          budgetYear: 2022,
          sourceOfFinancing: "LPM - Lokale prosjektmidler",
          periodFrom: "31.12.2022",
          periodTo: "31.12.2022",
          totalFinancedAmount: 150000,
          asvExpenses: 0,
          consultancyExpenses: 0,
          patentCosts: 0,
          travelAndMeetingExpenses: 0,
          otherExpenses: 0,
          remainingAmountForVISHours: 100000,
          comments: "This is a comment."
        }
      ]
    },
  ];

  const MainContent = () => {
    return (
      <div className="App">
        <AuthenticatedTemplate>
          <AppStateProvider>
            <Container>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={2}>
                  <Image src={visLogo} alt="VIS logo" size="tiny" />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Header as="h1" style={{ marginTop: "5%", marginBottom: "5%" }}>
                    Project Center
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Container>
            <Container>
              <header className="App-header">
                <Grid centered>
                  
                </Grid>
              </header>
            </Container>
            <Container>
              <Router>
                <Routes>
                  {/*<Route exact path="/"><Login/></Route>*/}
                  <Route exact path="/" element={<ProjectCardList list={emptyList} />}/>
                  {/*<Route path="/project/:id" children={<ProjectCardList list={testList} />} />*/}
                  <Route path="/:projectId" element={<RouteSingleProject list={emptyList} />} />
                  <Route path="/export/:format" element={<ExportProject />}/>
                </Routes>
              </Router>
            </Container>
          </AppStateProvider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          {/* <AppStateProvider>
            <Container>
              <header className="App-header">
                <Grid centered>
                  <Header
                    as="h1"
                    style={{ marginTop: "5%", marginBottom: "5%" }}
                    dividing
                  >
                    Test area for dev
                  </Header>
                </Grid>
              </header>
            </Container>
            <Container>
              <ProjectCardList list={testList} />
            </Container>
          </AppStateProvider> */}
        </UnauthenticatedTemplate>
      </div>
    );
  };
  console.log("Test list available " + testList.length)


  return (
    <MsalProvider instance={msalInstance}>
      <div>
        <PageLayout>
          <MainContent />
        </PageLayout>
      </div>
    </MsalProvider>
  );
}

export default App;