import React, { useState } from "react";
import { Button, Form, Divider, Grid, Dropdown, Icon, TextArea } from "semantic-ui-react";
import { useAppState } from '../state/useAppState';
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";

const BudgetLine = (props) => {

    const { state, dispatcher } = useAppState();
    const [inputMissing, setInputMissing] = useState(false);

    const budget = props.line
    const isInEdit = state.budgetEditIndex === props.index

    const modalContent = "The budget line will be permanently deleted. Are you sure that this line should be deleted?"

    const handleChange = (e, { name, value }) => {
        console.log(`Handle change: ${name}=${value} on line ${props.index + 1}`);
        updateFieldBudget(name, value)
    };

    const handleChangeNumber = (e, { name, value }) => {
        const number = value.replace(/\D/g, '')

        console.log(`Handle change: ${name}=${number} on line ${props.index + 1}`);
        updateFieldBudget(name, number)
    };

    const findRemaining = (budgetLine) => {
        const total = budgetLine.totalFinancedAmount || 0
        return total - (budgetLine.asvExpenses || 0) - (budgetLine.consultancyExpenses || 0) - (budgetLine.patentCosts || 0)
            - (budgetLine.travelAndMeetingExpenses || 0) - (budgetLine.otherExpenses || 0)
    }

    const updateFieldBudget = (name, value) => {

        const updatedBudget = state.budgetForEdit.map((budgetLine, i) => {
            if (i === props.index) {
                let updatedLine = { ...budgetLine, [name]: value, "budgetLineStatus": "For approval" }
                updatedLine.remainingAmountForVISHours = findRemaining(updatedLine)
                return updatedLine
            } else {
                return budgetLine
            }
        })
        dispatcher({ type: 'setBudgetForEdit', payload: updatedBudget });
    };

    const updateLineStatus = (value) => {

        const updatedBudget = state.budgetForEdit.map((budgetLine, i) => {
            if (i === props.index) {
                return { ...budgetLine, "budgetLineStatus": value };
            } else {
                return budgetLine
            }
        })
        props.saveBudget(value, updatedBudget)
        dispatcher({ type: 'setBudgetForEdit', payload: updatedBudget });
    };

    const deleteBudgetLine = () => {
        const updatedBudget = state.budgetForEdit.filter((budgetLine, i) => i !== props.index)
        dispatcher({ type: 'setBudgetForEdit', payload: updatedBudget});
        props.saveBudget('Deleted budget line', updatedBudget)
        dispatcher({ type: 'setBudgetEditIndex', payload: null })
    }

    const financingSources = [
        {
            key: 'Owner financed - Eier TK/TFTO',
            text: 'Owner financed - Eier TK/TFTO',
            value: 'Owner financed - Eier TK/TFTO',
        },
        {
            key: 'Owner invoiced - Eier, ikke tjenestekjøp',
            text: 'Owner invoiced - Eier, ikke tjenestekjøp',
            value: 'Owner invoiced - Eier, ikke tjenestekjøp',
        },
        {
            key: 'NFR Qualification - Kvalifisering',
            text: 'NFR Qualification - Kvalifisering',
            value: 'NFR Qualification - Kvalifisering',
        },
        {
            key: 'NFR Proof of concept - Verifisering',
            text: 'NFR Proof of concept - Verifisering',
            value: 'NFR Proof of concept - Verifisering',
        },
        {
            key: 'NFR BIOTEK',
            text: 'NFR BIOTEK',
            value: 'NFR BIOTEK',
        },
        {
            key: 'NFR Other - Annet',
            text: 'NFR Other - Annet',
            value: 'NFR Other - Annet',
        },
        {
            key: 'Other financing - Annen finansiering',
            text: 'Other financing - Annen finansiering',
            value: 'Other financing - Annen finansiering',
        },
        {
            key: 'Consulting - Solgte konsulentimer',
            text: 'Consulting - Solgte konsulentimer',
            value: 'Consulting - Solgte konsulentimer',
        },
        {
            key: 'VIS hours - ikke finansiert',
            text: 'VIS hours - ikke finansiert',
            value: 'VIS hours - ikke finansiert',
        },
    ]

    const yearOptions = (quantity) => {
        const currentYear = new Date().getFullYear()
        const yearList = []

        for (let i = -1; i < quantity; i++) { // Includes the previous year
            const year = '' + (currentYear + i)
            yearList.push({
                key: year,
                text: year,
                value: year,
            })
        }
        return yearList
    }

    const numberFormatCheck = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }
    const formatNumber = (x) => {
        if (!x) return ""

        const formatedNum = x.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ")

        if(x < 0) return "-" + formatedNum
        return formatedNum;
    }

    const redNegNum = (number) => {
        if (number < 0) return {color: "red"}
        return {color: "black"}
    }

    const formIsValid = () => {
        if ( 
            budget.budgetYear === "" || budget.budgetYear === undefined ||
            budget.sourceOfFinancing === "" || budget.sourceOfFinancing === undefined ||
            budget.periodFrom === "" || budget.periodFrom === undefined ||
            budget.periodTo === "" || budget.periodTo === undefined ||
            budget.totalFinancedAmount === "" || budget.totalFinancedAmount === undefined
            ) {
            setInputMissing(true)
            return false
        }
        else 
            setInputMissing(false)
            return true
        
      };

    return (
        <Form>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Budget year*</p>
                    </Grid.Column>
                    <Grid.Column>
                        {!isInEdit &&
                            <p>{budget.budgetYear}</p>
                        }
                        {isInEdit &&
                            <>
                                <Dropdown 
                                name="budgetYear" 
                                placeholder='Choose budget year' 
                                fluid selection options={yearOptions(11)} 
                                style={{ "margin": "0px 0px 3.73px", "width": "150px" }} 
                                onChange={handleChange} 
                                defaultValue={budget.budgetYear} />
                                {inputMissing && (budget.budgetYear === "" || budget.budgetYear === undefined) && <p style={{ color: 'red'}}>This field is mandatory.</p>}
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Source of financing*</p>
                    </Grid.Column>
                    <Grid.Column>
                        {!isInEdit &&
                            <p>{budget.sourceOfFinancing}</p>
                        }
                        {isInEdit &&
                            <>
                                <Dropdown 
                                name="sourceOfFinancing" 
                                placeholder='Choose source of financing' 
                                fluid selection options={financingSources} 
                                style={{ "margin": "0px 0px 3.73px", "width": "400px" }} 
                                onChange={handleChange} 
                                defaultValue={budget.sourceOfFinancing} />
                                {inputMissing && (budget.sourceOfFinancing === "" || budget.sourceOfFinancing === undefined) && <p style={{ color: 'red'}}>This field is mandatory.</p>}
                            </>
                            
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Periode from*</p>
                        <p>Periode to*</p><br />
                    </Grid.Column>
                    <Grid.Column withd={8}>
                        {!isInEdit &&
                            <p>{budget.periodFrom}</p>
                        }
                        {isInEdit &&
                            <>
                                <Form.Input 
                                name="periodFrom" 
                                type="date" 
                                size="mini" 
                                style={{ "margin": "0px 0px 3.73px", "width": "200px" }} 
                                onChange={handleChange} 
                                defaultValue={budget.periodFrom} />
                                {inputMissing && (budget.periodFrom === "" || budget.periodFrom === undefined) && <p style={{ color: 'red'}}>This field is mandatory.</p>}
                            </>
                        }

                        {!isInEdit &&
                            <p>{budget.periodTo}</p>
                        }
                        {isInEdit &&
                            <>
                                <Form.Input 
                                name="periodTo" 
                                type="date" 
                                size="mini" 
                                style={{ "margin": "0px 0px 3.73px", "width": "200px" }} 
                                onChange={handleChange} 
                                defaultValue={budget.periodTo} />
                                {inputMissing && (budget.periodTo === "" || budget.periodTo === undefined) && <p style={{ color: 'red'}}>This field is mandatory.</p>}
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Total financed amount*</p>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {!isInEdit &&
                            <p style={redNegNum(budget.totalFinancedAmount)}>{formatNumber(budget.totalFinancedAmount)}</p>
                        }
                        {isInEdit &&
                            <>
                                <Form.Input
                                name="totalFinancedAmount"
                                size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                                onKeyPress={numberFormatCheck}
                                onChange={handleChangeNumber}
                                defaultValue={budget.totalFinancedAmount} 
                                />
                                {inputMissing && (budget.totalFinancedAmount === "" || budget.totalFinancedAmount === undefined) && <p style={{ color: 'red'}}>This field is mandatory.</p>}
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>ASV expenses</p>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {!isInEdit &&
                            <p style={redNegNum(budget.asvExpenses)}>{formatNumber(budget.asvExpenses)}</p>
                        }
                        {isInEdit &&
                            <Form.Input
                            name="asvExpenses"
                            size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                            onKeyPress={numberFormatCheck}
                            onChange={handleChangeNumber}
                            defaultValue={budget.asvExpenses} />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Consultancy expenses</p>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {!isInEdit &&
                            <p style={redNegNum(budget.consultancyExpenses)}>{formatNumber(budget.consultancyExpenses)}</p>
                        }
                        {isInEdit &&
                            <Form.Input
                            name="consultancyExpenses"
                            size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                            onKeyPress={numberFormatCheck}
                            onChange={handleChangeNumber}
                            defaultValue={budget.consultancyExpenses} />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Patent costs</p>
                    </Grid.Column>
                    <Grid.Column width={7}>

                        {!isInEdit &&
                            <p style={redNegNum(budget.patentCosts)}>{formatNumber(budget.patentCosts)}</p>
                        }
                        {isInEdit &&
                            <Form.Input
                            name="patentCosts"
                            size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                            onKeyPress={numberFormatCheck}
                            onChange={handleChangeNumber}
                            defaultValue={budget.patentCosts} />
                        }

                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Traveling and meeting expenses</p>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {!isInEdit &&
                            <p style={redNegNum(budget.travelAndMeetingExpenses)}>{formatNumber(budget.travelAndMeetingExpenses)}</p>
                        }
                        {isInEdit &&
                            <Form.Input
                            name="travelAndMeetingExpenses"
                            size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                            onKeyPress={numberFormatCheck}
                            onChange={handleChangeNumber}
                            defaultValue={budget.travelAndMeetingExpenses} />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Other expenses</p><br />
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {!isInEdit &&
                            <p style={redNegNum(budget.otherExpenses)}>{formatNumber(budget.otherExpenses)}</p>
                        }
                        {isInEdit &&
                                <Form.Input
                                name="otherExpenses"
                                size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }}
                                onKeyPress={numberFormatCheck}
                                onChange={handleChangeNumber}
                                defaultValue={budget.otherExpenses} />
                        }
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Remaining amount for VIS hours</p><br />
                    </Grid.Column>
                    <Grid.Column>
                        {/* <Input name="remainingAmountForVISHours" size="mini" style={{ "margin": "0px 0px 3.73px", "width": "200px" }} onChange={handleChange} defaultValue={budget.remainingAmountForVISHours} /> */}
                        <p style={redNegNum(budget.remainingAmountForVISHours)}>{formatNumber(budget.remainingAmountForVISHours)}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <p>Comments</p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {!isInEdit &&
                            <p>{budget.comments}</p>
                        }
                        {isInEdit &&
                            <Form.Field
                            control={TextArea}
                            name="comments"
                            size="mini"
                            style={{ "margin": "0px 0px 3.73px", "width": "400px" }}
                            onChange={handleChange}
                            defaultValue={budget.comments} />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        {!isInEdit && !state.budgetEditIndex &&
                            <Button onClick={() => { dispatcher({ type: 'setBudgetEditIndex', payload: props.index }) }}>
                                <Icon name="lock" /> Edit
                            </Button>
                        }
                        {isInEdit &&
                            <>
                                <Button
                                    onClick={() => {
                                        if (formIsValid()) {
                                            props.saveBudget('For approval')
                                            dispatcher({ type: 'setBudgetEditIndex', payload: null })
                                        }
                                    }}>
                                    <Icon name="unlock" /> Save
                                </Button>
                                <Button onClick={() => { dispatcher({ type: 'setBudgetEditIndex', payload: null }) }}>
                                    <Icon name="x" /> Cancel
                                </Button>
                            </>
                        }
                    </Grid.Column>
                    <Grid.Column width={4}>
                        {!isInEdit && budget.budgetLineStatus &&
                            "Status: " + budget.budgetLineStatus
                        }
                    </Grid.Column>
                </Grid.Row>
                {!isInEdit && props.canApprove && budget.budgetLineStatus === 'For approval' &&
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Button onClick={() => updateLineStatus("Approved")}>
                                <Icon name="check" /> Approve
                            </Button>
                            <Button onClick={() => updateLineStatus("Not approved")}>
                                <Icon name="x" /> Decline
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                }
                {!isInEdit && props.canApproveERP && budget.budgetLineStatus === 'Approved' &&
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Button onClick={() => updateLineStatus("Approved and updated in ERP")}>
                                <Icon name="check" /> Set updated in ERP
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                }
                {!isInEdit && (budget.budgetLineStatus === 'Not approved' || budget.budgetLineStatus === 'For approval') &&
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <ConfirmDeleteModal 
                                deleteFunction={deleteBudgetLine} 
                                modalContent={modalContent} 
                                modalHeader="Delete Budget Line"/>
                        </Grid.Column>
                    </Grid.Row>
                } 
            </Grid>
            <br />
            <Divider />
            <br />
        </Form>
    )
}

export default BudgetLine