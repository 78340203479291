import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Dropdown,
  Form,
  Select,
} from "semantic-ui-react";
import {
  saveProject,
  addFieldToProject,
} from "../../functions/project-card-functions";
import { projectManagerDropDownOptions } from "../../functions/project-manager-functions";
import { lookUpProjectManagerName } from "../../functions/project-manager-functions";

let valuesForProjectManager = [];
const TeamMemberAndProjectManagerModalBox = (props) => {
  const userInfo = props.userInfo;
  const [historyObject, setHistoryObject] = useState({
    type: "changes",
    userId: "",
    userName: "",
    timestamp: "",
    change: "",
  });
  const handleChange = (e, { name, value }) => {
    if (value !== props.project.ownerId) {
      if (name === "ownerId") {
        valuesForProjectManager.push(props.project.ownerId);
        valuesForProjectManager.push(value);
        setHistoryObject({
          type: "changes",
          userId: userInfo.userId,
          userName: userInfo.userDetails,
          timestamp: new Date().toISOString(),
          change:
            "Project Manager changed from " +
            lookUpProjectManagerName(
              valuesForProjectManager[0],
              props.projectManagers
            ) +
            " to " +
            lookUpProjectManagerName(
              valuesForProjectManager[valuesForProjectManager.length - 1],
              props.projectManagers
            ),
        });
      }
    }
    props.dispatch({
      type: "setProjectForEdit",
      payload: addFieldToProject(props.project, name, value),
    });
  };
  const handleSubmitButton = async () => {
    if (
      valuesForProjectManager[0] !==
      valuesForProjectManager[valuesForProjectManager.length - 1]
    ) {
      props.project.history.push(historyObject);
    }
    saveProject(props.project);
    props.dispatch({ type: "updateProject", payload: props.project });
    setModalClosed();
    valuesForProjectManager = [];
  };
  const setModalClosed = () => {
    props.dispatch({ type: "setModal", payload: "" });
  };
  const handleCancelButton = () => {
    setModalClosed();
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={props.isOpen}
      onClose={setModalClosed}
    >
      <Header icon="cogs" content="Edit Team Members And Project Manager" />
      <Modal.Header>
        {props.project.id} - {props.project.projectName}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Project Manager"
            name="ownerId"
            value={props.project.ownerId}
            control={Select}
            options={projectManagerDropDownOptions(props.projectManagers)}
            onChange={handleChange}
          />

          <strong>Add team member</strong>
          <Dropdown
            search
            fluid
            multiple
            selection
            placeholder="Add a team member"
            name="projectTeam"
            value={props.project.projectTeam}
            options={projectManagerDropDownOptions(props.projectManagers)}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleSubmitButton()}>
          <Icon name="check" />
          Confirm
        </Button>
        <Button onClick={() => handleCancelButton()}>
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TeamMemberAndProjectManagerModalBox;
