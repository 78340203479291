import React from "react";
import { Step } from "semantic-ui-react";

const StepModal = (props) => {
  const initalGate = props.gate;
  let s1Active = false;
  let s1Completed = false;
  let s2Active = false;
  let s2Completed = false;
  let s3Active = false;
  let s3Completed = false;
  let s4Active = false;
  let s4Completed = false;
  let s5Active = false;
  let s5Completed = false;

  if (initalGate === "1") {
    s1Active = true;
  }
  if (initalGate === "2") {
    s2Active = true;
    s1Active = false;
    s1Completed = true;
  }
  if (initalGate === "3") {
    s3Active = true;
    s2Active = false;
    s2Completed = true;
    s1Active = false;
    s1Completed = true;
  }
  if (initalGate === "4") {
    s4Active = true;
    s3Completed = true;
    s3Active = false;
    s2Active = false;
    s2Completed = true;
    s1Active = false;
    s1Completed = true;
  }
  if (initalGate === "5") {
    s5Active = true;
    s4Completed = true;
    s4Active = false;
    s3Completed = true;
    s3Active = false;
    s2Active = false;
    s2Completed = true;
    s1Active = false;
    s1Completed = true;
  }

  return (
    <Step.Group ordered widths={5}>
      <Step active={s1Active} completed={s1Completed}>
        <Step.Content>
          <Step.Title>Idea Assessment</Step.Title>
        </Step.Content>
      </Step>
      <Step active={s2Active} completed={s2Completed}>
        <Step.Content>
          <Step.Title>Idea Evaluation</Step.Title>
        </Step.Content>
      </Step>

      <Step active={s3Active} completed={s3Completed}>
        <Step.Content>
          <Step.Title>Idea Development</Step.Title>
        </Step.Content>
      </Step>
      <Step active={s4Active} completed={s4Completed}>
        <Step.Content>
          <Step.Title>EXIT Phase</Step.Title>
        </Step.Content>
      </Step>
      <Step active={s5Active} completed={s5Completed}>
        <Step.Content>
          <Step.Title>Maintenance</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};

export default StepModal;
