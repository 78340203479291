import React, { useState } from "react";
import { Card, Grid, Input, Button } from "semantic-ui-react";
import OwningUnit1Filter from "./OwningUnit1Filter";
import OwningUnit2Filter from "./OwningUnit2Filter";
import OwnerFilter from "./OwnerFilter";
import FullyOwnedFilter from "./FullyOwnedFilter";

import ProjectManagerFilter from "./ProjectManagerFilter";
import KeywordFilter from "./KeywordFilter";
import ProjectTypeFilter from "./ProjectTypeFilter";
import RunningFilter from "./RunningFilter";
import StatusFilter from "./StatusFilter";
import TtoStageFilter from "./TtoStageFilter";

const FilterPanel = (props) => {
  const idFilter = props.filterState.projectIdFilter || "";
  const textFilter = props.filterState.textFilter || "";
  const [reset, setReset] = useState(false);

  const resetFilter = () => {
    props.filterDispatch({ type: "resetAllFilters" });
  };

  const changeIdFilter = (_currentNode, change) => {
    props.filterDispatch({ type: "projectIdFilter", payload: change.value });
  };

  const changeTextFilter = (_currentNode, change) => {
    props.filterDispatch({ type: "textFilter", payload: change.value });
  };

  const divStyleTTOFilter = {
    padding: "0px 10px 5px 0px",
    minWidth: "15%",
    float: "left",
  };

  return (
    <>
      <Card style={{ minWidth: "100%" }}>
        <Card.Content style={{ minWidth: "100%", background: "AliceBlue" }}>
          <Grid doubling columns={2}>
            <Grid.Column>
              <Grid doubling columns={1}>
                <Grid.Column>
                  <Input
                    size="mini"
                    icon="search"
                    value={textFilter}
                    onChange={changeTextFilter}
                    placeholder="Search text..."
                  />
                  <span style={{ padding: "1px 1px 1px 1px" }}> </span>
                  <Input
                    size="mini"
                    icon="search"
                    value={idFilter}
                    onChange={changeIdFilter}
                    placeholder="Search project id..."
                  />
                  <span style={{ padding: "1px 1px 1px 1px" }}> </span>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    size="mini"
                    onClick={() => {
                      resetFilter();
                    }}
                  >
                    Reset Filters
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <RunningFilter
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
              <StatusFilter
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
        <Card.Content extra>
          <Grid doubling columns={4}>
            <Grid.Column>
              <span style={{ padding: "1px 1px 1px 1px" }}> </span>
              <ProjectTypeFilter
                name="Project Type"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
            <Grid.Column>
              <span style={{ padding: "1px 1px 1px 1px" }}> </span>
              <div style={divStyleTTOFilter}>
                <TtoStageFilter
                  name="TTO Stage"
                  value=""
                  resetFilters={reset}
                  updateReset={setReset}
                  filterState={props.filterState}
                  filterDispatch={props.filterDispatch}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <span style={{ padding: "1px 1px 1px 1px" }}> </span>
              <ProjectManagerFilter
                projectManagers={props.projectManagers}
                name="Project Manager"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
            <Grid.Column>
              <span style={{ padding: "1px 1px 1px 1px" }}> </span>
              <KeywordFilter
                projectKeywordList={props.projectKeywordList}
                name="Keyword"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
            <Grid.Column>
              <div style={divStyleTTOFilter}>
                <OwnerFilter
                  name="Owner"
                  value=""
                  resetFilters={reset}
                  updateReset={setReset}
                  filterState={props.filterState}
                  filterDispatch={props.filterDispatch}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <OwningUnit1Filter
                name="Owning Unit 1"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
            <Grid.Column>
              <OwningUnit2Filter
                name="Owning Unit 2"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
            <Grid.Column>
              <FullyOwnedFilter
                name="Fully Owned"
                value=""
                resetFilters={reset}
                updateReset={setReset}
                filterState={props.filterState}
                filterDispatch={props.filterDispatch}
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </>
  );
};

export default FilterPanel;
