// module "project-card-functions.js"
import { statusApplications } from "../applications/application-functions";
import { statusBudget } from "../budget/budget-functions";

const saveProject = async (project) => {
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(project),
    };
    console.log("Save project ID: ", project.id);
    const response = await fetch(
        `/api/ProjectData?function=updateProject&projectId=${project.id}`,
        requestOptions
    );
    await response.json();
    return project;
};

const addFieldToProject = (project, name, value) => {
    return { ...project, [name]: value };
};

const projectTypeOptions = [
    { key: "TTO", value: "TTO", text: "TTO" },
    {
        key: "Administrative",
        value: "Administrative",
        text: "Administrative",
    },
    {
        key: "Innovation",
        value: "Innovation",
        text: "Innovation",
    },
    {
        key: "Company",
        value: "Company",
        text: "Company",
    },
    {
        key: "Consulting",
        value: "Consulting",
        text: "Consulting",
    },
    {
        key: "Supporting",
        value: "Supporting",
        text: "Supporting",
    },
];

const keywordListFileData = require("../data/KeywordList.json");

const createKeywordItem = (option) => {
    const { value: listValue, subList, parentValue = null } = option;

    const newValue = parentValue ? parentValue + ":" + listValue : listValue;
    if (subList) {
        const newChildren = subList.map((element) => {
            element.parentValue = newValue;
            return createKeywordItem(element);
        });
        return [
            { key: listValue, value: newValue, text: newValue },
            ...newChildren,
        ];
    }
    return { key: listValue, value: newValue, text: newValue };
};

const keywordOptions = keywordListFileData.flatMap((option) => {
    return createKeywordItem(option);
});

const checkRunningFilter = (project, runningFilter) => {
    if (!project.archiveStatus) return true;
    if (project.archiveStatus === "Running" && runningFilter.running)
        return true;
    if (project.archiveStatus === "Archived" && runningFilter.archived)
        return true;
    if (project.archiveStatus === "On-hold" && runningFilter.onHold)
        return true;

    return false;
};

const checkStatusProgressFilter = (project, statusProgressFilter) => {
    return (
        !statusProgressFilter || project.statusProgress === statusProgressFilter
    );
};
const checkStatusCostFilter = (project, statusCostFilter) => {
    return !statusCostFilter || project.statusCost === statusCostFilter;
};

const checkStatusRiskFilter = (project, statusRiskFilter) => {
    return !statusRiskFilter || project.statusRisk === statusRiskFilter;
};
const checkStatusBudgetFilter = (project, statusBudgetFilter) => {
    const sBudget = statusBudget(project.budget);
    return !statusBudgetFilter || sBudget === statusBudgetFilter;
};

const checkStatusApplicationFilter = (project, statusApplicationFilter) => {
    const sApplications = statusApplications(project.applications);
    return (
        !statusApplicationFilter || sApplications === statusApplicationFilter
    );
};

const checkTtoStageFilter = (project, stageGateFilter) => {
    if (project.projectType === "TTO") {
        const noOfFilter = stageGateFilter.length;
        if (noOfFilter === 0) {
            return true;
        }
        if (!project.projectGate) {
            return false;
        }
        let found = false;
        for (let i = 0; i < noOfFilter; i++) {
            if (project.projectGate.includes(stageGateFilter[i])) {
                found = true;
            }
        }
        return noOfFilter === 0 || found;
    }
};

const checkTeamFilter = (project, teamFilter) => {
    const noOfFilter = teamFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.team) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.team.includes(teamFilter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};
const checkProjectTypeFilter = (project, projectTypeFilter) => {
    const noOfFilter = projectTypeFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.projectType) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.projectType.includes(projectTypeFilter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkOwningUnit1Filter = (project, owningUnit1Filter) => {
    const noOfFilter = owningUnit1Filter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.owningUnit1) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.owningUnit1.includes(owningUnit1Filter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkOwningUnit2Filter = (project, owningUnit2Filter) => {
    const noOfFilter = owningUnit2Filter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.owningUnit2) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.owningUnit2.includes(owningUnit2Filter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkOwnerFilter = (project, ownerFilter) => {
    const noOfFilter = ownerFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.owningUnit1 && !project.owningUnit2) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (
            project.owningUnit1 &&
            project.owningUnit1.includes(ownerFilter[i])
        ) {
            found = true;
        } else if (
            project.owningUnit2 &&
            project.owningUnit2.includes(ownerFilter[i])
        ) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkFullyOwnedFilter = (project, ownerFilter) => {
    const noOfFilter = ownerFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.owningUnit1 && !project.owningUnit2) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (
            project.owningUnit1 &&
            project.owningUnit1.includes(ownerFilter[i]) &&
            project.owningUnit2 &&
            project.owningUnit2.includes(ownerFilter[i])
        ) {
            found = true;
        } else if (
            project.owningUnit1 &&
            project.owningUnit1.includes(ownerFilter[i]) &&
            (!project.owningUnit2 ||
                project.owningUnit2 === undefined ||
                project.owningUnit2 === "" ||
                project.owningUnit2 === "Not applicable")
        ) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkKeywordFilter = (project, keywordFilter) => {
    const noOfFilter = keywordFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.projectKeywords) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.projectKeywords.includes(keywordFilter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkProjectManagerFilter = (project, projectManagerFilter) => {
    const noOfFilter = projectManagerFilter.length;
    if (noOfFilter === 0) {
        return true;
    }
    if (!project.ownerId) {
        return false;
    }
    let found = false;
    for (let i = 0; i < noOfFilter; i++) {
        if (project.ownerId.includes(projectManagerFilter[i])) {
            found = true;
        }
    }
    return noOfFilter === 0 || found;
};

const checkFilters = (project, filter) => {
    if (project.projectGate === undefined) {
        project.projectGate = "";
    }
    const rOK =
        !filter.runningFilter ||
        checkRunningFilter(project, filter.runningFilter);
    const spOK =
        !filter.statusProgressFilter ||
        checkStatusProgressFilter(project, filter.statusProgressFilter);
    const scOK =
        !filter.statusCostFilter ||
        checkStatusCostFilter(project, filter.statusCostFilter);
    const stOK =
        !filter.statusRiskFilter ||
        checkStatusRiskFilter(project, filter.statusRiskFilter);
    const sbOK =
        !filter.statusBudgetFilter ||
        checkStatusBudgetFilter(project, filter.statusBudgetFilter);
    const saOK =
        !filter.statusApplicationFilter ||
        checkStatusApplicationFilter(project, filter.statusApplicationFilter);
    const idOK =
        !filter.projectIdFilter ||
        project.id.startsWith(filter.projectIdFilter);
    const sgOK =
        !filter.stageGateFilter ||
        checkTtoStageFilter(project, filter.stageGateFilter);
    const tOK =
        !filter.textFilter ||
        JSON.stringify(project)
            .toUpperCase()
            .includes(filter.textFilter.toUpperCase());
    const tiOK =
        !filter.teamFilter || checkTeamFilter(project, filter.teamFilter);
    const tyOK =
        !filter.typeFilter ||
        checkProjectTypeFilter(project, filter.typeFilter);
    const pOK =
        !filter.projectManagerFilter ||
        checkProjectManagerFilter(project, filter.projectManagerFilter);
    const kOK =
        !filter.owningUnit1Filter ||
        checkOwningUnit1Filter(project, filter.owningUnit1Filter);
    const o2OK =
        !filter.owningUnit2Filter ||
        checkOwningUnit2Filter(project, filter.owningUnit2Filter);
    const o12OK =
        !filter.ownerFilter || checkOwnerFilter(project, filter.ownerFilter);
    const foOK =
        !filter.fullyOwnedFilter ||
        checkFullyOwnedFilter(project, filter.fullyOwnedFilter);

    const oOK =
        !filter.keywordFilter ||
        checkKeywordFilter(project, filter.keywordFilter);
    return (
        idOK &&
        tOK &&
        rOK &&
        tiOK &&
        tyOK &&
        oOK &&
        kOK &&
        pOK &&
        spOK &&
        scOK &&
        stOK &&
        sgOK &&
        o2OK &&
        sbOK &&
        saOK &&
        o12OK &&
        foOK
    );
};

const projectListReducer = (state, action) => {
    let theFilter = state.filter;
    let theData = state.listDataLoaded;
    switch (action.type) {
        case "emptyListFromServer":
            return {
                ...state,
                loading: false,
                userMessage: action.payload,
            };
        case "newListFromServer":
            theData = action.payload;
            return {
                ...state,
                listDataLoaded: theData,
                loading: false,
                currentViewList: theData.filter((p) =>
                    checkFilters(p, theFilter)
                ),
            };

        case "listOfValues-projectKeywords":
            const newList = action.payload.flatMap((option) => {
                return createKeywordItem(option);
            });

            return {
                ...state,
                projectKeywordList: newList,
            };

        case "setProjectManagerList":
            const pmList = action.payload;

            return {
                ...state,
                projectManagers: pmList,
            };

        case "setProjectForEdit":
            return {
                ...state,
                projectForEdit: action.payload,
            };
        case "setModal":
            return {
                ...state,
                modal: action.payload,
            };
        case "newProject":
            theData.push(action.payload);
            return {
                ...state,
                listDataLoaded: theData,
                reload: state.reload + 1,
                loading: true,
            };
        case "updateProject":
            const projectToUpdate = action.payload;
            theData = theData.map((p) =>
                p.id === projectToUpdate.id ? projectToUpdate : p
            );
            return {
                ...state,
                listDataLoaded: theData,
                reload: state.reload + 1,
                loading: true,
            };
        case "doReload":
            return {
                ...state,
                loading: false,
                currentViewList: theData.filter((p) =>
                    checkFilters(p, theFilter)
                ),
            };
        case "textFilter":
            theFilter = { ...theFilter, textFilter: action.payload };
            return {
                ...state,
                reload: state.reload + 1,
                loading: true,
                filter: theFilter,
            };
        case "projectIdFilter":
            theFilter = { ...theFilter, projectIdFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "stageGateFilter":
            theFilter = { ...theFilter, stageGateFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "runningFilter":
            theFilter = { ...theFilter, runningFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "statusProgressFilter":
            theFilter = { ...theFilter, statusProgressFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "statusCostFilter":
            theFilter = { ...theFilter, statusCostFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "statusRiskFilter":
            theFilter = { ...theFilter, statusRiskFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        case "statusBudgetFilter":
            theFilter = { ...theFilter, statusBudgetFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "statusApplicationFilter":
            theFilter = {
                ...theFilter,
                statusApplicationFilter: action.payload,
            };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "teamFilter":
            theFilter = { ...theFilter, teamFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        case "typeFilter":
            theFilter = { ...theFilter, typeFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        case "keywordFilter":
            theFilter = { ...theFilter, keywordFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        case "projectManagerFilter":
            theFilter = { ...theFilter, projectManagerFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        case "owningUnit1Filter":
            theFilter = { ...theFilter, owningUnit1Filter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "owningUnit2Filter":
            theFilter = { ...theFilter, owningUnit2Filter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "ownerFilter":
            theFilter = { ...theFilter, ownerFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "fullyOwnedFilter":
            theFilter = { ...theFilter, fullyOwnedFilter: action.payload };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };

        case "resetAllFilters":
            theFilter = {
                runningFilter: {
                    running: true,
                    onHold: false,
                    archived: false,
                },
            };
            return {
                ...state,
                filter: theFilter,
                reload: state.reload + 1,
                loading: true,
            };
        default:
            return state;
    }
};

function openAbsoluteUrl(url) {
    if (url) {
        // Check if the URL starts with http://, https://, ftp://, or www.
        if (
            !/^https?:\/\//i.test(url) &&
            !/^ftp:\/\//i.test(url) &&
            !/^www\./i.test(url)
        ) {
            // If not, prepend https://www. to the URL
            url = "https://www." + url;
        } else if (/^www\./i.test(url)) {
            // If it starts with www., prepend only the protocol
            url = "https://" + url;
        }

        // Open the URL
        console.log(url);
        window.open(url);
    }
}

export {
    saveProject,
    addFieldToProject,
    projectListReducer,
    projectTypeOptions,
    keywordOptions,
    openAbsoluteUrl,
};
