import React, { useState } from "react";
import { Grid, Checkbox, List } from "semantic-ui-react";

const StageGateChecklist = ({ updateSelectedCriteria, gate }) => {

  let checkList = [];
  if (gate === "1") {
    checkList = require("../data/MigrationToNewTTOProcess/CheckListTTOGate1.json");
  } else if (gate === "2") {
    checkList = require("../data/MigrationToNewTTOProcess/CheckListTTOGate2.json");
  } else if (gate === "3") {
    checkList = require("../data/MigrationToNewTTOProcess/CheckListTTOGate3.json");
  } else if (gate === "4") {
    checkList = require("../data/MigrationToNewTTOProcess/CheckListTTOGate4.json");
  }

  const [checkedState, setCheckedState] = useState(
    new Array(checkList.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    //update parent component
    updateSelectedCriteria(updatedCheckedState);
  };

  return (
    <Grid>
      <ul>
        {checkList.map(({ name }, index) => {
          return (
            <div key={`criteria-no-${index}`}>
              <List key={`criteria-no-${index}`}>
                <Checkbox
                  label={name}
                  id={index}
                  value={name}
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index)}
                />
              </List>
              <p></p>
            </div>
          );
        })}
      </ul>
    </Grid>
  );
};

export default StageGateChecklist;
