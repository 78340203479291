import React, { createContext, useReducer } from "react";
import { appReducer } from "./appReducer";
import { initialState } from "./initialAppState";

const AppStateContext = createContext(null);

const AppStateProvider = ({ children, defaultState }) => {
    const [appState, appDispatcher] = useReducer(appReducer, defaultState || initialState)

    const stateContext = { dispatcher: appDispatcher, state: appState }
    return (
        <AppStateContext.Provider value={stateContext}>
            {children}
        </AppStateContext.Provider>
    )
}

export { AppStateContext, AppStateProvider }