import React, { useState } from "react";
import { Button, Header, Icon, Modal, Step } from "semantic-ui-react";
import ProjectType from "./ProjectType";
import NewProject from "./NewProject";
import Confirmation from "./Confirmation";
import ProjectMember from "./ProjectMember";

const NewProjectModal = (props) => {
  const [step, setStep] = useState(1);

  const [isValid, setIsValid] = useState(false);
  const [isValidProjectType, setIsValidProjectType] = useState(false);
  const [isValidSharedDetail, setValidSharedDetail] = useState(false);

  const [step1Active, setStep1Active] = useState(false);
  const [step1Completed, setStep1Comleted] = useState(false);
  const [step2Active, setStep2Active] = useState(false);
  const [step2Completed, setStep2Comleted] = useState(false);
  const [step3Active, setStep3Active] = useState(false);
  const [step3Completed, setStep3Completed] = useState(false);
  const [step4Active, setStep4Active] = useState(false);
  const [step4Completed, setStep4Comleted] = useState(false);

  const [message, setMessage] = useState("");

  const [project, setProject] = useState({
    id: "",
    history: [],
    projectName: "",
    ownerId: "",
    processArea: "",
    startDate: "",
    endDate: "",
    countAsIdea: "",
    owningUnit1: "",
    subOwningUnit: "",
    owningUnit2: "",
    ownerPercentage1: "",
    ownerPercentage2: "",
    leadInventor: "",
    nonConfidentialInformation: "",
    team: "",
    projectTeam: [],
    client: "",
    contactPerson: "",
    fields: "",
    keywords: "",
    projectKeywords: [],
    financedBy1: "",
    financedBy2: "",
    expectedDealType: "",
    relatedGatewayProjects: "",
    sustainableDevelopment: "",
    projectType: "",
  });

  const emptyProject = {
    id: "",
    history: [],
    projectName: "",
    ownerId: "",
    processArea: "",
    startDate: "",
    endDate: "",
    countAsIdea: "",
    owningUnit1: "",
    subOwningUnit: "",
    owningUnit2: "",
    ownerPercentage1: "",
    ownerPercentage2: "",
    leadInventor: "",
    nonConfidentialInformation: "",
    team: "",
    projectTeam: [],
    client: "",
    contactPerson: "",
    fields: "",
    keywords: "",
    projectKeywords: [],
    financedBy1: "",
    financedBy2: "",
    expectedDealType: "",
    relatedGatewayProjects: "",
    sustainableDevelopment: "",
    projectType: "",
  };

  const closeModal = () => {
    props.dispatch({ type: "setModal", payload: "" });

    setProject(emptyProject);

    setMessage("");

    setIsValid(false);
    setIsValidProjectType(false);
    setValidSharedDetail(false);

    setStep(1);

    setStep1Comleted(false);
    setStep2Comleted(false);
    setStep3Completed(false);
    setStep4Comleted(false);

    setStep1Active(false);
    setStep2Active(false);
    setStep3Active(false);
    setStep4Active(false);
  };

  const nextStep = () => {
    console.log("NPW isValidProjectType", isValidProjectType);
    if (step < 4) {
      if (step === 1) {
        setStep(step + 1);
      }
      if (step === 2) {
        setStep2Active(true);
        setStep2Comleted(true);
        setStep(3);
      }
      if (step === 3) {
        setStep3Active(true);
        setStep3Completed(true);
        setIsValid(true);
        setStep(4);
      }
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (step === 4) {
        setStep3Active(false);
        setStep3Completed(false);
        setStep(step - 1);
      }
      if (step === 3) {
        setStep2Active(false);
        setStep2Comleted(false);
        setStep(step - 1);
        setIsValidProjectType(false);
      }

      if (step === 2) {
        setStep1Active(false);
        setStep1Comleted(false);
        setStep(step - 1);
      }
    }
  };

  const handleProjectType = (type) => {
    setProject({ ...project, projectType: type });
  };

  const validateType = () => {
    if (
      project.projectType === "" ||
      project.projectType === undefined ||
      project.projectType === null
    ) {
      console.log("project type", project.projectType);
      setIsValidProjectType(false);
      setMessage("Please select a project type first!");
    } else {
      setStep1Active(true);
      setStep1Comleted(true);
      setIsValidProjectType(true);
      nextStep();
      setMessage();
    }
  };

  const validateSharedDetail = () => {
    if (project.endDate !== "") {
      if (
        project.endDate > project.startDate ||
        project.endDate === project.startDate
      ) {
        if (
          project.projectName === "" ||
          project.startDate === "" ||
          project.nonConfidentialInformation === ""
        ) {
          setValidSharedDetail(false);
          setMessage("Fill up the values!");
        } else {
          setMessage("");
          setValidSharedDetail(true);
          nextStep();
        }
      } else {
        console.log("CHECK END DATE!");
        console.log("END DATE", project.endDate);
      }
    } else {
      if (
        project.projectName === "" ||
        project.startDate === "" ||
        project.nonConfidentialInformation === ""
      ) {
        setValidSharedDetail(false);
        setMessage("Fill up the values!");
      } else {
        setMessage("");
        setValidSharedDetail(true);
        nextStep();
      }
    }
  };

  const validateForAdministrativeProjects = () => {
    if (
      project.projectType === "Administrative" &&
      isValidSharedDetail === true
    ) {
      console.log("SharedDetailValid", isValidSharedDetail);
      console.log("Project Type", project.projectType);
      if (project.owningUnit1 === "" || !project.projectKeywords) {
        setMessage("Fill up the values!");
      } else {
        setIsValid(true);
        nextStep();
      }
    }
  };

  const validateForTTOProject = () => {
    if (project.projectType === "TTO" && isValidSharedDetail) {
      console.log("Project Type", project.projectType);
      project.projectGate = 1;
      if (
        project.owningUnit1 === "" ||
        !project.projectKeywords ||
        project.leadInventor === ""
      ) {
        setMessage("Fill up the values!");
      } else {
        setIsValid(true);
        nextStep();
      }
    }
  };

  const validateForCompanyProject = () => {
    if (project.projectType === "Company" && isValidSharedDetail) {
      console.log("Project Type", project.projectType);
      if (!project.projectKeywords) {
        setMessage("Fill up the values!");
      } else {
        setIsValid(true);
        nextStep();
      }
    }
  };
  const validateForInnovationProjects = () => {
    if (project.projectType === "Innovation" && isValidSharedDetail) {
      console.log("Project Type", project.projectType);
      if (project.owningUnit1 === "" || !project.projectKeywords) {
        setMessage("Fill up the values!");
      } else {
        setIsValid(true);
        nextStep();
      }
    }
  };

  const validationForSupportingActivitiesProjects = () => {
    if (project.projectType === "Supporting" && isValidSharedDetail) {
      console.log("Project Type", project.projectType);
      if (project.owningUnit1 === "") {
        setMessage("Fill up the values!");
      } else {
        setIsValid(true);
        nextStep();
      }
    }
  };

  const validationForConsultingProjects = () => {
    if (project.projectType === "Consulting" && isValidSharedDetail) {
      console.log("Project Type", project.projectType);

      setIsValid(true);
      nextStep();
    }
  };

  const validateDetail = () => {
    validateSharedDetail();
    validateForAdministrativeProjects();
    validateForTTOProject();
    validateForCompanyProject();
    validateForInnovationProjects();
    validationForSupportingActivitiesProjects();
    validationForConsultingProjects();
    console.log("CLICKING");
  };

  // api handle
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(project);
    if (isValid) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(project),
      };
      closeModal();
      const response = await fetch(
        `/api/ProjectData?function=saveProject&${props.userInfo.userId}`,
        requestOptions
      );
      await response.json().finally(props.reload());
      setStep4Active(true);
      setStep4Comleted(true);
    } else {
      console.log("There are some empty fields you need to fill up!");
    }
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={props.isOpen}
      onClose={closeModal}
    >
      <Header icon="arrow right" content="New Project" />
      <Modal.Header>
        <Step.Group ordered size="tiny">
          <Step active={step1Active} completed={step1Completed}>
            <Step.Content>
              <Step.Title>Type</Step.Title>
              <Step.Description>Choose your project type</Step.Description>
            </Step.Content>
          </Step>

          <Step active={step2Active} completed={step2Completed}>
            <Step.Content>
              <Step.Title>Details</Step.Title>
              <Step.Description>Enter your project details</Step.Description>
            </Step.Content>
          </Step>

          <Step active={step3Active} completed={step3Completed}>
            <Step.Content>
              <Step.Title>Member</Step.Title>
              <Step.Description>Select members</Step.Description>
            </Step.Content>
          </Step>

          <Step active={step4Active} completed={step4Completed}>
            <Step.Content>
              <Step.Title>Confirm or Cancel</Step.Title>
              <Step.Description>Make the decision</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Modal.Header>
      <Modal.Content>
        <h3>New project</h3>
        <div>
          {
            {
              1: (
                <ProjectType
                  message={message}
                  handleProjectType={handleProjectType}
                  project={project}
                  setIsValidProjectType={setIsValidProjectType}
                />
              ),
              2: (
                <NewProject
                  message={message}
                  handleChange={setProject}
                  project={project}
                  {...props}
                />
              ),
              3: (
                <ProjectMember
                  handleChange={setProject}
                  {...props}
                  project={project}
                />
              ),
              4: <Confirmation project={project} {...props} />,
            }[step]
          }
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>
          <Icon name="cancel" />
          Cancel
        </Button>
        {step > 1 ? <Button onClick={prevStep}>Back</Button> : null}

        <Button
          onClick={
            step === 4
              ? handleSubmit
              : step === 3
              ? nextStep
              : step === 2
              ? validateDetail
              : step === 1
              ? validateType
              : null
          }
        >
          {step === 4 ? "Submit" : "Next"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewProjectModal;
