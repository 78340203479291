// module "project-manager-functions.js"

const lookUpProjectManager = (searchId, projectManagers) => {
  const pmPri1 = projectManagers.find(f => f.value === searchId)
  const pmPri2 = projectManagers.find(f => f.userId === searchId)
  if (pmPri1) {
    return pmPri1
  }
  if (pmPri2) {
    return pmPri2
  }
  return
}

const lookUpProjectManagerName = (searchId, projectManagers) => {
  if (!searchId || !projectManagers) {
    return "Unknown"
  }
  const pmPri1 = projectManagers.find(f => f.value === searchId)
  const pmPri2 = projectManagers.find(f => f.userId === searchId)
  if (pmPri1) {
    return pmPri1.name
  }
  if (pmPri2) {
    return pmPri2.name
  }
  return "Unknown"
}

const lookUpProjectManagerEmail = (searchId, projectManagers) => {
  if (!searchId || !projectManagers) {
    return "Unknown"
  }
  const pmPri1 = projectManagers.find(f => f.value === searchId)
  const pmPri2 = projectManagers.find(f => f.userId === searchId)
  if (pmPri1) {
    return pmPri1.userName
  }
  if (pmPri2) {
    return pmPri2.userName
  }
  return "Unknown"
}


const projectManagerDropDownOptions = (projectManagers) => {
  if (projectManagers === undefined) {
    return []
  }

  return projectManagers.map((option) => {
    return { key: option.userId, value: option.userId, text: option.name };
  }).filter((option) => option.key);
}


export { lookUpProjectManager, lookUpProjectManagerName, lookUpProjectManagerEmail, projectManagerDropDownOptions }