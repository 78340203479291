const newBudgetLine = () => {
  return {
    budgetYear: "" + new Date().getFullYear(),
  }
}

const statusBudget = (budget) => {
  if (!budget || !budget.length) {
    return undefined
  }

  const currentYear = "" + new Date().getFullYear()
  const budgetThisYear = budget.filter(budgetLine => budgetLine.budgetYear === currentYear)
  if (budgetThisYear.length === 0) {
    return undefined
  }

  const notApproved = budgetThisYear.filter(budgetLine => budgetLine.budgetLineStatus === "Not approved")
  const forApproval = budgetThisYear.filter(budgetLine => budgetLine.budgetLineStatus === "For approval" || budgetLine.budgetLineStatus === undefined)

  if (notApproved.length > 0) {
    return "red"
  }
  else if (forApproval.length > 0) {
    return "yellow"
  }
  else if (forApproval.length === 0 && notApproved.length === 0) {
    return "green"
  }
  else {
    return undefined
  }
}

const canUserApprove = (userId, projectId, ttoApprovers, innovationApprovers, merkantileApprovers) => {
  const projectSeries = projectId.charAt(0);

  if (projectSeries === "8" || Number(projectId) < 10000) {
    return ttoApprovers.includes(userId);
  } else if (["2", "4", "5"].includes(projectSeries)) {
    return innovationApprovers.includes(userId);
  } else if (["1", "3" ,"6", "7", "9"].includes(projectSeries)){
    return merkantileApprovers.includes(userId);
  } else {
    return false;
  }
};

const sortedBudgetLines = (budgetLines) => {
  return [...budgetLines].sort((a, b) => Number(b.budgetYear) - Number(a.budgetYear));
};


export { newBudgetLine, statusBudget, canUserApprove, sortedBudgetLines }