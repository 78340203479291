import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { formatNumber, numberFormatCheck } from "../functions/numbers";

const SpecificationCell = (props) => {
  const { value, isInEdit, handleChangeNumber } = props;
  return (
    <Grid.Column width={2}>
      {!isInEdit && <p>{formatNumber(value)}</p>}
      {isInEdit && (
        <>
          <Form.Input
            name="valueNameTodo"
            size="mini"
            style={{ margin: "0px 0px 3.73px", width: "90px" }}
            onKeyPress={numberFormatCheck}
            onChange={handleChangeNumber}
            value={value}
          />
        </>
      )}
    </Grid.Column>
  );
};

export default SpecificationCell;
