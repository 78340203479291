import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'

function ConfirmDeleteModal(props) {
  const [open, setOpen] = React.useState(false)

  const deleteFunction = () => {
    props.deleteFunction()
    setOpen(false)
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button>
                <Icon name="trash alternate" /> Delete
              </Button>}
      size="mini"
      centered={true}
    >

    <Modal.Header>
      {props.modalHeader}
    </Modal.Header>
    <Modal.Content>
      <p>{props.modalContent}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)}>
        Cancel
      </Button>
      <Button negative onClick={() => deleteFunction()}>
        Confirm delete
      </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmDeleteModal