import React from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Form,
  Table,
  Tab,
} from "semantic-ui-react";
import RAGButton from "../RAGButton";
import { lookUpProjectManagerName } from "../../functions/project-manager-functions";
import { reformatDateForHistory } from "../../functions/reformat-date";
import { Parser } from "html-to-react";
import ExportHistory from "../ExportHistory";
import { getArrayItems } from "../../functions/get-array-items";

const HistoryModal = (props) => {
  let showTable = true;
  const projectHistories = props.project.history ? props.project.history : [];
  let sizeOfModal = "small";
  let message = "";
  const projectName = props.project.projectName;
  if (projectHistories.length === 0) {
    showTable = false;
    message = "There is no history for current project!";
  } else {
    sizeOfModal = "large";
    showTable = true;
  }

  const setModalClosed = () => {
    props.dispatch({ type: "setModal", payload: "" });
  };

  const handleCancelButton = () => {
    setModalClosed();
  };

  const htmlParser = new Parser();

  const panes = [
    {
      menuItem: "Log",
      render: () => (
        <Tab.Pane attached={false}>
          <Modal.Content image scrolling>
            <Form>
              {showTable && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Changed on</Table.HeaderCell>
                      <Table.HeaderCell>Changed by</Table.HeaderCell>
                      <Table.HeaderCell>Changed</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {projectHistories
                      .filter((h) => h.type === "changes")
                      .map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {htmlParser.parse(
                              reformatDateForHistory(item.timestamp)
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {lookUpProjectManagerName(
                              item.userId,
                              props.projectManagers
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.change}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              )}
            </Form>
          </Modal.Content>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Status",
      render: () => (
        <Tab.Pane attached={false}>
          <Modal.Content image scrolling>
            <Form>
              {showTable && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Created on</Table.HeaderCell>
                      <Table.HeaderCell>Created by</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Progress Description</Table.HeaderCell>
                      <Table.HeaderCell>Next Steps</Table.HeaderCell>
                      <Table.HeaderCell>Risk Evaluation</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {projectHistories
                      .filter((h) => h.type === "status")
                      .map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {htmlParser.parse(
                              reformatDateForHistory(item.timestamp)
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {lookUpProjectManagerName(
                              item.userId,
                              props.projectManagers
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <RAGButton
                              color={item.statusProgress}
                              readonly={true}
                              ragTopic="Progress"
                            />
                            <RAGButton
                              color={item.statusCost}
                              readonly={true}
                              ragTopic="Cost"
                            />
                            <RAGButton
                              color={item.statusRisk}
                              readonly={true}
                              ragTopic="Risk"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {htmlParser.parse(item.progressDescription)}
                          </Table.Cell>
                          <Table.Cell>
                            {htmlParser.parse(item.nextSteps)}
                          </Table.Cell>
                          <Table.Cell>
                            {htmlParser.parse(item.riskEvaluation)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              )}
            </Form>
          </Modal.Content>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Stage",
      render: () => (
        <Tab.Pane attached={false}>
          <Modal.Content image scrolling>
            <Form>
              {showTable && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Created on</Table.HeaderCell>
                      <Table.HeaderCell>Created by</Table.HeaderCell>
                      <Table.HeaderCell>Stage</Table.HeaderCell>
                      <Table.HeaderCell>Decision</Table.HeaderCell>
                      <Table.HeaderCell>Checklist</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {projectHistories
                      .filter((h) => h.type === "gate")
                      .map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {htmlParser.parse(
                              reformatDateForHistory(item.timestamp)
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {lookUpProjectManagerName(
                              item.userId,
                              props.projectManagers
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.gate}</Table.Cell>
                          <Table.Cell>{item.decision}</Table.Cell>
                          <Table.Cell>
                            {getArrayItems(item.checklist)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              )}
            </Form>
          </Modal.Content>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Archive",
      render: () => (
        <Tab.Pane attached={false}>
          <Modal.Content image scrolling>
            <Form>
              {showTable && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Created on</Table.HeaderCell>
                      <Table.HeaderCell>Created by</Table.HeaderCell>
                      <Table.HeaderCell>Archive Status</Table.HeaderCell>
                      <Table.HeaderCell>Archive Comment</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {projectHistories
                      .filter((h) => h.type === "archive")
                      .map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {htmlParser.parse(
                              reformatDateForHistory(item.timestamp)
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {lookUpProjectManagerName(
                              item.userId,
                              props.projectManagers
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.archiveStatus}</Table.Cell>
                          <Table.Cell>{item.archiveComment}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              )}
            </Form>
          </Modal.Content>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={props.isOpen}
      onClose={setModalClosed}
      size={sizeOfModal}
    >
      <Header icon="history" content="History" />
      <Modal.Header>
        {props.project.id} - {props.project.projectName}
      </Modal.Header>

      <h2 style={{ textAlign: "center" }}>
        <strong>{message}</strong>
      </h2>

      {showTable && (
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      )}
      <Modal.Actions>
        <ExportHistory
          projectHistories={projectHistories}
          projectManagers={props.projectManagers}
          projectName={projectName}
        />
        <Button onClick={handleCancelButton}>
          <Icon name="cancel" />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default HistoryModal;
