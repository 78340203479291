import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'

function ConfirmDiscardModal(props) {
  const [open, setOpen] = React.useState(false)

  const discardFunction = () => {
    props.discardFunction()
    setOpen(false)
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button>
                <Icon name="x" /> Cancel
              </Button>}
      size="mini"
      centered={true}
    >

    <Modal.Header>
      {props.modalHeader}
    </Modal.Header>
    <Modal.Content>
      <p>{props.modalContent}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)}>
        Cancel
      </Button>
      <Button negative onClick={() => discardFunction()}>
        Confirm discard
      </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmDiscardModal