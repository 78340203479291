import React, { useState } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import './OwnerList.css'

const matchSelected = (option, selected) => {
    const { value: listValue, subList, parentValue = null } = option

    const newValue = parentValue ? parentValue + ":" + listValue : listValue
    let isSelected = false;
    if (selected) {
        isSelected = selected.includes(newValue)
    }
    if (subList) {
        const newChildren = subList.map(element => {
            element.parentValue = newValue
            return matchSelected(element, selected)
        })
        return { label: listValue, value: newValue, children: newChildren, checked: isSelected }
    }
    return { label: listValue, value: newValue, checked: isSelected }
}

const OwnerList = (props) => {
    const initalValue = props.value && props.value.split(";")
    const [selectedValue, updateSelected] = useState(initalValue)

    const listFileData = require('../../data/OwnerList.json')

    const fieldOptionData = listFileData.map(option => {
        return matchSelected(option, selectedValue)
    })

    const onChangeList = (currentNode, selectedNodes) => {
        console.log('onChange::', currentNode, selectedNodes)
        const selectCodes = currentNode.value
        updateSelected(selectCodes)
        const event = { name: props.name, value: currentNode.value }
        props.onChange(event, event)
    }
    const onAction = (node, action) => {
        console.log('onAction::', action, node)
    }
    const onNodeToggle = currentNode => {
        console.log('onNodeToggle::', currentNode)
    }

    return (
        <DropdownTreeSelect label='Select:'
            data={fieldOptionData}
            onChange={onChangeList} onAction={onAction} onNodeToggle={onNodeToggle} mode="hierarchical" />
    )
}

export default OwnerList