import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon } from 'semantic-ui-react';
import { lookUpProjectManagerName } from "../functions/project-manager-functions";
import { reformatDateForExcelJS } from '../functions/reformat-date'
import { addFieldToProject } from '../functions/project-card-functions';
import { exportToExcel } from '../functions/export-data-functions';
import { getArrayItemsAsString } from "../functions/get-array-items";

const ExportHistory = (props) => {
    const getSelected = (checkList, item) => {
        let output = {
            "outputType": item.type,
            "outputData": {}
        }
        for (let x in checkList) {
            if (checkList[x].key === "logChangedOn") {
                output.outputData = addFieldToProject(output.outputData, "Log - Changed on", item.type === 'changes' ? reformatDateForExcelJS(item.timestamp) : null)
            }
            if (checkList[x].key === "logChangedBy") {
                output.outputData = addFieldToProject(output.outputData, "Log - Changed by", item.type === 'changes' ? lookUpProjectManagerName(item.userId, props.projectManagers) : null)
            }
            if (checkList[x].key === "logChanged") {
                output.outputData = addFieldToProject(output.outputData, "Log - Changed", item.type === 'changes' ? item.change : null)
            }
            if (checkList[x].key === "statusCreatedOn") {
                output.outputData = addFieldToProject(output.outputData, "Status - Created on", item.type === 'status' ? reformatDateForExcelJS(item.timestamp) : null)
            }
            if (checkList[x].key === "statusCreatedBy") {
                output.outputData = addFieldToProject(output.outputData, "Status - Created by", item.type === 'status' ? lookUpProjectManagerName(item.userId, props.projectManagers) : null)
            }
            if (checkList[x].key === "statusProgress") {
                output.outputData = addFieldToProject(output.outputData, "Status - Progress", item.type === 'status' ? item.statusProgress : null)
            }
            if (checkList[x].key === "statusCost") {
                output.outputData = addFieldToProject(output.outputData, "Status - Cost", item.type === 'status' ? item.statusCost : null)
            }
            if (checkList[x].key === "statusRisk") {
                output.outputData = addFieldToProject(output.outputData, "Status - Risk", item.type === 'status' ? item.statusRisk : null)
            }
            if (checkList[x].key === "statusProgressDescription") {
                output.outputData = addFieldToProject(output.outputData, "Status - Progress Description", item.type === 'status' ? item.progressDescription : null)
            }
            if (checkList[x].key === "statusNextSteps") {
                output.outputData = addFieldToProject(output.outputData, "Status - Next Steps", item.type === 'status' ? item.nextSteps : null)
            }
            if (checkList[x].key === "statusRiskEvaluation") {
                output.outputData = addFieldToProject(output.outputData, "Status - Risk Evaluation", item.type === 'status' ? item.riskEvaluation : null)
            }
            if (checkList[x].key === "stageCreatedOn") {
                output.outputData = addFieldToProject(output.outputData, "Stage - Created on", item.type === 'gate' ? reformatDateForExcelJS(item.timestamp) : null)
            }
            if (checkList[x].key === "stageCreatedBy") {
                output.outputData = addFieldToProject(output.outputData, "Stage - Created by", item.type === 'gate' ? lookUpProjectManagerName(item.userId, props.projectManagers) : null)
            }
            if (checkList[x].key === "stageStage") {
                output.outputData = addFieldToProject(output.outputData, "Stage - Stage", item.type === 'gate' ? item.gate : null)
            }
            if (checkList[x].key === "stageDecision") {
                output.outputData = addFieldToProject(output.outputData, "Stage - Decision", item.type === 'gate' ? item.decision : null)
            }
            if (checkList[x].key === "stageChecklist") {
                output.outputData = addFieldToProject(output.outputData, "Stage - Checklist", item.type === 'gate' ? getArrayItemsAsString(item.checklist) : null)
            }
            if (checkList[x].key === "archiveCreatedOn") {
                output.outputData = addFieldToProject(output.outputData, "Archive - Created on", item.type === 'archive' ? reformatDateForExcelJS(item.timestamp) : null)
            }
            if (checkList[x].key === "archiveCreatedBy") {
                output.outputData = addFieldToProject(output.outputData, "Archive - Created by", item.type === 'archive' ? lookUpProjectManagerName(item.userId, props.projectManagers) : null)
            }
            if (checkList[x].key === "archiveArchiveStatus") {
                output.outputData = addFieldToProject(output.outputData, "Archive - Archive Status", item.type === 'archive' ? item.archiveStatus : null)
            }
            if (checkList[x].key === "archiveArchiveComment") {
                output.outputData = addFieldToProject(output.outputData, "Archive - Archive Comment", item.type === 'archive' ? item.archiveComment : null)
            }
        }
        return output
    }

    const mapToTable = (items) => {
        let checkList = props.arrayOfCheckedItems
        return items.map((item) => {
            if (checkList === undefined || checkList.length === 0) {
                checkList = [
                    {
                        "key": "logChangedOn",
                        "text": "Log - Changed on",
                        "value": "logChangedOn"
                    },
                    {
                        "key": "logChangedBy",
                        "text": "Log - Changed by",
                        "value": "logChangedby"
                    },
                    {
                        "key": "logChanged",
                        "text": "Log - Changed",
                        "value": "logChanged"
                    },
                    {
                        "key": "statusCreatedOn",
                        "text": "Status - Created on",
                        "value": "statusCreatedOn"
                    },
                    {
                        "key": "statusCreatedBy",
                        "text": "Status - Created by",
                        "value": "statusCreatedBy"
                    },
                    {
                        "key": "statusProgress",
                        "text": "Status - Progress",
                        "value": "statusProgress"
                    },
                    {
                        "key": "statusCost",
                        "text": "Status - Cost",
                        "value": "statusCost"
                    },
                    {
                        "key": "statusRisk",
                        "text": "Status - Risk",
                        "value": "statusRisk"
                    },
                    {
                        "key": "statusProgressDescription",
                        "text": "Status - Progress Description",
                        "value": "statusProgressDescription"
                    },
                    {
                        "key": "statusNextSteps",
                        "text": "Status - Next Steps",
                        "value": "statusNextSteps"
                    },
                    {
                        "key": "statusRiskEvaluation",
                        "text": "Status - Risk Evaluation",
                        "value": "statusRiskEvaluation"
                    },
                    {
                        "key": "stageCreatedOn",
                        "text": "Stage - Created on",
                        "value": "stageCreatedOn"
                    },
                    {
                        "key": "stageCreatedBy",
                        "text": "Stage - Created by",
                        "value": "stageCreatedBy"
                    },
                    {
                        "key": "stageStage",
                        "text": "Stage - Stage",
                        "value": "stageStage"
                    },
                    {
                        "key": "stageDecision",
                        "text": "Stage - Decision",
                        "value": "stageDecision"
                    },
                    {
                        "key": "stageChecklist",
                        "text": "Stage - Checklist",
                        "value": "stageChecklist"
                    },
                    {
                        "key": "archiveCreatedOn",
                        "text": "Archive - Created on",
                        "value": "archiveCreatedOn"
                    },
                    {
                        "key": "archiveCreatedBy",
                        "text": "Archive - Created by",
                        "value": "archiveCreatedBy"
                    },
                    {
                        "key": "archiveArchiveStatus",
                        "text": "Archive - Archive Status",
                        "value": "archiveArchiveStatus"
                    },
                    {
                        "key": "archiveArchiveComment",
                        "text": "Archive - Archive Comment",
                        "value": "archiveArchiveComment"
                    }
                ]
            }
            return getSelected(checkList, item)
        });
    }

    return (
        <Button
            onClick={() => {
                exportToExcel(mapToTable(props.projectHistories), props.projectName);
            }}
        >
            <Button.Content visible><Icon name="file excel outline" /></Button.Content>
        </Button>
    )
}

export default ExportHistory;