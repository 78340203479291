import React from "react";
import { Card, Grid, GridColumn, Icon } from "semantic-ui-react";
import RAGButton from "./RAGButton";
import ProjectSiteButton from "./ProjectSiteButton";
import ProjectManager from "./elements/ProjectManager";
import OwnerLabel from "./elements/OwnerLabel";
import InfoButton from "./InfoButton";
import ModalButton from "./modals/ModalButton";
import ArchiveStatusIcon from "./ArchiveStatusIcon";
import "./ProjectCardSingle.css";
import { Parser } from "html-to-react";
import { useAppState } from "../state/useAppState";
import { statusBudget } from "../budget/budget-functions";
import { statusApplications } from "../applications/application-functions";
import DOFIButton from "./DOFIButton";
import SpinOffButton from "./SpinOffButton"
import LicenseButton from "./LicenseButton"

const ProjectCardSingle = (props) => {
  const { dispatcher } = useAppState();

  let color = "White";
  if (props.project.archiveStatus === "Running") {
    color = "AliceBlue";
  } else if (props.project.archiveStatus === "Archived") {
    color = "#F6F6F6";
  } else if (props.project.archiveStatus === "On-hold") {
    color = "#ffffe0";
  } else if (props.project.archiveStatus === "notFound") {
    color = "#BB6464";
  }

  const reformatDate = (date) => {
    if (date === "" || date === undefined || date === null) {
      return "Undefined";
    }
    let year = date.slice(0, 4);
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    return day + "." + month + "." + year;
  };

  const posRight = {
    float: "right",
    width: "10px",
  };

  const htmlParser = new Parser();

  return (
    <Card style={{ minWidth: "100%", background: color }}>
      <Card.Content>
        <Card.Content extra>
          <Grid columns={"equal"}>
            <Grid.Row
              column={2}
              equal={true.toString()}
              width={true.toString()}
              grid={true.toString()}
            >
              <Grid.Column style={{ minWidth: "25%" }}>
                <Card.Meta>
                  <span className="date">
                    {props.project.id}
                    <InfoButton infoData={props.project} />
                    <DOFIButton project={props.project} />
                    <SpinOffButton project={props.project} />
                    <LicenseButton project={props.project} />
                    <ProjectSiteButton
                      project={props.project}
                      dispatch={props.projectDispatch}
                    />
                  </span>
                </Card.Meta>
                <Card.Meta>
                  <span>
                    Start Date: {reformatDate(props.project.startDate)}
                  </span>
                </Card.Meta>
              </Grid.Column>
              <Grid.Column style={posRight}>
                <ModalButton
                  project={props.project}
                  dispatch={props.projectDispatch}
                  modalName={"teamMemberAndProjectManagerModal"}
                  modalDescription={"Team members"}
                  iconName={"users"}
                />{" "}
                <ModalButton
                  project={props.project}
                  dispatch={props.projectDispatch}
                  modalName={"progressModal"}
                  modalDescription={"Evaluation of current project status"}
                  iconName={"cogs"}
                />{" "}
                {props.project.projectType === "TTO" && (
                  <ModalButton
                    project={props.project}
                    dispatch={props.projectDispatch}
                    modalName={"stageGateDecisionModal"}
                    modalDescription={" Decision to move to next stage"}
                    iconName={"handshake"}
                  />
                )}{" "}
                <ModalButton
                  project={props.project}
                  dispatch={dispatcher}
                  modalName={"budgetModal"}
                  modalDescription={"Edit budget"}
                  iconName={"dollar"}
                />{" "}
                <ModalButton
                  project={props.project}
                  dispatch={dispatcher}
                  modalName={"applicationModal"}
                  modalDescription={"Manage applications"}
                  iconName={"faHandHoldingDollar"}
                />{" "}
                <ModalButton
                  project={props.project}
                  dispatch={props.projectDispatch}
                  modalName={"projectDetails"}
                  modalDescription={"Project details"}
                  iconName={"edit"}
                />{" "}
                <ModalButton
                  project={props.project}
                  dispatch={props.projectDispatch}
                  modalName={"archiveModal"}
                  modalDescription={"Decision to hold or archive the project"}
                  iconName={"archive"}
                />{" "}
                <ModalButton
                  project={props.project}
                  dispatch={props.projectDispatch}
                  modalName={"historyModal"}
                  modalDescription={"Show history of status, stage and archive"}
                  iconName={"history"}
                />{" "}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <br />
        <Card.Header>{props.project.projectName} </Card.Header>
        <Card.Description>
          <div style={{ paddingBottom: "10px" }}>
            <ProjectManager
              projectManagerId={props.project.ownerId}
              projectManagers={props.projectManagers}
            />
          </div>
          {props.project.leadInventor !== "Not applicable" && (
            <div style={{ paddingBottom: "10px" }}>
              Lead Inventor: <Icon color="purple" name="user" />{" "}
              {props.project.leadInventor}
            </div>
          )}
          <div style={{ paddingBottom: "10px" }}>
            {props.project.projectType === "TTO" && (
              <div>
                {" "}
                TTO Stage: {props.project.projectGate}
                <br />
              </div>
            )}
          </div>
          <div>
            Owning unit 1: <OwnerLabel owner={props.project.owningUnit1} />
          </div>
          {props.project.owningUnit2 && (
            <div>
              <br />
              Owning unit 2: <OwnerLabel owner={props.project.owningUnit2} />
            </div>
          )}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Card.Meta>
          <span>Progress:</span>
        </Card.Meta>
        <Card.Description>
          {htmlParser.parse(props.project.progressDescription)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Card.Meta>
          <span>Next steps:</span>
        </Card.Meta>
        <Card.Description>
          {htmlParser.parse(props.project.nextSteps)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Card.Meta>
          <span>Risk Evaluation:</span>
        </Card.Meta>
        <Card.Description>
          {htmlParser.parse(props.project.riskEvaluation)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Card.Description>
          <Grid columns={5} verticalAlign="middle" centered>
            <Grid.Column style={{ width: "85px" }}>
              <RAGButton
                color={props.project.statusProgress}
                readonly={true}
                ragTopic="Progress"
              />
            </Grid.Column>
            <Grid.Column style={{ width: "63px" }}>
              <RAGButton
                color={props.project.statusCost}
                readonly={true}
                ragTopic="Cost"
              />
            </Grid.Column>
            <Grid.Column style={{ width: "63px" }}>
              <RAGButton
                color={props.project.statusRisk}
                readonly={true}
                ragTopic="Risk"
              />
            </Grid.Column>
            <Grid.Column style={{ width: "75px" }}>
              <RAGButton
                color={statusBudget(props.project.budget)}
                readonly={true}
                ragTopic="Budget"
              />
            </Grid.Column>
            <Grid.Column style={{ width: "130px" }}>
              <RAGButton
                color={statusApplications(props.project.applications)}
                readonly={true}
                ragTopic="Applications"
              />
            </Grid.Column>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
export default ProjectCardSingle;
