import React from 'react'
import { Icon, Button } from 'semantic-ui-react'

const ReloadButton = (props) => {

    const reloadProjects = () => {
        props.reloadProjects();
    }

    return (
        <Button onClick={() => { reloadProjects() }}>
            <Icon name="refresh" />
        </Button>
    );
}

export default ReloadButton