import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  Icon, Popup
} from "semantic-ui-react";

const ModalButton = (props) => {

  const openModal = () => {
    props.dispatch({ type: 'setProjectForEdit', payload: props.project })
    props.dispatch({ type: 'setModal', payload: props.modalName })
  };

  return (
    <Popup
      trigger={<Button icon size="mini" onClick={() => openModal()} compact>
        {props.iconName === "faHandHoldingDollar" ? (
          <Icon color='blue'>
            <FontAwesomeIcon icon={faHandHoldingDollar} />
          </Icon>
          ) : (
          <Icon color='blue' name={props.iconName} />
        )}
      </Button>} wide="very">
      {props.modalDescription}
    </Popup>
  );
};

export default ModalButton;