
import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'
import { reformatDate } from "../functions/reformat-date";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faD } from '@fortawesome/free-solid-svg-icons'
import { openAbsoluteUrl } from '../functions/project-card-functions'

const DOFIButton = (props) => {
  const dofiDate = props.project.dofiDate && props.project.dofiDate.slice(0, 10);
  const dofiURL = props.project.dofiURL;
  let message = "";
  let backgroundColor = null

  if (dofiDate === "" || dofiDate === undefined || dofiDate == null) {
    backgroundColor = null;
    message = "No DOFI registration yet.";
  } else { //TODO Should it be checked whether it acually is a date or not?
    backgroundColor = '#bce416';
    message = "DOFI registration date: " + reformatDate(dofiDate);
  }

  const iconStyle = {
    backgroundColor: backgroundColor,
    cursor: dofiURL === "" || dofiURL === undefined || dofiURL == null ? "default" : "pointer",
  }

  return (
    <Popup
        trigger= {<Icon circular size="small" style={iconStyle} onClick={() => openAbsoluteUrl(dofiURL)}>
                      <FontAwesomeIcon icon={faD} />
                  </Icon>}
    >
        {message}
    </Popup>
  )
};

export default DOFIButton
