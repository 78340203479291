import React from 'react'
import { Grid } from 'semantic-ui-react'
import StatusColorButton from "../StatusColorButton";

const StatusFilter = (props) => {

  const handleChangeProgress = (e, { name, value }) => {
    console.log(`Handle change PROGRESS: ${name}, ${value}`);
    props.filterDispatch(
      {
        type: 'statusProgressFilter',
        payload: value
      })
  };

  const handleChangeCost = (e, { name, value }) => {
    console.log(`Handle change COST: ${name}, ${value}`);
    props.filterDispatch(
      {
        type: 'statusCostFilter',
        payload: value
      })
  };

  const handleChangeRisk = (e, { name, value }) => {
    console.log(`Handle change risk: ${name}, ${value}`);
    props.filterDispatch(
      {
        type: 'statusRiskFilter',
        payload: value
      })
  };

  const handleChangeBudget = (e, { name, value }) => {
    console.log(`Handle change budget: ${name}, ${value}`);
    props.filterDispatch(
      {
        type: 'statusBudgetFilter',
        payload: value
      })
  };

  const handleChangeApplications = (e, { name, value }) => {
    console.log(`Handle change applications: ${name}, ${value}`);
    props.filterDispatch(
      {
        type: 'statusApplicationFilter',
        payload: value
      })
  };

  return (
    <Grid>
      <Grid.Row double={true.toString()} columns={5}>
        <Grid.Column>
          <StatusColorButton name="statusProgress" color={props.filterState.statusProgressFilter} onChange={handleChangeProgress}
            ragTopic="Progress" />
        </Grid.Column>
        <Grid.Column>
          <StatusColorButton name="statusCost" color={props.filterState.statusCostFilter} onChange={handleChangeCost} ragTopic="Cost" />
        </Grid.Column>
        <Grid.Column>
          <StatusColorButton name="statusRisk" color={props.filterState.statusRiskFilter} onChange={handleChangeRisk} ragTopic="Risk" />
        </Grid.Column>
        <Grid.Column>
          <StatusColorButton name="statusBudget" color={props.filterState.statusBudgetFilter} onChange={handleChangeBudget} ragTopic="Budget" />
        </Grid.Column>
        <Grid.Column>
          <StatusColorButton name="statusApplications" color={props.filterState.statusApplicationFilter} onChange={handleChangeApplications} ragTopic="Applications" />
        </Grid.Column>
      </Grid.Row>
    </Grid>

  )
}

export default StatusFilter