
import React from "react";
import { Table } from "semantic-ui-react";
import { lookUpProjectManagerName } from "../../functions/project-manager-functions";


const Confirmation = (props) => {

  const ttoProject = "TTO"
  const administrative = "Administrative"
  const innovation = "Innovation"
  const company = "Company"
  const consulting = "Consulting"
  const supportingActivities = "Supporting"
  const project = props.project

  const displayFor = showWhen => {
    return showWhen.includes(project.projectType)
  }

  return (
    <div>
      <Table celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell><h2>Check and Confirm</h2></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell>  <strong>Project Type*: </strong> </Table.Cell>
            <Table.Cell>{project.projectType}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><strong> Project Name*: </strong></Table.Cell>
            <Table.Cell>{project.projectName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><strong>Project Manager: </strong></Table.Cell>
            <Table.Cell> {lookUpProjectManagerName(project.ownerId, props.projectManagers)}</Table.Cell>
          </Table.Row>
          {displayFor([administrative, company, consulting, innovation, supportingActivities]) &&
            <Table.Row >
              <Table.Cell>   <strong>VIS Process Area: </strong> </Table.Cell>
              <Table.Cell>{project.processArea}</Table.Cell>
            </Table.Row>
          }
          <Table.Row >
            <Table.Cell>   <strong>Start Date*: </strong> </Table.Cell>
            <Table.Cell>{project.startDate}</Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>  <strong> Exit Route: </strong> </Table.Cell>
            <Table.Cell> {project.exitRoute}</Table.Cell>
          </Table.Row>
          {displayFor([innovation]) &&
            <Table.Row >
              <Table.Cell> <strong>Count As Idea: </strong></Table.Cell>
              <Table.Cell> {project.countAsIdea}</Table.Cell>
            </Table.Row>
          }
          {displayFor([administrative, ttoProject, company, innovation, supportingActivities]) &&
            <Table.Row >
              <Table.Cell> <strong>Owning Unit 1*: </strong></Table.Cell>
              <Table.Cell>{project.owningUnit1}</Table.Cell>
            </Table.Row>
          }
          {displayFor([ttoProject, innovation, supportingActivities]) &&
            <Table.Row >
              <Table.Cell>  <strong>Sub owner: </strong> </Table.Cell>
              <Table.Cell>{project.subOwningUnit}</Table.Cell>
            </Table.Row>
          }
          {displayFor([administrative, ttoProject, company, innovation, supportingActivities]) &&
            <Table.Row >
              <Table.Cell>  <strong>Owning Unit 2: </strong></Table.Cell>
              <Table.Cell>{project.owningUnit2}</Table.Cell>
            </Table.Row>
          }
          {displayFor([ttoProject]) &&
            <><Table.Row>
              <Table.Cell> <strong>Owner percentage - Unit 1: </strong> </Table.Cell>
              <Table.Cell>{project.ownerPercentage1}</Table.Cell>
            </Table.Row><Table.Row>
                <Table.Cell>  <strong>Owner percentage - Unit 2: </strong> </Table.Cell>
                <Table.Cell>{project.ownerPercentage2}</Table.Cell>
              </Table.Row></>
          }
          {displayFor([innovation, ttoProject]) &&
            <Table.Row >
              <Table.Cell> <strong>Lead Inventor*: </strong></Table.Cell>
              <Table.Cell>  {project.leadInventor}</Table.Cell>
            </Table.Row>}
          <Table.Row >
            <Table.Cell>  <strong>Non-confidential Information*: </strong> </Table.Cell>
            <Table.Cell>{project.nonConfidentialInformation}</Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell> <strong>Project Team: </strong> </Table.Cell>
            <Table.Cell>
              {project.projectTeam && project
                .projectTeam.map(item => {
                  return <li style={{ listStyleType: "none" }}>{lookUpProjectManagerName(item, props.projectManagers)}</li>
                })}
            </Table.Cell>
          </Table.Row>

          {displayFor([consulting]) &&
            <Table.Row >
              <Table.Cell><strong>Client</strong></Table.Cell>
              <Table.Cell>{project.client}</Table.Cell>
            </Table.Row>}
          {displayFor([consulting]) &&
            <Table.Row >
              <Table.Cell>   <strong>Contact Person</strong> </Table.Cell>
              <Table.Cell> {project.contactPerson}</Table.Cell>
            </Table.Row>}
          {displayFor([administrative, ttoProject, company, innovation]) &&
            <Table.Row >
              <Table.Cell>  <strong>Fields: </strong> </Table.Cell>
              <Table.Cell>{project.fields}</Table.Cell>
            </Table.Row>}
          {displayFor([administrative, ttoProject, company, innovation]) &&
            <Table.Row >
              <Table.Cell>  <strong>Project Keywords: </strong> </Table.Cell>
              <Table.Cell> {project.projectKeywords}</Table.Cell>
            </Table.Row>}
          {displayFor([innovation]) &&
            <Table.Row >
              <Table.Cell>  <strong>Financed by 1: </strong> </Table.Cell>
              <Table.Cell>{project.financedBy1}</Table.Cell>
            </Table.Row>}
          {displayFor([innovation]) &&
            <Table.Row >
              <Table.Cell>  <strong>Financed by 2: </strong> </Table.Cell>
              <Table.Cell>{project.financedBy2}</Table.Cell>
            </Table.Row>}
          {/* {displayFor([ttoProject]) &&
            <Table.Row >
              <Table.Cell>   <strong>Expected Deal Type*: </strong> </Table.Cell>
              <Table.Cell> {project.expectedDealType}</Table.Cell>
            </Table.Row>}
          <Table.Row >
            <Table.Cell>  <strong>Related Gateway Projects :</strong> </Table.Cell>
            <Table.Cell> {project.relatedGatewayProjects}</Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>   <strong>Sustainable Development :</strong> </Table.Cell>
            <Table.Cell> {project.sustainableDevelopment}</Table.Cell>
          </Table.Row>*/}
        </Table.Body>
      </Table>
    </div>
  );


};

export default Confirmation;