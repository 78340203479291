import React, { useState } from "react";
import { Form, Select, Dropdown } from "semantic-ui-react";
import { projectManagerDropDownOptions } from "../../functions/project-manager-functions";


const ProjectMember = (props) => {
    const [project, setProject] = useState(props.project);

    const [teamMemberData] = useState();

    const handleChange = (e, { name, value }) => {
        console.log(`Handle change: ${name}, ${value}`);
        const updatedProject = { ...project, [name]: value };
        setProject(updatedProject);
        props.handleChange(updatedProject);
    };

    return (
        <div>
            <Form>
                <Form.Field
                    label="Project Manager"
                    name="ownerId"
                    value={project.ownerId}
                    control={Select}
                    options={projectManagerDropDownOptions(props.projectManagers)}
                    onChange={handleChange}
                />

                <strong>Add team member</strong>
                {JSON.stringify(teamMemberData)}
                <Dropdown
                    search
                    fluid
                    multiple
                    selection
                    placeholder="Add a team member"
                    name="projectTeam"
                    options={projectManagerDropDownOptions(props.projectManagers)}
                    onChange={handleChange}
                />
            </Form>
        </div>
    )
}

export default ProjectMember