import React from 'react'
import { Grid, Label } from 'semantic-ui-react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

const matchSelected = (option, selected) => {
    const { value: listValue, subList, parentValue = null } = option


    const newValue = parentValue ? parentValue + ":" + listValue : listValue
    const isSelected = selected.includes(newValue)
    if (subList) {
        const newChildren = subList.map(element => {
            element.parentValue = newValue
            return matchSelected(element, selected)
        })
        return { label: listValue, value: newValue, children: newChildren, checked: isSelected }
    }
    return { label: listValue, value: newValue, checked: isSelected }
}

const KeywordFilter = (props) => {
    const componentName = props.name
    const initalValue = props.value.split(";")
    const selectedValue = props.filterState.keywordFilter || initalValue
    const listFileData = props.projectKeywordList

    const fieldOptionData = listFileData.map(option => {
        return matchSelected(option, selectedValue)
    })


    const onChange = (currentNode, selectedNodes) => {
        console.log('onChange::', currentNode, selectedNodes)
        const selectCodes = selectedNodes.map(node => node.value)
        props.filterDispatch({ type: 'keywordFilter', payload: selectCodes })
    }
    const onAction = (node, action) => {
        console.log('onAction::', action, node)
    }
    const onNodeToggle = currentNode => {
        console.log('onNodeToggle::', currentNode)
    }

    return (
        <Grid.Row columns="1">
            <Label style={{ "backgroundColor": "white", "marginLeft": "1%", "marginRight": "1%", "color": "black", "display": "flex" }}><span style={{ "marginRight": "2%", "marginTop": "2%" }} >{componentName + ": "}</span>
                <DropdownTreeSelect
                    data={fieldOptionData}
                    onChange={onChange} onAction={onAction} onNodeToggle={onNodeToggle} mode="hierarchical" />
            </Label>
        </Grid.Row>
    )
}

export default KeywordFilter