const reformatDate = (date) => {
    if (date === "" || date === undefined || date === null) {
        return ""
    }
    //dd.mm.yyyy
    return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
}

const reformatDateForHistory = (date) => {
    if (date === "" || date === undefined || date === null) {
        return ""
    }
    //dd.mm.yy-hh:mm:ss
    return date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4) + "</br>" + date.slice(11, 13) + ":" + date.slice(14, 16) + ":" + date.slice(17, 19);
}

const reformatDateForExcelJS = (date) => {
    if (date === "" || date === undefined || date === null) {
        return ""
    }
    //yy,mm,dd
    const theDate = new Date(date.slice(0, 4), date.slice(5, 7)-1, date.slice(8, 10), date.slice(11,13),date.slice(14,16),date.slice(17,19))
    theDate.setHours(theDate.getHours()-(theDate.getTimezoneOffset()/60)) // The timezone offset is added to give correct date in the Excel file
    return theDate
}

export { reformatDate, reformatDateForHistory, reformatDateForExcelJS }
