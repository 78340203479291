import React from "react";
import { Grid, Dropdown } from "semantic-ui-react";
import {
  calculateTotalAmount,
  createSpecificationItem,
  getLifeTimeYears,
  getYearFromDate,
} from "./application-functions";
import { formatNumber } from "../functions/numbers";
import SpecificationLine from "./SpecificationLine";

const ApplicationSpecification = (props) => {
  const firstYear = getYearFromDate(props.periodFrom);
  const yearList = getLifeTimeYears(firstYear, 5);
  const specifications = props.specifications;

  const cleanListForYears = (specifications, yearList) => {
    return specifications.filter((specification) =>
      yearList.includes(specification.year)
    );
  };
  const specificationList = cleanListForYears(specifications, yearList);

  const fundingPartners = [
    {
      key: "Universitetet i Bergen",
      text: "Universitetet i Bergen",
      value: "Universitetet i Bergen",
    },
    {
      key: "Helse Bergen",
      text: "Helse Bergen",
      value: "Helse Bergen",
    },
    {
      key: "Høgskulen på Vestlandet",
      text: "Høgskulen på Vestlandet",
      value: "Høgskulen på Vestlandet",
    },
    {
      key: "Other - plese specify under Notes",
      text: "Other - plese specify under Notes",
      value: "Other - plese specify under Notes",
    },
  ];

  const fundingPartnerOptions = fundingPartners.filter((partner) => {
    return !specifications.some(
      (specification) => specification.partner === partner.key
    );
  });

  const handleChangePartner = (e, { name, value }) => {
    console.log(`Handle change: ${name}=${value} on line ${props.index + 1}`);
    const addPartner = createSpecificationItem(
      "PARTNER",
      value,
      firstYear.toString(),
      0
    );

    props.onChange(e, {
      name: "specificationList",
      value: [...specificationList, addPartner],
    });
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column width={3}>
          <p>
            <strong>Specification lifespan</strong>
          </p>
        </Grid.Column>
        <Grid.Column width={2}>
          <strong>Total</strong>
        </Grid.Column>
        {/* loop over yearList and create a column for each year */}
        {yearList.map((year, index) => {
          return (
            <Grid.Column key={index} width={2}>
              <p>
                <strong>{year}</strong>
              </p>
            </Grid.Column>
          );
        })}
        <Grid.Column width={1}></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <p>Funding - VIS hours</p>
        </Grid.Column>
        <SpecificationLine
          specifications={specificationList}
          yearList={yearList}
          specificationType={"VISHOURS"}
          isInEdit={props.isInEdit}
          onChange={props.onChange}
        />
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <p>Funding - VIS direct costs</p>
        </Grid.Column>
        <SpecificationLine
          specifications={specificationList}
          yearList={yearList}
          specificationType={"VISDIRECT"}
          isInEdit={props.isInEdit}
          onChange={props.onChange}
        />
      </Grid.Row>
      {specificationList
        .filter((specification) => specification.type === "PARTNER")
        .reduce((acc, curr) => {
          if (acc.findIndex((item) => item.partner === curr.partner) === -1) {
            acc.push(curr);
          }
          return acc;
        }, [])
        .map((specification, index) => {
          return (
            <Grid.Row>
              <Grid.Column width={3}>
                <p>
                  {specification.partner &&
                  specification.partner !== "Other - please specify under Notes"
                    ? `Funding - ${specification.partner}`
                    : `Funding per other partner`}
                </p>
              </Grid.Column>
              <SpecificationLine
                specifications={specificationList}
                yearList={yearList}
                specificationType={"PARTNER"}
                partner={specification.partner}
                isInEdit={props.isInEdit}
                onChange={props.onChange}
              />
            </Grid.Row>
          );
        })}
      <Grid.Row>
        <Grid.Column width={3}>
          <p>
            <strong>Total</strong>
          </p>
        </Grid.Column>
        <Grid.Column width={2}>
          <p>{formatNumber(calculateTotalAmount(specifications))}</p>
        </Grid.Column>
        {/* loop over yearList and create a column for each year */}
        {yearList.map((year, index) => {
          return (
            <Grid.Column key={index} width={2}>
              <p>{formatNumber(calculateTotalAmount(specifications, year))}</p>
            </Grid.Column>
          );
        })}
        <Grid.Column width={1}></Grid.Column>
      </Grid.Row>
      {props.isInEdit && (
        <Grid.Row>
          <Grid.Column width={3}>
            <p>Add partner</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <>
              <Dropdown
                name="addPartner"
                placeholder="Choose funding partner"
                fluid
                selection
                options={fundingPartnerOptions}
                style={{ margin: "0px 0px 3.73px", width: "400px" }}
                onChange={handleChangePartner}
                value={""}
              />
            </>
          </Grid.Column>
          <Grid.Column width={4}></Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default ApplicationSpecification;
