import React, { useState, useEffect } from "react";
import { Form, Radio, Popup } from "semantic-ui-react";

const ProjectType = ({ handleProjectType, message, setIsValidProjectType }, props) => {
  const [value, setValue] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const infoForAdministrativeProjects = "Leave of absence and vacation etc. Only to be used by administration.";
  const infoForttoProject = "Research based projects";
  const infoForCompanyProjects = "VIS owned companies";
  const infoForConsultingProjects = "Consulting project (invoiceable)";
  const infoForInnovationProjects = "User-driven projects and other projects that requires follow-up and reporting.";
  const infoForSupportingActivitiesProjects = "Includes stakeholder management, networking, communication, dealflow, skills development, etc.";

  useEffect(() => {
    if (!value) {
      setIsValidProjectType(false);
      setShowMessage(true);
    } else {
      setShowMessage(false);
      setIsValidProjectType(true);
    }
  }, [value])

  return (
    <div>
      <h2>Select Project Type</h2>
      {showMessage && (
        <div>
          <h3 style={{ color: 'red' }}>{message}</h3>
        </div>
      )}
      <p></p>
      <Form>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="Administrative"
              name="adminRadioGroup"
              value={props.ProjectType}
              checked={value === "Administrative"}
              onClick={() => setValue("Administrative")}
              onChange={() => handleProjectType("Administrative")}
            />} wide="very">
            {infoForAdministrativeProjects}
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="TTO"
              name="ttoProjectRadioGroup"
              value={props.ProjectType}
              checked={value === "TTO"}
              onClick={() => setValue("TTO")}
              onChange={() => handleProjectType("TTO")}
            />} wide="very">
            {infoForttoProject}
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="Company"
              name="CompanyRadioGroup"
              value={props.ProjectType}
              checked={value === "Company"}
              onClick={() => setValue("Company")}
              onChange={() => handleProjectType("Company")}
            />} wide="very">
            {infoForCompanyProjects}
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="Consulting"
              name="consultingProjectsRadioGroup"
              value={props.ProjectType}
              checked={value === "Consulting"}
              onClick={() => setValue("Consulting")}
              onChange={() => handleProjectType("Consulting")}
            />} wide="very">
            {infoForConsultingProjects}
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="Innovation"
              name="innovationProjectsRadioGroup"
              value={props.ProjectType}
              checked={value === "Innovation"}
              onClick={() => setValue("Innovation")}
              onChange={() => handleProjectType("Innovation")}
            />} wide="very">
            {infoForInnovationProjects}
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={<Radio
              label="Supporting"
              name="supportingActivitiesRadioGroup"
              value={props.ProjectType}
              checked={value === "Supporting"}
              onClick={() => setValue("Supporting")}
              onChange={() => handleProjectType("Supporting")}
            />} wide="very">
            {infoForSupportingActivitiesProjects}
          </Popup>
        </Form.Field>
      </Form>
    </div>
  );
};

export default ProjectType;
