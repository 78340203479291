import { useState, useMemo } from 'react';
import { lookUpProjectManagerName } from "../functions/project-manager-functions";
import { getLastUpdatedDate } from './getLastUpdated';

const UseSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const projectManagers = [
        {
            "name": "Anne Karine Aspholt Monsen",
            "value": "eb9c83f4-cc9b-e911-b078-00155d78450d",
            "unit4UserId": "1127"
        },
        {
            "name": "Anne Marit Soltveit Pettersen",
            "value": "9f723339-da03-e911-afb4-00155d788512",
            "userId": "dd3d19c5-756f-4ab7-8547-bb6217527e55",
            "userName": "annemarit.pettersen@bergento.com",
            "unit4UserId": "1082"
        },
        {
            "name": "Anne-Sophie Schillinger",
            "value": "b01433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "40"
        },
        {
            "userName": "arber.zagragja@bergento.com",
            "userId": "da556df7-5fc1-4016-bea3-eca76d442160",
            "name": "Arber Zagragja",
            "value": "b95330ff-60eb-e511-80d4-00155dd0390a"
        },
        {
            "name": "Barbara Salopek",
            "value": "bd1433fc-14e5-e511-80d5-00155dd47003"
        },
        {
            "name": "Berit Helen Bjørnhaug",
            "value": "bf1433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "14"
        },
        {
            "userName": "bjarte.horn@bergento.com",
            "userId": "8d689263-cb1f-4c80-b2a3-7e53bebb8a44",
            "name": "Bjarte Horn",
            "value": "b4485dc5-ee66-e711-80dd-00155da48907",
            "unit4UserId": "13"
        },
        {
            "userName": "bryngerd.hognestad@bergento.com",
            "userId": "29acd3b7-fea1-43b0-b6cb-b8025694af21",
            "name": "Bryngerd Hognestad",
            "value": "8abb10fc-7d2b-e711-8103-00155da4b802",
            "unit4UserId": "48"
        },
        {
            "name": "Caspar M Lund (delt) Sluttet",
            "value": "ba1433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "31"
        },
        {
            "userName": "Christer.Sorensen@bergento.com",
            "userId": "2cf8025f-a6f2-4d76-bac1-ab036b2c3bbf",
            "name": "Christer Sørensen",
            "value": "16140125-17df-eb11-aaa4-00155d91290c"
        },
        {
            "userName": "Christian.Alnes@bergento.com",
            "userId": "7f5ffcdf-eb70-453c-af5b-a10d1096f0b5",
            "name": "Christian Alnes",
            "value": "185a2d58-2b21-ec11-bcf6-00155d68871c",
            "unit4UserId": "1164"
        },
        {
            "name": "Christian André Pettersen Aubell",
            "value": "d14b66fc-ee66-e711-80cf-00155da0cb11"
        },
        {
            "userName": "dag.finne@bergento.com",
            "userId": "8a94201c-4c08-4949-af04-2a1b0d9cc84e",
            "name": "Dag Finne",
            "value": "c41433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "24"
        },
        {
            "name": "Dag Hellesund",
            "value": "62ad603b-78e3-e911-b091-00155d806331",
            "unit4UserId": "1142"
        },
        {
            "name": "Dagfinn Hallseth",
            "value": "917a3707-d0f1-e811-afac-00155d808214",
            "unit4UserId": "1080"
        },
        {
            "name": "Eli Margrethe Orre",
            "value": "b9310ed1-4fb9-e911-b07b-00155d78720e",
            "unit4UserId": "1129"
        },
        {
            "userName": "elisabeth.silden@bergento.com",
            "userId": "dd5b52e5-ab74-4d94-b2ce-1cfb4a3bc71d",
            "name": "Elisabeth Silden",
            "value": "d18df9a1-fd58-e611-8120-00155dd0dc02",
            "unit4UserId": "45"
        },
        {
            "name": "Erik Helland",
            "value": "bd53adb8-b34c-e611-80e9-00155dd04d0b"
        },
        {
            "name": "Erik Hellestøl",
            "value": "70295695-ae63-e611-80c6-00155dd45711",
            "unit4UserId": "56"
        },
        {
            "name": "Erlend Ødegård",
            "userName": "darcblue@bergento.com",
            "value": "bf0b7aaf-9ad4-e511-80d2-00155dd43505",
            "userId": "07a51270-9106-4bc8-ad8c-703380a4e84b"
        },
        {
            "name": "Espen Rostrup",
            "userName": "espen.rostrup@bergento.com",
            "userId": "08b4fee4-8c14-4bb7-b653-b70578a11807",
            "value": "08b4fee4-8c14-4bb7-b653-b70578a11807",
            "unit4UserId": "1167"
        },
        {
            "name": "florian wieser",
            "value": "f6d3c406-b942-e611-80e6-00155dd03008"
        },
        {
            "userName": "frank.hoover@bergento.com",
            "userId": "6bf60a46-6b01-4f35-87d5-0244b2290dc0",
            "name": "Frank Hoover",
            "value": "bb1433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "30"
        },
        {
            "name": "Frøya Stavem",
            "value": "b85330ff-60eb-e511-80d4-00155dd0390a"
        },
        {
            "name": "Guri Andersland",
            "value": "73840cf2-5c12-e711-80d0-00155da08f04"
        },
        {
            "name": "Halvard Sandvik Jansen",
            "value": "885add7b-e866-e711-80d5-00155da0800b"
        },
        {
            "userName": "halvor.aas@bergento.com",
            "userId": "e7821a33-4027-4e82-b76b-097b2041c96d",
            "name": "Halvor Aas",
            "value": "8b83acd9-aee4-e511-80d5-00155dd46c06",
            "unit4UserId": "23"
        },
        {
            "name": "Hanne Sæle",
            "value": "590cd168-4c90-e611-80c7-00155dd42108",
            "unit4UserId": "21"
        },
        {
            "name": "Hans Hag",
            "value": "dbc29ee5-cae5-e511-80d4-00155dd03607",
            "unit4UserId": "36"
        },
        {
            "name": "Hege Stave Larsen",
            "value": "0575ccaa-fc68-e611-80c8-00155dd03608"
        },
        {
            "name": "Helene Pedersen",
            "value": "ad403d08-5ac0-e911-b07b-00155d788216",
            "unit4UserId": "1135"
        },
        {
            "name": "Henning Kalvatsvik",
            "value": "c408caf7-76e3-e911-b093-00155d806d29",
            "unit4UserId": "1141"
        },
        {
            "userId": "3c9a25f3-d800-40ac-a9ef-73b6b77fc0b0",
            "userName": "hilde.indresovde@bergento.com",
            "name": "Hilde Indresøvde",
            "value": "b31433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "18"
        },
        {
            "name": "Håkon Lysen",
            "value": "96b6ac73-d338-e911-afa5-00155d80bc03",
            "unit4UserId": "1101"
        },
        {
            "name": "Inge Ådland",
            "value": "7e31b740-d5a3-e711-80ce-00155da0bd0d",
            "unit4UserId": "22"
        },
        {
            "name": "Ingjerd Lie",
            "value": "6fe10b7d-013a-e611-80e1-00155dd47a07"
        },
        {
            "name": "Ingmar Høgøy",
            "value": "b41433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "66"
        },
        {
            "name": "Ingvild Rooth",
            "value": "bf1516b4-996a-e611-80ca-00155dd49208"
        },
        {
            "name": "Ingvild Øyen",
            "value": "faba9b3d-5d12-e711-80cc-00155da05809"
        },
        {
            "userId": "4099564e-d43f-4837-b18c-143bd6fbb77d",
            "userName": "Jeanett.Munster@bergento.com",
            "name": "Jeanett Münster",
            "value": "1d66edb2-0fd8-eb11-924b-00155d903b1b"
        },
        {
            "name": "Jenny Nordin (Deloitte)",
            "value": "c31927d1-22f2-e811-afa9-00155d7c851c"
        },
        {
            "name": "Jens Andreas Reigstad",
            "value": "c21433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "15"
        },
        {
            "name": "Jo E L Lerheim",
            "value": "512a8d41-8921-e611-80e1-00155dd04106",
            "unit4UserId": "4"
        },
        {
            "userName": "Johan.Gunnar@bergento.com",
            "userId": "ba7a826e-eeea-40fd-8d25-c28e9631bc74",
            "name": "Johan Gunnar",
            "value": "a1541b3e-1116-ec11-8e7c-00155d683d0f",
            "unit4UserId": "1163"
        },
        {
            "name": "Josefin Styve",
            "value": "bf3c01ed-ee66-e711-80cf-00155da0cb11"
        },
        {
            "name": "Joy Mutoloki",
            "value": "057754e8-b2a6-e811-80cb-863e1434cf87"
        },
        {
            "name": "Jørn Einen",
            "value": "b21433fc-14e5-e511-80d5-00155dd47003"
        },
        {
            "name": "Kaja Witnes Schultz",
            "value": "e365122c-1dc0-e911-b089-00155d80a40b",
            "unit4UserId": "1136"
        },
        {
            "userName": "kari.oritsland@bergento.com",
            "userId": "8787e3b7-0fa2-47f0-83f1-fcaabbdb005a",
            "name": "Kari Øritsland",
            "value": "6f295695-ae63-e611-80c6-00155dd45711",
            "unit4UserId": "38"
        },
        {
            "name": "Karianne Kojen Andersen",
            "value": "b9a4098d-32f7-e911-b08f-00155d7c4d04",
            "unit4UserId": "1143"
        },
        {
            "name": "Karina Sundby",
            "value": "8a30bbd7-d47b-e911-afb9-00155d806d22",
            "unit4UserId": "1120"
        },
        {
            "name": "Karoline Lekve",
            "value": "63f4841a-b3a6-e811-80d1-00155da4341f"
        },
        {
            "name": "Kassian Pause",
            "value": "19cfbdc7-97e6-e511-80d3-00155dd48d09"
        },
        {
            "userId": "d09d8553-2c25-44e9-81ec-f0006f2306d6",
            "userName": "katinka.bratland@bergento.com",
            "name": "Katinka Bratland",
            "value": "c053adb8-b34c-e611-80e9-00155dd04d0b"
        },
        {
            "name": "Katinka Tofte Amundsen",
            "value": "7842ea34-00e5-e511-80d2-00155dd47d04",
            "unit4UserId": "20"
        },
        {
            "name": "Kine Gregersen",
            "value": "4c79d8b3-eb2b-e611-80e2-00155dd04106",
            "unit4UserId": "44"
        },
        {
            "userName": "kine.gregersen@bergento.com",
            "userId": "23591413-e09b-4701-89b5-74387169df0e",
            "name": "Kine Risnes Pettersen",
            "value": "ee64659d-f700-eb11-b07f-00155d78a832",
            "unit4UserId": "1150"
        },
        {
            "name": "Kingsley Nguyen",
            "value": "6507dd27-b1a6-e811-80d6-00155da05d1a"
        },
        {
            "name": "Kinza Ilyas Bhatti",
            "value": "aa0299c4-d47b-e911-afbe-00155d7ca41c",
            "unit4UserId": "1123"
        },
        {
            "userName": "kjartan.kramer@bergento.com",
            "userId": "80b8eb45-8c14-4712-a3cc-ce9ac607eda9",
            "name": "Kjartan Kramer",
            "value": "f0bfea2a-b82a-e911-afb7-00155d787d01",
            "unit4UserId": "1100"
        },
        {
            "name": "Kjetil Myhren-Berge",
            "value": "c11433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "26"
        },
        {
            "name": "Krastina Georgieva-Ilkova",
            "value": "b75330ff-60eb-e511-80d4-00155dd0390a"
        },
        {
            "name": "Kristine Andersen",
            "value": "c1239eee-1343-e811-80cb-00155da0391a"
        },
        {
            "name": "KristineTuft",
            "value": "0a0dfde8-d47b-e911-afb9-00155d806d22",
            "unit4UserId": "1121"
        },
        {
            "name": "Lars Flesland",
            "value": "87b94fdb-4fcd-e911-b093-00155d80860c",
            "unit4UserId": "1140"
        },
        {
            "userName": "lars.gronnestad@bergento.com",
            "userId": "0f1e23b5-9829-43d7-9057-2b136b5f4518",
            "name": "Lars Grønnestad",
            "value": "1ec2eed1-439f-e811-80de-00155da49003",
            "unit4UserId": "1071"
        },
        {
            "name": "Lars Jørgen Loktu",
            "value": "eb370c4e-b2b1-e811-80cc-00155d809016"
        },
        {
            "name": "Legal Advisor",
            "value": "b4468e9f-8ad6-e511-80d0-00155dd0810a"
        },
        {
            "userId": "c1e2ab5a-edee-4996-9717-169b0779377d",
            "userName": "lena.vasstrand@bergento.com",
            "name": "Lena Vasstrand",
            "value": "14de9b38-7931-e711-80d6-00155da0bb09"
        },
        {
            "name": "Leonid Bashlykov",
            "value": "bf53adb8-b34c-e611-80e9-00155dd04d0b"
        },
        {
            "name": "Magnus Falck-Pedersen",
            "value": "95c9467d-1dc0-e911-b086-00155d7cb316",
            "unit4UserId": "1131"
        },
        {
            "userName": "maja.flatval@bergento.com",
            "userId": "062f3a3e-71d9-46d0-89ad-c6a23595a31e",
            "name": "Maja Flatval",
            "value": "29b5c375-01e5-e511-80d5-00155dd47003",
            "unit4UserId": "28"
        },
        {
            "userName": "maja.elgsaas@bergento.com",
            "userId": "90455d23-0b9e-4ceb-94ac-8c65ff5a95d9",
            "name": "Maja Mujic Elgsaas",
            "value": "bc1433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "29"
        },
        {
            "userName": "malgorzata.barczyk@bergento.com",
            "userId": "28ce391c-b0bd-4c41-b8b9-0e8e141899a5",
            "name": "Malgorzata Barczyk",
            "value": "b91433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "32"
        },
        {
            "name": "Margrethe Vikan Sæbø",
            "value": "eec220d8-eeac-e711-80d1-00155da0db0e"
        },
        {
            "name": "Mari Kristine Rørvik",
            "value": "a9a1fdce-c3e6-ea11-b11b-00155d784903",
            "unit4UserId": "1147"
        },
        {
            "name": "Maria Ratinho",
            "value": "c50ba0aa-15c2-e611-80cb-00155d3cab09"
        },
        {
            "name": "Maria Strønstad",
            "value": "8e426d74-b22a-eb11-bfae-00155d908005"
        },
        {
            "name": "Marie Borg",
            "value": "379f0c6c-1dc0-e911-b08a-00155d80bc09",
            "unit4UserId": "1130"
        },
        {
            "name": "Meruyert Kanzhigalina",
            "value": "de5f2473-ee66-e711-80c9-00155da49f09"
        },
        {
            "name": "Mina Bergan",
            "value": "06a974c3-b2a6-e811-80d6-00155da4e40e"
        },
        {
            "userName": "mona.leirgulen@bergento.com",
            "userId": "de0fb917-68b9-4c81-a218-ccc4d7da3785",
            "name": "Mona Leirgulen",
            "value": "1142ea34-00e5-e511-80d2-00155dd47d04",
            "unit4UserId": "65"
        },
        {
            "userName": "monica.lislerud@bergento.com",
            "userId": "58ab3239-3910-49c5-bdeb-852e58048d4c",
            "name": "Monica Lislerud",
            "value": "b81433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "33"
        },
        {
            "name": "Morten Ansteensen",
            "value": "dc8ad651-0268-e711-80d4-00155da4860c",
            "unit4UserId": "49"
        },
        {
            "name": "Morten Gauslaa",
            "value": "6e295695-ae63-e611-80c6-00155dd45711"
        },
        {
            "name": "Muhammad Qadeer Bin Shahab",
            "value": "698635f8-d47b-e911-b083-00155d7c9b0d",
            "unit4UserId": "1122"
        },
        {
            "userName": "nils-eivind.holmedal@bergento.com",
            "userId": "07a40554-ee3c-45d3-ac9f-8b779805a1fa",
            "name": "Nils-Eivind Holmedal",
            "value": "b51433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "17"
        },
        {
            "userId": "50f4f810-02e3-4ce7-a101-417fceb3e80f",
            "userName": "nina.toften@bergento.com",
            "name": "Nina Toften",
            "value": "f89e4f0e-cb1c-e611-80df-00155dd0310a",
            "unit4UserId": "41"
        },
        {
            "name": "Nora Gedde",
            "value": "842b9890-1dc0-e911-b07b-00155d78ac14",
            "unit4UserId": "1132"
        },
        {
            "userId": "e00b5b05-7b94-4ef9-94a6-85892b3a87f8",
            "userName": "ove.gjelsvik@bergento.com",
            "name": "Ove Gjelsvik",
            "value": "faa3ca36-106d-e911-afbe-00155d7c5b1d",
            "unit4UserId": "1106"
        },
        {
            "name": "Pernille Bucher-Johannessen",
            "value": "91362bd6-ee66-e711-80cf-00155da0cb11"
        },
        {
            "userId": "311c57f6-74c5-4714-9d43-2ba7afdd22ed",
            "userName": "randi.taxt@bergento.com",
            "name": "Randi Elisabeth Taxt",
            "value": "c01433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "27"
        },
        {
            "userId": "d150bedd-189a-427b-b0a6-0b37f5f2188e",
            "userName": "roger.han@bergento.com",
            "name": "Roger Han",
            "value": "3e156579-9585-eb11-bf84-00155d90fa0f"
        },
        {
            "name": "Samantha Scarlett",
            "value": "0884b452-cd0a-e811-80e1-00155da4d505",
            "unit4UserId": "1065"
        },
        {
            "userId": "0817c752-cf61-45c5-8d15-1d5f358db127",
            "userName": "sara.schreiner@bergento.com",
            "name": "Sara Schreiner",
            "value": "085cd466-e1cd-ea11-b104-00155d807b00",
            "unit4UserId": "1148"
        },
        {
            "name": "Sarah Økland",
            "value": "43cc090c-583c-ea11-b076-00155d808f03",
            "unit4UserId": "1144"
        },
        {
            "name": "Simen Johannssen Dolve",
            "value": "8952df30-9585-eb11-bf84-00155d90fa0f"
        },
        {
            "name": "Sindre Wergeland",
            "value": "1f7b68fb-b2a6-e811-80d2-00155da09b21"
        },
        {
            "name": "Siv Sylta",
            "value": "dcc3e8cd-866f-eb11-bf8b-00155d90170c",
            "unit4UserId": "1154"
        },
        {
            "name": "Sonia Bains",
            "value": "d9f5af17-ef66-e711-80cf-00155da0cb11",
            "unit4UserId": "51"
        },
        {
            "userId": "ea8810ff-6436-4fa0-a8fe-d1569ee4b60e",
            "userName": "steffen.boga@bergento.com",
            "name": "Steffen Boga",
            "value": "b71433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "34"
        },
        {
            "userId": "e5d0cd16-50cf-48e4-9590-a79a26138fea",
            "userName": "stine.fiksdal@bergento.com",
            "name": "Stine Fiksdal",
            "value": "5804f5af-3686-ea11-b0d0-00155d7c4702",
            "unit4UserId": "1145"
        },
        {
            "name": "Sunniva Ullavik Erstad",
            "value": "be1433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "16"
        },
        {
            "name": "Sølvi Søbstad",
            "value": "ca4cd131-8c29-e611-80e2-00155dd47408",
            "unit4UserId": "43"
        },
        {
            "userName": "teele.lohne@bergento.com",
            "userId": "4a7218f9-c405-439c-b725-136649aa23a2",
            "name": "Teele Lohne",
            "value": "c9ef95a1-949e-eb11-bf8e-00155d90e00b",
            "unit4UserId": "1155"
        },
        {
            "name": "Thea Agathe Eriksdatter Hope",
            "value": "1beda3a0-1dc0-e911-b087-00155d7ca60f",
            "unit4UserId": "1133"
        },
        {
            "name": "Thomas Tjøstheim",
            "value": "cc079cd9-1dc0-e911-b086-00155d784603",
            "unit4UserId": "1139"
        },
        {
            "name": "Tone Skår",
            "value": "cb4cd131-8c29-e611-80e2-00155dd47408",
            "unit4UserId": "42"
        },
        {
            "name": "Tor Arne Fanghol",
            "value": "c8eda4bd-5664-e611-80c6-00155dd45711",
            "unit4UserId": "39"
        },
        {
            "userId": "c7e000fe-d84f-4daa-ab8e-a5288eb0f821",
            "userName": "tor.rognaldsen@bergento.com",
            "name": "Tor Ole Rognaldsen",
            "value": "c94cd131-8c29-e611-80e2-00155dd47408",
            "unit4UserId": "37"
        },
        {
            "name": "Tora Lindheim",
            "value": "fab3d835-b3a6-e811-80eb-00155da0af1c"
        },
        {
            "name": "Torill Bakke",
            "value": "5704d54f-f331-e811-80e4-00155da45c11"
        },
        {
            "userId": "de793064-cdda-48d9-8e86-3375ff6d9384",
            "userName": "torleif.lunde@bergento.com",
            "name": "Torleif Markussen Lunde",
            "value": "dbbe71c4-b763-eb11-bf8c-00155d906d0e",
            "unit4UserId": "1153"
        },
        {
            "name": "Torsten Porwol",
            "value": "b61433fc-14e5-e511-80d5-00155dd47003",
            "unit4UserId": "35"
        },
        {
            "name": "Trine Mykkeltvedt",
            "value": "70e10b7d-013a-e611-80e1-00155dd47a07"
        },
        {
            "name": "Trude Gerhardsen",
            "value": "cc4cd131-8c29-e611-80e2-00155dd47408",
            "unit4UserId": "19"
        },
        {
            "name": "Trygve Trohaug",
            "value": "57f1ed35-4283-e711-80cb-00155da44e09"
        },
        {
            "name": "Turi Pettersen",
            "value": "b65330ff-60eb-e511-80d4-00155dd0390a"
        },
        {
            "name": "Vetle Seljestad",
            "value": "be53adb8-b34c-e611-80e9-00155dd04d0b"
        },
        {
            "name": "Victoria Isern",
            "value": "0c0a1f6c-2aa8-e711-80d3-00155da08f0e",
            "unit4UserId": "53"
        },
        {
            "name": "Viktor Nielsen",
            "value": "50c9e4f0-b0a6-e811-80ce-00155da06522",
            "unit4UserId": "1072"
        },
        {
            "name": "Vilde Nakkim",
            "value": "0ebc4aaf-1dc0-e911-b088-00155d78760e",
            "unit4UserId": "1134"
        },
        {
            "name": "Wenche Sæter",
            "value": "6d295695-ae63-e611-80c6-00155dd45711",
            "userId": "71c8cce2-2bd7-4fd5-974b-0d543ea217a5",
            "userName": "wenche.saeter@bergento.com",
            "unit4UserId": "46"
        },
        {
            "name": "Ådne Iden Høiland",
            "value": "707b29c5-1dc0-e911-b079-00155d7c7221",
            "unit4UserId": "1136"
        }
    ]
    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.key === 'projectManager') {
                    const aPM = lookUpProjectManagerName(a.ownerId, projectManagers);
                    const bPM = lookUpProjectManagerName(b.ownerId, projectManagers);
                    if (aPM < bPM) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aPM > bPM) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
                if (sortConfig.key === 'lastUpdated') {
                    const aPM = getLastUpdatedDate(a.history);
                    const bPM = getLastUpdatedDate(b.history);
                    if (aPM < bPM) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aPM > bPM) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sortableItems;
    }, [items, projectManagers, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

export default UseSortableData;