
import React from 'react'
import Ownerdata from '../../data/mapping_list.json'
import { Label, Icon } from 'semantic-ui-react'

const OwnerLabel = (props) => {
  let found = []
  if (!props.owner) {
    return (<span style={{ color: "grey" }}> None selected </span>)
  }
  const topLevelOwner = props.owner.split(':')[0]
  let shortTopLevel = topLevelOwner
  if (topLevelOwner.length > 12) {
    found = Ownerdata.find(({ organization }) => organization === topLevelOwner)
    try {
      shortTopLevel = found.short
    } catch (err) {
      shortTopLevel = topLevelOwner.substring(0, 9)
    }
  }
  const lowerLevels = props.owner.split(':').slice(1)

  return (<Label as='a' color='blue'>
    <Icon name="home" />
    {shortTopLevel}
    {lowerLevels.map((level) => <Label.Detail key={level}>{level}</Label.Detail>)}

  </Label>)
}

export default OwnerLabel