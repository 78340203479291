const getLastUpdatedDate = (historyObject) => {
    let allDatesinHistoryObject = [];
    for (let x in historyObject) {
        if (historyObject[x].timestamp !== undefined || historyObject[x].timestamp !== "") {
            allDatesinHistoryObject.push(historyObject[x].timestamp);
        }
    }
    return allDatesinHistoryObject.sort()[historyObject.length - 1];
}

export { getLastUpdatedDate }