import React from 'react'
import { Icon } from 'semantic-ui-react'
import {lookUpProjectManager} from "../../functions/project-manager-functions";

const ProjectManager = (props) => {
    const projectManagerId = props.projectManagerId

    const pm = lookUpProjectManager(projectManagerId, props.projectManagers)
    const projectManagerName = pm ? pm.name : "Unknown"

    return (
        <span>Project Manager: <Icon color="violet" name='user'/>{projectManagerName} </span>
    )
}

export default ProjectManager