import React from 'react'
import { Button, Popup } from 'semantic-ui-react'

const nextColor = (statusColor) => {
  switch (statusColor) {
    case "green":
      return "yellow";
    case "yellow":
      return "red";
    case "red":
      return "";
    default:
      return "green";
  }
}

const StatusColorButton = (props) => {
  const initialColor = props.color

  const onColorChange = (click) => {
      const theColor = nextColor(click)
      if (props.name) {
        const event = { name: props.name, value: theColor }
        props.onChange(click, event)
      }
  }

  return (
    <Popup
      trigger={<Button color={initialColor} size="mini" onClick={() => onColorChange(initialColor)} style={{ width: "80px" }}
        compact>{props.ragTopic}</Button>} wide="very">
      Traffic Lights
    </Popup>
  );
}

export default StatusColorButton