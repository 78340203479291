import React, { useState } from 'react'
import { Button, Header, Icon, Modal, TextArea, Form, Checkbox } from "semantic-ui-react";
import { saveProject, addFieldToProject } from '../../functions/project-card-functions';

const ArchiveModalBox = (props) => {
    const userInfo = props.userInfo;
    const [archiveInfo, setArchiveInfo] = useState({});

    const handleChange = (e, { name, value }) => {
        setArchiveInfo({ ...archiveInfo, [name]: value });
    };

    const running = "Running";
    const onHold = "On-hold";
    const archived = "Archived";

    const checkList = require("../../data/CheckListForArchivingTheProject.json");

    const [checkedState, setCheckedState] = useState(
        new Array(checkList.length).fill(false)
    );

    const setModalClosed = () => {
        props.dispatch({ type: 'setModal', payload: '' })
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const changeStatusAndCloseModal = (status) => {

        const historyObject =
        {
            type: "archive",
            userId: userInfo.userId,
            userName: userInfo.userDetails,
            archiveStatus: status,
            archiveComment: archiveInfo.archiveComment,
            timestamp: new Date().toISOString()
        }
        const updatedProject = addFieldToProject(
            props.project, "archiveStatus", status);
        updatedProject.history.push(historyObject)

        saveProject(updatedProject)
            .then(r => console.log("project chngStsAndCloseModal", updatedProject, "status", status));
        setModalClosed();
        props.dispatch({ type: 'updateProject', payload: updatedProject })
        setArchiveInfo({})
    }

    const displayFor = (showWhen) => {
        return showWhen.includes(props.project.archiveStatus);
    };


    return (

        <Modal
            closeOnDimmerClick={false}
            closeIcon
            open={props.isOpen}
            onClose={setModalClosed}
        >
            <Header
                icon="archive"
                content="Decision to hold or archive the project:"
            />
            <Modal.Header>{props.project.id} - {props.project.projectName}</Modal.Header>
            <Modal.Content>
                <h3>Current project status is: {props.project.archiveStatus || "Undefined"}</h3>

                <Form>
                    <Form.Field
                        label="Please provide details about the decision to defer or cancel the project"
                        name="archiveComment"
                        control={TextArea}
                        value={archiveInfo.archiveComment}
                        onChange={handleChange}
                    />

                    <p />
                    <strong>Checklist for archiving the project</strong>
                    <ul style={{ listStyleType: "none" }}>
                        {checkList.map(({ name, value }, index) => {
                            return (
                                <li key={`criteria-no-${index}`}>
                                    <Checkbox
                                        label={value}
                                        id={index}
                                        name={name}
                                        value={name}
                                        checked={checkedState[index]}
                                        onChange={() => handleOnChange(index)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {!displayFor([running]) && (
                    <Button onClick={() => changeStatusAndCloseModal(running)}>
                        <Icon name="play" />
                        Set to Running
                    </Button>
                )}
                {!displayFor([onHold]) && (
                    <Button onClick={() => changeStatusAndCloseModal(onHold)}>
                        <Icon name="pause" />
                        Put Project On-Hold
                    </Button>
                )}
                {!displayFor([archived]) && (
                    <Button onClick={() => changeStatusAndCloseModal(archived)}>
                        <Icon name="archive" />
                        Archive Project
                    </Button>
                )}
                <Button onClick={setModalClosed}>
                    <Icon name="cancel" />
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ArchiveModalBox