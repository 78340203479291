import { Popup, Icon } from 'semantic-ui-react'
import { reformatDate } from "../functions/reformat-date";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faL } from '@fortawesome/free-solid-svg-icons'
import { openAbsoluteUrl } from '../functions/project-card-functions'

const LicenseButton = (props) => {
  const licenseDate = props.project.licenseDate && props.project.licenseDate.slice(0, 10);
  const licenseURL = props.project.licenseURL;
  let message = "";
  let backgroundColor = null

  if (licenseDate === "" || licenseDate === undefined || licenseDate == null) {
    backgroundColor = null;
    message = "No license agreement registered.";
  } else {
    backgroundColor = '#bce416';
    message = "License agreement date: " + reformatDate(licenseDate);
  }

  const iconStyle = {
    backgroundColor: backgroundColor,
    cursor: licenseURL === "" || licenseURL === undefined || licenseURL == null ? "default" : "pointer",
  }

  return (
    <Popup
        trigger= {<Icon circular size="small" style={iconStyle} onClick={() => openAbsoluteUrl(licenseURL)}>
                      <FontAwesomeIcon icon={faL} />
                  </Icon>}
    >
        {message}
    </Popup>
  )
};

export default LicenseButton
